import type {
  OrderLine,
  PriceExcludingVat,
  PriceIncludingVat,
} from "@repo/types";

import { calculateConfigTotal } from "../orders/calculate-config-total";
import { round, sumBy } from "../utils/utils";

/**
 * calculateOrderVatBreakdown calculates all VAT properties for an order.
 * Use it in places where you need to display a full price breakdown
 */
export type OrderInvoiceTotalResponse = {
  withoutVat: PriceExcludingVat;
  vat: number;
  withVat: PriceIncludingVat;
};

type OrderLineForVat = Pick<
  OrderLine,
  "options" | "vatRate" | "amount" | "unitPrice"
>;

export const calculateOrderVatBreakdown = (
  orderLines: OrderLineForVat[] = [],
  /** If `decimalPrice` is selected, we want to return the value in decimals, not as unit price */
  decimalPrice?: boolean
): OrderInvoiceTotalResponse => {
  const mapped = orderLines.map((orderLine) => {
    const { options = {}, vatRate, amount = 0, unitPrice = 0 } = orderLine;

    const totalConfigPrice = calculateConfigTotal(options);

    const singlePriceExclVat = unitPrice + totalConfigPrice;

    const withoutVat = amount * singlePriceExclVat;
    const withVat = round(singlePriceExclVat * (1 + vatRate), 0) * amount;
    const vat = round(singlePriceExclVat * vatRate, 0) * amount;

    if (decimalPrice)
      return {
        withoutVat: round(withoutVat / 100, 2),
        withVat: round(withVat / 100, 2),
        vat: round(vat / 100, 2),
      };

    return {
      withoutVat: round(withoutVat, 0),
      withVat: round(withVat, 0),
      vat: round(vat, 0),
    };
  });

  return {
    withoutVat: round(sumBy(mapped, "withoutVat"), 2),
    vat: round(sumBy(mapped, "vat"), 2),
    withVat: round(sumBy(mapped, "withVat"), 2),
  };
};
