import { clsx } from "clsx";

type GridLayoutProps = {
  children: React.ReactNode;
  className?: string;
};

export const GridLayout = ({ children, className }: GridLayoutProps) => (
  <div
    className={clsx(
      "grid h-min w-full grid-cols-2 gap-1 px-2 py-3 lg:grid-cols-3 xl:grid-cols-5 ipad:grid-cols-4",
      className
    )}
  >
    {children}
  </div>
);
