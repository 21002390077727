import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";

import { client, getAuthHeader } from "./api.client";

export const reportsKeys = createQueryKeys("reports", {
  detail: ({ sessionId }: { sessionId: string }) => ({
    queryKey: [{ sessionId }],
  }),
});

function useSalesReport(sessionId: string | undefined) {
  return useQuery({
    ...reportsKeys.detail({ sessionId: sessionId || "empty" }),
    queryFn: async () => {
      if (!sessionId) throw new Error("No session id provided");
      const { profileId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.reports.getById({
        params: { sessionId },
        query: { profileId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(sessionId),
    staleTime: 1000 * 30, // 30 seconds
  });
}

function useSendEmailReport(sessionId: string) {
  return useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      const { profileId, moduleId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.reports.email({
        params: { sessionId },
        body: { moduleId, profileId, email },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
  });
}

export { useSalesReport, useSendEmailReport };
