import { useParams } from "react-router-dom";
import { T } from "@repo/transifex";
import { atom, useAtom } from "jotai";
import { getErrorMessage } from "@repo/system";

import { useOpenTillSession, useTill } from "~/hooks/queries";
import { isApiError } from "~/utils/api";
import { useAppContext, useBasketProducts } from "~/providers/app";
import { Button, DialogError, DialogLoading } from "~/components";

export const startedCashBalanceAtom = atom(0);

export const HeaderButtonOpenSession = () => {
  const { tillId } = useParams();

  const {
    hardware: { printer },
  } = useAppContext();

  const {
    meta: { currency },
  } = useBasketProducts();

  const { mutate: createTillSession, status, error } = useOpenTillSession();
  const { data: till, status: tillStatus } = useTill(tillId);

  const [startedCashBalance, setStartedCashBalance] = useAtom(
    startedCashBalanceAtom
  );

  if (tillStatus !== "success") return null;

  const handleConfirm = () => {
    createTillSession({
      deviceId: till.deviceId || "",
      printerId: printer?.identifier || "",
      tillId: till.id,
      startedCashBalance,
      currency,
    });

    setStartedCashBalance(0);
  };

  return (
    <>
      <Button className="ml-auto" onClick={handleConfirm} variant="primary">
        <T _str="Open register" />
      </Button>

      {status === "pending" && <DialogLoading />}
      {status === "error" && (
        <DialogError
          backUrl={`settings/tills/${tillId}`}
          error={
            isApiError(error) ? error.body.message : getErrorMessage(error)
          }
        />
      )}
    </>
  );
};
