import * as Sentry from "@sentry/react";

const SENTRY_TAG_TILL = "till_id";
const SENTRY_TAG_SESSION = "session_id";
const SENTRY_TAG_PROFILE = "profile_id";

function setSentryTagTill(tillId: string | undefined | null) {
  if (tillId) {
    Sentry.setTag(SENTRY_TAG_TILL, tillId);
  }
}

function clearSentryTagTill() {
  Sentry.setTag(SENTRY_TAG_TILL, undefined);
}

function setSentryTagSession(sessionId: string | undefined | null) {
  if (sessionId) {
    Sentry.setTag(SENTRY_TAG_SESSION, sessionId);
  }
}

function clearSentryTagSession() {
  Sentry.setTag(SENTRY_TAG_SESSION, undefined);
}

function setSentryTagProfile(profileId: string | undefined | null) {
  if (profileId) {
    Sentry.setTag(SENTRY_TAG_PROFILE, profileId);
  }
}

function clearSentryTagProfile() {
  Sentry.setTag(SENTRY_TAG_PROFILE, undefined);
}

export {
  setSentryTagTill,
  clearSentryTagTill,
  setSentryTagSession,
  clearSentryTagSession,
  setSentryTagProfile,
  clearSentryTagProfile,
};
