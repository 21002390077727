import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import { faCheck, faPlus } from "@fortawesome/pro-regular-svg-icons";

import type { PosProduct } from "@repo/types";

type ProductRadioProps = Pick<PosProduct, "id"> & {
  label: string;
  displayPrice: string;
  displayPriceDiscounted: string;
} & JSX.IntrinsicElements["input"];

export const ProductRadioButton = forwardRef<
  HTMLInputElement,
  ProductRadioProps
>(({ id, label, displayPrice, displayPriceDiscounted, ...props }, ref) => {
  return (
    <label className="relative flex w-full cursor-pointer items-center">
      <input
        className="peer hidden"
        ref={ref}
        type="radio"
        value={id}
        {...props}
      />
      <span className="flex w-full items-center justify-between rounded-md border border-background-secondary p-4 pr-24 text-left peer-checked:bg-primary-backdrop">
        <div>
          <p>{label}</p>
          <div className="flex gap-2">
            <p className="text-text-secondary line-through">{displayPrice}</p>
            <p className="font-bold">{displayPriceDiscounted}</p>
          </div>
        </div>
      </span>
      {/* Not checked */}
      <FontAwesomeIcon
        className="absolute right-4 top-1/2 size-6 -translate-y-1/2 rounded-md bg-background-secondary px-6 py-2 text-secondary-dark peer-checked:hidden"
        icon={faPlus}
      />
      {/* Checked */}
      <FontAwesomeIcon
        className="absolute right-4 top-1/2 hidden size-6 -translate-y-1/2 rounded-md bg-background-primary px-6 py-2 text-primary-main peer-checked:block"
        icon={faCheck}
      />
    </label>
  );
});

ProductRadioButton.displayName = "ProductRadioButton";
