import { useEffect, useState } from "react";

type UseCardReaderArgs = {
  onScan: (code: string) => void;
};

/**
 * This hook is used to read the card reader input, ergo the employee card code.
 * @param {UseCardReaderArgs} args contains the onScan function
 */
export function useCardReader({ onScan }: UseCardReaderArgs) {
  const [enter, setEnter] = useState(false);
  const [code, setCode] = useState("");

  const handleUserKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      setEnter(true);
      return;
    }

    setCode((prev) => prev.concat(event.key));
  };

  useEffect(() => {
    document.addEventListener("keydown", handleUserKeyPress);
    return () => {
      document.removeEventListener("keydown", handleUserKeyPress);
    };
  }, []);

  useEffect(() => {
    if (!enter) return;

    // handle output
    const shiftRegex = /shift/gi;
    const parsedCode = code.replaceAll(shiftRegex, "");
    onScan(parsedCode);

    // reset
    setCode("");
    setEnter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to run this when enter is pressed
  }, [enter, code]);
}
