import { useQuery, type UseQueryResult } from "@tanstack/react-query";

import { tx } from "@repo/transifex";
import type { Language } from "@repo/types";

type TransifexLanguage = {
  code: Language;
  localized_name: string;
  name: string;
  rtl: boolean;
};

/**
 * This hook loads the available languages from Transifex.
 * @returns {UseQueryResult<TransifexLanguage[], unknown>[]} available languages
 */
export function useAvailableLanguages(): UseQueryResult<
  TransifexLanguage[],
  unknown
> {
  return useQuery({
    queryKey: ["transifex-languages"],
    queryFn: async () => {
      const txResponse = await tx.getLanguages();
      return txResponse as TransifexLanguage[];
    },
  });
}
