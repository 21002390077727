import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { useBasketProducts } from "~/providers/app";

import { getBasket } from "./getters";
import { basketStore } from "./store";
import type { BasketStore } from "./store.types";

function useBasket(): BasketStore {
  return useStore(basketStore, useShallow(getBasket));
}

function useBasketWithCategories() {
  const { products: basket } = useBasket();
  const { productsInBasket } = useBasketProducts();

  return useMemo(() => {
    return basket.flatMap((product) => {
      const productId = product.productId;

      if (product.type === "custom") {
        return {
          amount: product.count,
          category: product.category,
          unitPrice: product.rawUnitPrice,
          vatRate: product.vatRate,
          name: product.name,
          id: productId,
          type: product.type,
          productLineId: product.id,
          taxRateName: product.taxRateName,
          taxRateId: product.taxRateId,
        };
      }

      const productInBasket = productsInBasket.find(
        (p) => p.basketId === product.id
      );
      if (!productInBasket) return [];

      return {
        amount: product.count,
        unitPrice: productInBasket.exclVatUnitPrice,
        vatRate: productInBasket.vatRate,
        category: productInBasket.category,
        productLineId: productInBasket.productLineId,
        id: productId,
        type: product.type,
        taxRateId: productInBasket.taxRateId,
        taxRateName: productInBasket.taxRateName,
        stampCardRuleId: productInBasket.stampCardRuleId,
        ...((product.type === "variant" ||
          product.type === "variant-weight") && {
          options: product.options.map((option) => ({
            id: "id" in option ? option.id : uuidv4(),
            choices: option.choices
              .filter((choice) => choice.amount > 0)
              .map((choice) => ({
                id: choice.id,
                amount: choice.amount,
                name: choice.name ?? "",
                ...("unitPrice" in choice && {
                  unitPrice: choice.unitPrice ?? 0,
                }),
                ...("weight" in choice && { weight: choice.weight }),
              })),
          })),
        }),
        ...(productInBasket.discountUnitPrice && {
          unitDiscountedPrice: productInBasket.discountUnitPrice,
        }),
      };
    });
  }, [basket, productsInBasket]);
}

function useOrderDiscount(): BasketStore["orderDiscount"] {
  return useStore(basketStore, (state) => state.orderDiscount);
}

function useDiningOption(): BasketStore["diningOption"] {
  return useStore(basketStore, (state) => state.diningOption);
}

function useShopperName(): BasketStore["shopperName"] {
  return useStore(basketStore, (state) => state.shopperName);
}

export {
  useBasket,
  useBasketWithCategories,
  useDiningOption,
  useOrderDiscount,
  useShopperName,
};
