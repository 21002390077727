export function calculateValue(
  current: number,
  input: number | "00" | "cancel",
  variant: "currency" | "percentage" | "amount"
): number {
  // if the NumPad is for "amount" - it works as a calculator, without decimals
  if (variant === "amount") {
    if (input === "00") return current * 100;

    if (input === "cancel") return Number(current.toString().slice(0, -1));

    return Number(current + input.toString());
  }

  // if the NumPad is for "percentage" - it works as a calculator, with decimals, max value is 100
  if (variant === "percentage") {
    if (input === "00") return Math.min(100, current * 100);

    if (input === "cancel") return Number(current.toString().slice(0, -1));

    return Math.min(100, Number(current + input.toString()));
  }

  // if the NumPad is for "currency" - it works with decimals, automatic two decimals are added
  if (input === "cancel") return Math.trunc((current / 10) * 100) / 100;

  const decimalLength = Math.floor(current).toString().length;
  if (decimalLength >= 5) return current;

  if (input === "00") {
    if (decimalLength <= 3) {
      return fixedNumber(current * 100);
    }

    return fixedNumber(current * 10);
  }

  if (input === 0) return fixedNumber(current * 10);

  return fixedNumber(current * 10 + input / 100);
}

function fixedNumber(number: number) {
  return Number(number.toFixed(2));
}
