import type { OrderLine } from "@repo/types";

export const calculateConfigTotal = (options: OrderLine["options"]) => {
  const optionValues = Object.values(options);

  return optionValues.reduce((sum, option) => {
    const { amount = 0, unitExtraPrice = 0 } = option;
    const optionTotal = amount * unitExtraPrice;
    return sum + optionTotal;
  }, 0);
};
