import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSupportInfoFromCountryCode } from "@repo/system";
import { T } from "@repo/transifex";
import type { ReactNode } from "react";

import type { DialogProps } from "~/components";
import { Dialog } from "~/components";
import { useAuth } from "~/providers/store/auth";

type DialogCodeErrorProps = Pick<DialogProps, "onOutsideClick"> & {
  icon: IconDefinition;
  title: ReactNode;
  body: ReactNode;
};

export const DialogCodeError = ({
  onOutsideClick,
  icon,
  title,
  body,
}: DialogCodeErrorProps) => {
  const auth = useAuth();

  const { email, phone } = getSupportInfoFromCountryCode(auth.country);

  return (
    <Dialog onOutsideClick={onOutsideClick} size="md">
      <div className="flex flex-col gap-y-6 p-7">
        <div className="flex flex-col items-center gap-y-6">
          <div className="flex flex-col gap-y-3">
            <FontAwesomeIcon
              className="text-6xl text-danger-main"
              icon={icon}
            />

            <h2 className="text-3xl font-bold">{title}</h2>
          </div>

          <p>{body}</p>
        </div>

        <div className="flex flex-col items-center justify-center rounded-2xl bg-secondary-main px-5 py-7">
          <h2 className="mb-5 text-sm font-bold uppercase text-danger-main">
            <T _str="Still doesn't work?" />
          </h2>
          <p className="mb-8 w-4/5 text-center text-text-primary">
            <T _str="Please contact us via email or give us a call and we will help you out." />
          </p>

          <p className="mb-2.5 font-semibold text-text-primary">
            <T _str="Kanpla Customer Support" />
          </p>

          <div className="flex flex-col items-center gap-y-2.5">
            <a className="font-bold text-primary-dark" href={`tel:${phone}`}>
              {phone}
            </a>
            <a className="font-bold text-primary-dark" href={`mailto:${email}`}>
              {email}
            </a>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
