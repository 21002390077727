import {
  faEllipsisH,
  faPercent,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import type {
  Control,
  UseFieldArrayRemove,
  UseFormWatch,
} from "react-hook-form";
import { Controller } from "react-hook-form";

import { T, useT } from "@repo/transifex";
import {
  BaseInput,
  Button,
  Dialog,
  InputAmount,
  Popover,
  PriceInput,
  SelectCreatable,
} from "~/components";
import { useProducts } from "~/hooks/queries";
import { useEmployee } from "~/providers/store/employee";

import type { CustomProductForm } from "./route";

type CustomProductProps = {
  index: number;
  control: Control<CustomProductForm, unknown>;
  remove: UseFieldArrayRemove;
  watch: UseFormWatch<CustomProductForm>;
};

export const CustomProduct = ({
  index,
  control,
  remove,
  watch,
}: CustomProductProps) => {
  const t = useT();
  const { groupName } = useEmployee();

  const { data, status } = useProducts({ groupName });
  const [openCustomVat, setOpenCustomVat] = useState(false);

  if (status !== "success") return null;

  const categories = [
    { value: t("Custom product"), label: t("Custom product") },
    ...data.categories.map((category) => ({
      value: category.name,
      label: category.name,
    })),
  ];

  return (
    <div className="flex w-full items-center gap-5">
      <Controller
        control={control}
        defaultValue={1}
        name={`customProduct.${index}.amount`}
        render={({ field: { value, onChange, ref: _, ...fieldProps } }) => (
          <InputAmount {...fieldProps} onChange={onChange} value={value} />
        )}
      />

      <Controller
        control={control}
        name={`customProduct.${index}.name`}
        render={({ field: { ref: _, ...field } }) => (
          <BaseInput
            {...field}
            className="h-12 w-full"
            placeholder={t("Diverse")}
            type="text"
          />
        )}
      />

      <Controller
        control={control}
        name={`customProduct.${index}.category`}
        render={({ field: { ref: _, ...field } }) => (
          <SelectCreatable
            {...field}
            className="w-full"
            menuPortalTarget={document.body}
            options={categories}
            placeholder={t("Select category")}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        )}
      />

      <Controller
        control={control}
        name={`customProduct.${index}.price`}
        render={({ field: { ref: _, ...field } }) => (
          <PriceInput
            {...field}
            defaultShowingVat
            vatRate={watch(`customProduct.${index}.vatRate`) / 100}
          />
        )}
      />

      <Popover
        content={
          <>
            <Popover.Item
              onClick={() => {
                setOpenCustomVat(true);
              }}
            >
              <span className="text-sm">
                <T _str="Custom VAT rate" />
              </span>
            </Popover.Item>
            <Popover.Item
              onClick={() => {
                remove(index);
              }}
            >
              <div className="flex items-center gap-2 text-sm text-danger-dark">
                <FontAwesomeIcon icon={faTrash} />
                <T _str="Delete" />
              </div>
            </Popover.Item>
          </>
        }
        position="bottom"
      >
        <Button icon={faEllipsisH} variant="secondary" />
      </Popover>

      {openCustomVat ? (
        <Dialog
          onOutsideClick={() => {
            setOpenCustomVat(false);
          }}
          size="sm"
        >
          <div className="flex flex-col gap-5 p-5 text-center">
            <h1 className="text-xl font-medium">
              <T _str="Custom VAT rate" />
            </h1>
            <Controller
              control={control}
              name={`customProduct.${index}.vatRate`}
              render={({ field }) => (
                <div className="flex items-center">
                  <BaseInput
                    {...field}
                    className="w-full rounded-r-none  [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                    placeholder={t("Enter VAT")}
                    type="number"
                  />
                  <div className="rounded-r-md border border-l-0 border-divider-main bg-background-secondary px-3 py-2">
                    <FontAwesomeIcon icon={faPercent} />
                  </div>
                </div>
              )}
            />
            <Button
              onClick={() => {
                setOpenCustomVat(false);
              }}
            >
              <T _str="Save" />
            </Button>
          </div>
        </Dialog>
      ) : null}
    </div>
  );
};
