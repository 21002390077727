import { Outlet } from "react-router-dom";

import { useAuth } from "~/providers/store/auth";
import { Footer } from "~/components/Footer";
import { DialogLoading, LayoutLogo } from "~/components";

const SettingsPage = () => {
  const { status } = useAuth();

  if (status === "none") {
    return (
      <LayoutLogo>
        <DialogLoading />
      </LayoutLogo>
    );
  }

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="h-[calc(100%-theme(spacing.20))] flex-1 px-12">
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};
export { SettingsPage };
