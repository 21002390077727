import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { Button } from "../Button";

export type InputOperation = "increment" | "decrement";

type InputAmountProps = {
  value: number;
  name: string;
  className?: string;
  onChange: (value: number) => void;
  onIncrement?: () => void;
  onDecrement?: () => void;
  size?: "small" | "medium";
  variant?: "default" | "light-green";
  disableMaxButton?: boolean;
};

export const InputAmount = ({
  value,
  onChange,
  onIncrement,
  onDecrement,
  name,
  className,
  size = "small",
  variant = "default",
  disableMaxButton = false,
}: InputAmountProps) => {
  const handleUpdate = (change: number) => {
    const oldAmount = isNaN(value) ? 0 : value;
    const newAmount = oldAmount + change;
    onChange(newAmount);
  };

  const buttonStyle = clsx(
    size === "small" ? "!px-2" : "!px-4",
    "m-0 h-full rounded-md transition-colors",
    {
      "text-primary-contrast": variant === "light-green",
    }
  );

  return (
    <div
      className={clsx(
        "box-content inline-flex items-center rounded-md",
        {
          "border-secondary-main bg-secondary-main border-4":
            variant === "default",
          "bg-[#5BC286] border-t border-1 border-primary-contrast":
            variant === "light-green",
          "h-10": size === "small",
          "h-14": size === "medium",
        },
        className
      )}
    >
      <Button
        className={buttonStyle}
        disabled={value === 0}
        onClick={() => {
          onDecrement?.();
          handleUpdate(-1);
        }}
        type="button"
        variant="transparent"
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
      <input
        className={clsx(
          "mx-1 w-8 rounded  py-1 text-center text-lg font-medium",
          "[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
          {
            "bg-secondary-main text-secondary-contrast": variant === "default",
            "bg-[#5BC286] text-primary-contrast": variant === "light-green",
          }
        )}
        inputMode="numeric"
        name={name}
        pattern="[0-9]*"
        readOnly
        type="number"
        value={value}
      />

      <Button
        className={buttonStyle}
        disabled={disableMaxButton}
        onClick={() => {
          onIncrement?.();
          handleUpdate(1);
        }}
        type="button"
        variant="transparent"
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    </div>
  );
};
