import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useQuery } from "@tanstack/react-query";

import { useAuth } from "~/providers/store/auth";

import { client, getAuthHeader } from "./api.client";

export const schoolsKeys = createQueryKeys("school", {
  detail: (schoolId: string) => ({ queryKey: [schoolId] }),
  list: (partnerId: string) => ({ queryKey: [partnerId] }),
});

export function useSchool(
  schoolId: string | null | undefined,
  selectorQuery?: string
) {
  return useQuery({
    ...schoolsKeys.detail(schoolId || "empty"),
    queryFn: async () => {
      if (!schoolId) throw new Error("No school id provided");

      const res = await client.v1.pos.schools.getById({
        params: { schoolId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    select: (data) => {
      if (!selectorQuery) return data;

      // find selector that matches a part of the query
      const selectorsOptions = data?.selector?.options.filter((s) =>
        s.name.toLowerCase().includes(selectorQuery.toLowerCase())
      );

      return {
        ...data,
        selector: { ...data.selector, options: selectorsOptions },
      };
    },
    enabled: Boolean(schoolId),
  });
}

export function useCurrentSchool() {
  const { schoolId } = useAuth();

  const schoolQuery = useSchool(schoolId);

  return schoolQuery;
}

export function useSchoolsByPartner(partnerId: string | null | undefined) {
  return useQuery({
    ...schoolsKeys.list(partnerId || ""),
    queryFn: async () => {
      if (!partnerId) throw new Error("No partner id provided");

      const res = await client.v1.pos.schools.get({
        query: { partnerId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(partnerId),
  });
}
