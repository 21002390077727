export const isPaymentInProgress = (pathname: string) => {
  return (
    pathname.includes("card") ||
    pathname.includes("cash") ||
    pathname.includes("billing") ||
    pathname.includes("mobilepay") ||
    pathname.includes("credit") ||
    pathname.includes("menu") ||
    pathname.includes("settings")
  );
};
