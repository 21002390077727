import { T } from "@repo/transifex";

import { Button, Dialog } from "~/components";
import { useAppDispatch, useCountDownState } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { employeeActions } from "~/providers/store/employee";

export const DialogStandBy = () => {
  const dispatch = useAppDispatch();

  const { count } = useCountDownState();

  const handleCancelClick = () => {
    dispatch({ type: "COUNTDOWN_RESET" });
    basketActions.reset();
    basketActions.resetDiningOption();
    employeeActions.reset();
  };

  const handleResetCountdown = () => {
    dispatch({ type: "COUNTDOWN_RESET" });
  };

  return (
    <Dialog onOutsideClick={handleResetCountdown} size="md">
      <div className="flex flex-col items-center p-8">
        <p className="mb-8 text-center text-xl font-semibold">
          <T _str="Are you still there?" />
        </p>

        <Button
          className="mb-4 w-full"
          onClick={handleCancelClick}
          variant="danger-light"
        >
          <T _str="Cancel order {seconds}" seconds={count} />
        </Button>

        <Button className="w-full" onClick={handleResetCountdown}>
          <T _str="Continue ordering" />
        </Button>
      </div>
    </Dialog>
  );
};
