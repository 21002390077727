import { T } from "@repo/transifex";
import { Link } from "react-router-dom";

import { Button, LayoutLogo } from "~/components";

const NotFoundPage = () => {
  return (
    <LayoutLogo className="flex-col items-center justify-center">
      <p className="mb-4 text-9xl font-bold text-gray-700">Oops!</p>

      <p className="mb-2 text-xl font-bold uppercase">
        <T _str="404 - Page not found" />
      </p>

      <p className="mb-4 w-72 text-center">
        <T _str="The page you are looking for does not exist or may be temporarily unavailable" />
      </p>

      <Link to="/menu">
        <Button variant="danger">
          <T _str="Back to POS" />
        </Button>
      </Link>
    </LayoutLogo>
  );
};

export { NotFoundPage };
