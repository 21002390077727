import { clsx } from "clsx";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import { Button } from "~/components";
import { useProducts } from "~/hooks/queries";
import { useAppDispatch, useSplitPaymentFlow } from "~/providers/app";
import { useEmployee } from "~/providers/store/employee";

type HeaderLinkProps = {
  to: string;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
};

const HeaderCategoryLink = ({
  to,
  onClick,
  children,
  className,
}: HeaderLinkProps) => {
  const [ref, setRef] = useState<HTMLParagraphElement | null>(null);

  const lines = useMemo(() => {
    if (ref === null) return 3;

    const pHeight = ref.offsetHeight;
    const lineHeight = Number(ref.style.lineHeight.replace("px", ""));
    return pHeight / lineHeight;
  }, [ref]);

  return (
    <NavLink className={className} onClick={onClick} to={to}>
      {({ isActive }) => (
        <Button
          className="h-16 w-full rounded-none"
          size={lines > 2 ? "small" : "medium"}
          variant={isActive ? "info" : "info-inverted"}
        >
          <p
            className={clsx("line-clamp-3 h-full truncate text-wrap")}
            ref={(newRef) => {
              setRef(newRef);
            }}
            style={{ lineHeight: "18px" }}
          >
            {children}
          </p>
        </Button>
      )}
    </NavLink>
  );
};

type HeaderCategoriesLayoutProps = {
  children: React.ReactNode;
  count: number;
};

export const HeaderCategoriesLayout = ({
  children,
  count,
}: HeaderCategoriesLayoutProps) => {
  return (
    <div
      className={clsx("grid grid-cols-7 gap-1", {
        "!grid-cols-4": count <= 4 || count === 8,
        "!grid-cols-5": count === 5 || (count >= 9 && count <= 10),
        "!grid-cols-6": count === 6 || (count >= 11 && count <= 12),
      })}
    >
      {children}
    </div>
  );
};

const skeletonArray = Array.from(Array(4).keys()).map((_, index) => ({
  id: index,
}));

export const HeaderCategories = () => {
  const { groupName } = useEmployee();
  const dispatch = useAppDispatch();

  const { data, status } = useProducts({ groupName });

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  if (status !== "success") {
    return (
      <HeaderCategoriesLayout count={4}>
        {skeletonArray.map(({ id }) => (
          <Button
            className="h-16 w-full animate-pulse rounded-none"
            key={id}
            variant="skeleton"
          >
            loading
          </Button>
        ))}
      </HeaderCategoriesLayout>
    );
  }

  const categoryCount = data.categories.length;

  if (categoryCount < 2) return null;

  return (
    <HeaderCategoriesLayout count={categoryCount}>
      {data.categories.map((category) => (
        <HeaderCategoryLink
          className={clsx({ "pointer-events-none": isSplitPaymentActive })}
          key={category.id}
          onClick={() => {
            dispatch({ type: "COUNTDOWN_RESET" });
          }}
          to={`/pos/${category.id}`}
        >
          {category.name}
        </HeaderCategoryLink>
      ))}
    </HeaderCategoriesLayout>
  );
};
