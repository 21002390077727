import { useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { DialogError } from "~/components";
import { useSplitPaymentFlow, useBasketProducts } from "~/providers/app";
import { GridLayout } from "~/components/GridLayout";
import { ItemSkeleton } from "~/components/ItemSkeleton";

import { ProductItem } from "./ProductItem";

const PosCategoryProductsPage = () => {
  const params = useParams();

  const { products, productsStatus, meta } = useBasketProducts();
  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  const categoryProducts = useMemo(() => {
    const category = params.categoryId;

    if (!category || category === "all") {
      return products;
    }

    return products.filter((p) => p.category === category);
  }, [products, params.categoryId]);

  if (productsStatus === "pending") {
    return (
      <GridLayout>
        {Array.from({ length: 16 }).map(() => (
          <ItemSkeleton key={uuidv4()} />
        ))}
      </GridLayout>
    );
  }

  if (productsStatus === "error") {
    return <DialogError backUrl="/menu" />;
  }

  return (
    <>
      <GridLayout>
        {categoryProducts.map((product) => (
          <ProductItem
            disabled={isSplitPaymentActive}
            key={product.id}
            offset={meta.offset}
            product={product}
            unitSystem={product.unitSystem}
          />
        ))}
      </GridLayout>

      <Outlet />
    </>
  );
};

export { PosCategoryProductsPage };
