import { useEffect } from "react";

import { useAppDispatch, useCountDownState } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";
import { employeeActions } from "~/providers/store/employee";

export function useCountdown() {
  const dispatch = useAppDispatch();
  const { count, enabled } = useCountDownState();

  useEffect(() => {
    if (count === 0) {
      basketActions.reset();
      basketActions.resetDiningOption();
      employeeActions.reset();
      dispatch({ type: "COUNTDOWN_RESET" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dispatch is optimized
  }, [count]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const intervalId = setInterval(() => {
      dispatch({ type: "COUNTDOWN_TICK" });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- dispatch is optimized
  }, [enabled]);
}
