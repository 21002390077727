import { Navigate, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";

import { T, useT } from "@repo/transifex";
import type { PosProduct, Product, StampCardDiscount } from "@repo/types";
import { Button, Dialog } from "~/components";
import { useBasketProducts } from "~/providers/app";
import { getEmployee } from "~/providers/store/employee";
import { priceFormatter, round } from "@repo/system";
import { basketActions } from "~/providers/store/basket";
import { useAuth } from "~/providers/store/auth";

import { StampCardSection } from "./StampCardSection";
import { ProductRadioButton } from "./ProductRadioButton";

type StampCardFormType = Record<
  NonNullable<StampCardDiscount["stampCardRuleId"]>,
  Product["id"]
>;

const StampcardPage = () => {
  const t = useT();
  const navigate = useNavigate();

  const { locale } = useAuth();

  const { stampCardDiscounts } = getEmployee();

  const {
    products,
    productsInBasket,
    meta: { currency },
  } = useBasketProducts();

  const methods = useForm<StampCardFormType>({
    defaultValues: productsInBasket.reduce<StampCardFormType>(
      (acc, product) => {
        if (product.stampCardRuleId) {
          acc[product.stampCardRuleId] = product.id;
        }
        return acc;
      },
      {}
    ),
  });
  const { handleSubmit, watch, register } = methods;

  const handleOnClose = () => {
    basketActions.removeRedeemed();
    navigate("..");
  };

  const onSubmit = (data: StampCardFormType) => {
    Object.entries(data)
      .filter(([_, productId]) => Boolean(productId))
      .forEach(([stampCardRuleId, productId]) => {
        if (!productId) throw new Error("Product not selected");

        const stampcard = stampCardDiscounts.find(
          (stampCard) => stampCard.stampCardRuleId === stampCardRuleId
        );
        const product = products.find((product) => product.id === productId);

        if (!stampcard) throw new Error("Stampcard not found");
        if (!product) throw new Error("Product not found");

        const discountedUnitPrice =
          stampcard.discountType === "percentage"
            ? product.unitPrice - product.unitPrice * (stampcard.discount / 100)
            : product.unitPrice - stampcard.discount;

        const discountUnitPrice = round(
          product.unitPrice - discountedUnitPrice,
          2
        );

        basketActions.addRedeemed(
          productId,
          discountUnitPrice,
          stampCardRuleId
        );
      });

    navigate("..");
  };

  const formSections = mapStampCardDiscountsWithRedeemableProducts(
    products,
    stampCardDiscounts
  );

  if (
    formSections.length === 0 ||
    !formSections.some((section) => section.redeemableProducts.length > 0)
  ) {
    return <Navigate to=".." />;
  }

  return (
    <Dialog
      onOutsideClick={() => {
        navigate("..");
      }}
      size="lg"
    >
      <div className="flex flex-col justify-center p-8">
        <p className="mb-2 whitespace-pre text-center text-2xl font-bold">
          <T _str={`You have a reward!\nDo you want to redeem it now?`} />
        </p>
        <FormProvider {...methods}>
          <form className="max-h-96 w-full overflow-y-scroll">
            {formSections.map((section) => (
              <StampCardSection
                discount={t("{discount} off 1 selected product", {
                  discount:
                    section.stampCardDiscount.discountType === "percentage"
                      ? `${section.stampCardDiscount.discount} %`
                      : priceFormatter({
                          value: section.stampCardDiscount.discount,
                          currency,
                          locale,
                        }),
                })}
                key={section.stampCardDiscount.stampCardRuleId}
              >
                {section.redeemableProducts.map((product) => (
                  <ProductRadioButton
                    displayPrice={product.displayPrice}
                    displayPriceDiscounted={priceFormatter({
                      value:
                        section.stampCardDiscount.discountType === "percentage"
                          ? product.unitPrice -
                            product.unitPrice *
                              (section.stampCardDiscount.discount / 100)
                          : product.unitPrice -
                            section.stampCardDiscount.discount,
                      currency,
                      locale,
                    })}
                    id={product.id}
                    key={product.id}
                    label={product.name}
                    {...register(
                      section.stampCardDiscount.stampCardRuleId ?? "unknown"
                    )}
                  />
                ))}
              </StampCardSection>
            ))}
          </form>
        </FormProvider>
      </div>

      <div className="grid w-full grid-cols-2 gap-4 border-t p-4">
        <Button onClick={handleOnClose} variant="secondary">
          <T _str="Not now" />
        </Button>
        <Button
          disabled={Object.values(watch()).every((value) => !value)}
          onClick={handleSubmit(onSubmit)}
          type="submit"
        >
          <T _str="Redeem reward" />
        </Button>
      </div>
    </Dialog>
  );
};

const mapStampCardDiscountsWithRedeemableProducts = (
  products: PosProduct[],
  stampCardDiscounts: StampCardDiscount[]
) => {
  return stampCardDiscounts.map((stampCardDiscount) => {
    const redeemableProducts = products.filter((product) => {
      // variants not supported
      if (product.options.length > 0) return false;

      // variant-weight not supported
      if (product.unitSystem && product.unitSystem !== "piece") return false;

      return stampCardDiscount.productIds.includes(product.id);
    });

    return {
      stampCardDiscount,
      redeemableProducts,
    };
  });
};

export { StampcardPage };
