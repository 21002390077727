import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient } from "@tanstack/react-query";
import { T } from "@repo/transifex";
import { getSupportInfoFromCountryCode } from "@repo/system";
import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { clsx } from "clsx";

import { authActions, useAuth } from "~/providers/store/auth";

import { Button } from "./Button";
import { Dialog, type DialogProps } from "./Dialog";

type DialogErrorProps = Partial<DialogProps> & {
  error?: React.ReactNode;
  childrenPosition?: "top" | "bottom";
  hideSupport?: boolean;
  showLogout?: boolean;
};
export const DialogError = ({
  error,
  children,
  hideSupport = false,
  showLogout = false,
  childrenPosition = "bottom",
  ...props
}: DialogErrorProps) => {
  const queryClient = useQueryClient();

  const { country } = useAuth();
  const { email, phone } = getSupportInfoFromCountryCode(country);

  return (
    <Dialog size="sm" {...props}>
      <div className="flex flex-col items-center gap-y-5 p-8">
        <div className="flex flex-col items-center gap-y-5 text-center">
          <ErrorMessage error={error} />

          {childrenPosition === "top" ? children : null}

          <div className={clsx("w-full rounded-md bg-background-secondary p-3", {
            "hidden": hideSupport && error,
          })}>
            {!error && (
              <p className="mb-2 text-xs">
                <T _str="Experiencing issues or need help?" />
              </p>
            )}

            {!hideSupport && (
              <div className="flex flex-col gap-y-2.5">
                <a className="text-xs text-primary-dark" href={`tel:${phone}`}>
                  {phone}
                </a>
                <a
                  className="text-xs text-primary-dark"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </div>
            )}
          </div>
        </div>

        {childrenPosition === "bottom" ? children : null}

        {showLogout ? (
          <Button
            onClick={() => {
              authActions.logout();
              queryClient.removeQueries();
            }}
            variant="danger"
          >
            <T _str="Logout" />
          </Button>
        ) : null}
      </div>
    </Dialog>
  );
};

export const ErrorMessage = ({ error }: { error?: React.ReactNode }) => (
  <div className="flex flex-col items-center gap-y-5 text-center">
    <FontAwesomeIcon className="text-5xl text-danger-dark" icon={faWarning} />

    <p className="text-xl">
      {typeof error === "string" ? (
        <T _str={error || "Something went wrong"} />
      ) : (
        error
      )}
    </p>
  </div>
);
