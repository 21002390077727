import { faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { T, useT } from "@repo/transifex";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

import { Button, DialogError, Field } from "~/components";
import { useImpersonateTill } from "~/hooks/queries";
import { useDeviceData } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

type SwitchTillInputs = {
  tillId: string;
};

const AdminPage = () => {
  const t = useT();

  const { deviceTillId, isKanplaAdmin } = useAuth();
  const { deviceId } = useDeviceData();
  const { mutate: impersonateTill, status: impersonateStatus } =
    useImpersonateTill();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SwitchTillInputs>();

  if (!isKanplaAdmin) {
    return <Navigate replace to="/profiles" />;
  }

  if (impersonateStatus === "error") {
    return (
      <DialogError
        error={t("Something went wrong, you can try logging in again")}
        hideSupport
      >
        <Button
          onClick={() => {
            location.reload();
          }}
          variant="danger"
        >
          <T _str="Reload" />
        </Button>
      </DialogError>
    );
  }

  return (
    <div className="size-full bg-slate-800 p-10">
      <div className="relative mb-11 flex flex-col items-center">
        <h1 className="mb-2 text-5xl font-bold text-white">
          <T _str="Admin" />
        </h1>
        <h2 className="text-2xl font-bold text-white">
          <T _str="Switch till" />
        </h2>

        <form
          className="my-16 flex flex-col gap-3"
          onSubmit={handleSubmit(({ tillId }) => {
            impersonateTill({ tillId });
          })}
        >
          <Field
            error={errors.tillId?.message}
            label="Till ID"
            labelClassName="text-white"
            {...register("tillId", { required: t("Till ID is required") })}
          />
          <Button
            icon={faRepeat}
            loading={impersonateStatus === "pending"}
            size="large"
            type="submit"
            variant="primary"
          >
            <T _str="Switch till" />
          </Button>

          <Button
            loading={impersonateStatus === "pending"}
            onClick={() => {
              if (!deviceTillId) throw new Error("DeviceTillId is missing");
              if (!deviceId) throw new Error("Device ID is missing");

              impersonateTill({ tillId: deviceTillId, deviceId });
            }}
            size="large"
            variant="info"
          >
            <T _str="Switch to device till" />
          </Button>

          <Button
            disabled={impersonateStatus === "pending"}
            onClick={() => {
              navigate("/profiles");
            }}
            size="large"
            variant="dark-blue"
          >
            <T _str="Back" />
          </Button>
        </form>
      </div>
    </div>
  );
};

export { AdminPage };
