import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { getAuth, getAuthStatus } from "./getters";
import { authStore } from "./store";
import type { AuthState } from "./store.types";

function useAuth(): AuthState {
  return useStore(authStore, useShallow(getAuth));
}

function useAuthStatus(): AuthState["status"] {
  return useStore(authStore, getAuthStatus);
}

function useIsOnline(): AuthState["isOnline"] {
  return useStore(authStore, (state) => state.isOnline);
}

export { useAuth, useAuthStatus, useIsOnline };
