import { useEffect, useRef } from "react";

import { useAppContext, useAppDispatch } from "~/providers/app";

type UseScanProps = {
  onScan: (args: { code: string; isQrCode: boolean }) => void;
};

/**
 * This hook is used to read a barcode.
 * @param {UseScanProps} args contains the onScan function
 */
export function useCodeScanner({ onScan }: UseScanProps) {
  const codeRef = useRef("");

  const {
    hardware: { barcode: printerBarcode },
  } = useAppContext();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (printerBarcode) {
      const validation = validateCode(printerBarcode.trim());

      if (validation) {
        onScan(validation);
      }

      dispatch({ type: "CLEAR_BARCODE_SCANNED" });

      return;
    }

    let timeoutId: ReturnType<typeof setTimeout>;

    /**
     * This function is used to handle the input from the card reader and barcode scanner.
     * It will wait for 200ms to see if the user is done scanning.
     * It needs to handle the Clear key as well as the Enter key. Since there's a model that fires, before everything, the Clear key.
     * Therefore we need to wait for the reader the finish the input.
     * @param {KeyboardEvent} event keyboard event
     * @returns {void}
     */
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Clear") {
        clearTimeout(timeoutId);
      } else {
        // if the event.key is Enter (bar-codes) or Shift (QR codes), we don't want to append it to the code.
        if (event.key === "Enter" || event.key === "Shift") {
          // prevent to propagate the event to a HTML node
          event.preventDefault();
          return;
        }

        // build the code up
        codeRef.current += event.key;

        // clear the current timeout to only have one that fires
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          const validation = validateCode(codeRef.current);
          // reset
          codeRef.current = "";

          if (validation) {
            onScan(validation);
          }
        }, 200);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- printerBarcode is the only necessary dependency
  }, [printerBarcode]);
}

function validateCode(scanned: string) {
  /**
   * This regex is used to validate the barcode.
   * For product barcodes commonly used in retail, the most common formats are UPC-A and EAN-13.
   * - UPC-A (Universal Product Code - Version A): It has a fixed length of 12 digits.
   * - EAN-13 (European Article Number): It has a fixed length of 13 digits.
   *
   * Then we will use a regular expression that matches a combination of characters and digits with a length of 12 or more.
   */
  const barCoderegex = /^[A-Za-z0-9]{8,}$/;

  /**
   * This regex matches for any QR code with this format START<childId>END.
   * The childId will be used to identify the user that has a stamp-card.
   */
  const qrCodeRegex = /^START[a-zA-Z0-9?]+END$/;
  if (Boolean(barCoderegex.exec(scanned)) && !qrCodeRegex.exec(scanned)) {
    return { code: scanned, isQrCode: false };
  }

  if (qrCodeRegex.exec(scanned)) {
    // Development data issue with docs ids
    // they are saved with underscores
    let qrCode = scanned;
    if (scanned.includes("?")) {
      qrCode = qrCode.replaceAll("?", "_");
    }

    // replace prefix and suffix
    qrCode = qrCode.replace("START", "").replace("END", "");

    // handle qr code output
    return { code: qrCode, isQrCode: true };
  }

  return undefined;
}
