import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { T } from "@repo/transifex";
import { useState } from "react";

import { Button } from "~/components";
import { useSalesReportCombined } from "~/hooks/queries/reportsCombined";

import { DialogSendReportCombinedEmail } from "./DialogSendReportCombinedEmail";

export const SendReportCombinedButton = ({
  dateSeconds,
}: {
  dateSeconds?: string;
}) => {
  const [open, setOpen] = useState(false);

  const { data, status: reportStatus } = useSalesReportCombined(
    Number(dateSeconds || 0)
  );

  if (reportStatus === "error") return null;

  return (
    <>
      <Button
        className="w-full"
        icon={faEnvelope}
        loading={reportStatus === "pending"}
        onClick={() => {
          setOpen(true);
        }}
        size="large"
        variant="primary-light"
      >
        <T _str="Send report" />
      </Button>

      {data?.reports?.length ? (
        <DialogSendReportCombinedEmail
          dateSeconds={dateSeconds}
          onOutsideClick={() => {
            setOpen(false);
          }}
          open={open}
        />
      ) : null}
    </>
  );
};
