export const CurveArrow = () => {
  return (
    <svg
      fill="none"
      height="37"
      viewBox="0 0 102 37"
      width="102"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M17.2315 36.4561C14.4914 37.1903 13.6198 36.1941 7.1615 24.9476C-3.02525 7.20927 -2.99741 7.13929 15.9961 3.1638C23.5146 1.59134 29.9163 0.448826 30.2228 0.625817C30.5294 0.802806 30.3764 1.82692 29.8824 2.90147C28.6868 5.50172 23.0481 8.7322 16.5473 10.54L11.2587 12.0105L16.505 14.6235C22.2403 17.4795 35.971 21.2299 47.1868 23.0048C64.3377 25.7172 81.2969 23.2267 93.6389 16.1828C97.7053 13.8623 101.195 12.0487 101.395 12.1543C102.857 12.9275 100.579 16.9997 96.6705 20.6042C92.3613 24.5763 91.0138 25.1761 79.2791 28.3363C68.8463 31.1465 65.0082 31.6976 57.9391 31.3971C47.0465 30.9346 35.0152 28.7291 23.895 25.1566C19.1209 23.6229 15.0973 22.57 14.9545 22.8174C14.8106 23.0651 15.7575 25.106 17.058 27.3511C19.8846 32.2333 19.9514 35.7273 17.2315 36.4561Z"
        fill="#202735"
        fillRule="evenodd"
        id="Intersect"
      />
    </svg>
  );
};
