import { clsx } from "clsx";

import { useDeviceData } from "~/providers/app";
import { BasketWrapper } from "~/routes/__app.pos/BasketWrapper";

type LayoutProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
};

export const ShopLayout = ({ children, footer, header }: LayoutProps) => {
  const { os } = useDeviceData();

  return (
    <div className={clsx("flex size-full", { "pt-6": os === "android" })}>
      <div className="flex h-full w-2/3 flex-[2] flex-col justify-between">
        {header}
        <div className="flex-1 overflow-auto scrollbar-hide">{children}</div>
        {footer}
      </div>

      <div className="flex h-full w-1/3 flex-1">
        <BasketWrapper />
      </div>
    </div>
  );
};
