import { faTrophy } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ReactNode } from "react";

import { T } from "@repo/transifex";

type StampCardSectionProps = {
  children: ReactNode;
  discount: string;
};
export const StampCardSection = ({
  children,
  discount,
}: StampCardSectionProps) => {
  return (
    <div className="w-full">
      <div className="flex justify-between py-4">
        <p className="text-primary-light">
          <FontAwesomeIcon className="mr-2" icon={faTrophy} />
          <T _str="Reward" />
        </p>
        <p className="text-primary-light">
          <T _str="{discount} off 1 selected product" discount={discount} />
        </p>
      </div>
      <div>
        <p className="mb-2 text-text-disabled">
          <T _str="Select item to redeem" />
        </p>
        <div className="grid grid-cols-2 gap-2">{children}</div>
      </div>
    </div>
  );
};
