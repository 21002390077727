import { useNavigate } from "react-router-dom";
import type { ComponentProps } from "react";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";

import { Button } from "./Button";

type BackButtonProps = ComponentProps<typeof Button> & {
  to?: string;
};

export const BackButton = ({ to, ...props }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      {...props}
      icon={faChevronLeft}
      onClick={() => {
        to ? navigate(to) : navigate(-1);
      }}
      type="button"
    />
  );
};
