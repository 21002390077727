import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, useT } from "@repo/transifex";
import { Link } from "react-router-dom";

import { SettingsLayout, TillCard, TillCardSkeleton } from "~/components";
import { useCurrentTill, useSession, useTills } from "~/hooks/queries";

type LayoutProps = {
  children: React.ReactNode;
  showWarning?: boolean;
};

const Layout = ({ children, showWarning }: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      title={t("Register Management")}
      {...(showWarning && {
        headerChildren: (
          <div className="flex h-8 content-center items-center gap-2 rounded-md bg-warning-backdrop p-2 text-warning-main">
            <FontAwesomeIcon icon={faWarning} />
            <T _str="A register needs to be closed from yesterday" />
          </div>
        ),
      })}
      className="pb-0"
    >
      {children}
    </SettingsLayout>
  );
};

const TillsPage = () => {
  const { data: tills, status } = useTills();

  const { data: currentTill } = useCurrentTill();
  const { data: session } = useSession(currentTill?.sessionId);

  if (status === "pending")
    return (
      <Layout>
        <div className="grid grid-cols-3 gap-6">
          <TillCardSkeleton />
          <TillCardSkeleton />
          <TillCardSkeleton />
        </div>
      </Layout>
    );

  if (status === "error" || !tills.length)
    return (
      <Layout>
        <div className="h-60 rounded-md bg-gray-200">
          <div className="flex h-full flex-col items-center justify-center gap-6 py-16">
            <T _str="No tills found" />
          </div>
        </div>
      </Layout>
    );

  return (
    <Layout showWarning={!(session?.isValid ?? false)}>
      <div className="grid grid-cols-3 gap-6 overflow-auto pb-5 scrollbar-hide">
        {tills.map((till) => (
          <Link key={till.id} to={till.id}>
            <TillCard
              className="relative h-full !justify-start"
              name={till.name}
              selected={currentTill?.id === till.id}
              showWarning={!(session?.isValid ?? false)}
              status={till.sessionId ? "open" : "closed"}
            />
          </Link>
        ))}
      </div>
    </Layout>
  );
};

export { TillsPage };
