import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment-timezone";

import { getAuth } from "~/providers/store/auth";

import { client, getAuthHeader } from "./api.client";

export const reportsCombinedKeys = createQueryKeys("reportsCombined", {
  detail: ({ dateSeconds }: { dateSeconds: number }) => ({
    queryKey: [{ dateSeconds }],
  }),
});

function useSalesReportCombined(dateSeconds: number) {
  return useQuery({
    ...reportsCombinedKeys.detail({ dateSeconds }),
    queryFn: async () => {
      const { profileId, schoolId, moduleId, status, tillId } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v2.pos.reportsCombined.get({
        params: {
          schoolId,
          moduleId,
          isoDate: moment(dateSeconds * 1000).format("YYYY-MM-DD"),
        },
        query: { profileId, tillId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      if (res.status === 404) {
        return { reports: [], receipt: null };
      }

      throw res;
    },
    staleTime: 1000 * 30, // 30 seconds
  });
}

function useSendEmailReportCombined(dateSeconds: number) {
  return useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      const { profileId, schoolId, moduleId, status, tillId } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v2.pos.reportsCombined.email({
        params: {
          schoolId,
          moduleId,
          isoDate: moment(dateSeconds * 1000).format("YYYY-MM-DD"),
        },
        body: { profileId, email, tillId },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
  });
}

export { useSalesReportCombined, useSendEmailReportCombined };
