import {
  faCircleInfo,
  faGrid2,
  faLock,
} from "@fortawesome/pro-solid-svg-icons";
import { T } from "@repo/transifex";
import { clsx } from "clsx";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";

import { useCurrentTill, useSession } from "~/hooks/queries";
import { useSplitPaymentFlow } from "~/providers/app";

import { Button } from "./Button";
import { UnattendedLogoutButton } from "./UnattendedLogoutButton";
import { DialogCancelSplitPayment } from "./DialogCancelSplitPayment";

type FooterProps = {
  children?: React.ReactNode;
};

export const Footer = ({ children }: FooterProps) => {
  return (
    <div className="flex h-20 w-full shrink-0 items-center gap-4 border-t border-divider-main bg-background-secondary px-3">
      <FooterButton />

      {children}
    </div>
  );
};

const FooterButton = () => {
  const { pathname } = useLocation();

  const { data: till, status: tillStatus } = useCurrentTill();
  const { data: session } = useSession(till?.sessionId);

  const { isActive: isSplitPaymentActive } = useSplitPaymentFlow();

  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  if (tillStatus !== "success") {
    return (
      <Button
        className="shadow"
        disabled
        icon={faLock}
        variant="secondary-inverted"
      />
    );
  }

  const showTransactionsWarning =
    pathname === "/settings/transactions" && !session?.isValid;

  if (till.type === "attended" || pathname.includes("/settings")) {
    return (
      <>
        <div
          className={clsx("flex justify-between", {
            "w-full": showTransactionsWarning,
          })}
          onClick={() => {
            isSplitPaymentActive && setCancelDialogOpen(true);
          }}
        >
          <Link
            className={clsx({ "pointer-events-none": isSplitPaymentActive })}
            to="/menu"
          >
            <Button
              className="shadow"
              icon={faGrid2}
              variant="secondary-inverted"
            />
          </Link>
          {showTransactionsWarning ? (
            <Button icon={faCircleInfo} variant="info-inverted">
              <T _str="Open till in order to do refunds" />
            </Button>
          ) : null}
        </div>

        {cancelDialogOpen ? (
          <DialogCancelSplitPayment
            onClose={() => {
              setCancelDialogOpen(false);
            }}
          />
        ) : null}
      </>
    );
  }

  return <UnattendedLogoutButton />;
};
