import { faDeleteLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";

import { SquareButton } from "./SquareButton";

type PinKeyboardProps = {
  onInputChange: (value: number) => void;
  error?: string;
};

export const PinKeyboard = ({ onInputChange, error }: PinKeyboardProps) => {
  return (
    <>
      {error ? (
        <div className="mb-3 text-center text-danger-main">
          <T _str={error} />
        </div>
      ) : null}

      <div className="m-auto grid w-full grid-cols-3 grid-rows-4 gap-4">
        {pinButtons.map(({ value, type, label }) => (
          <SquareButton
            key={value}
            onClick={() => {
              onInputChange(value);
            }}
            variant={type}
          >
            {label || value}
          </SquareButton>
        ))}
      </div>
    </>
  );
};

const pinButtons: {
  value: number;
  label?: string | JSX.Element;
  type?: "default" | "danger" | "success";
}[] = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  {
    value: -1,
    label: <FontAwesomeIcon color="white" icon={faDeleteLeft} size="xs" />,
    type: "danger",
  },
  { value: 0 },
];
