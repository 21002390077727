import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";

import type { SelfServiceConfig } from "@repo/types";
import { T } from "@repo/transifex";
import {
  Button,
  DialogError,
  DialogLoading,
  UnattendedLogoutButton,
} from "~/components";
import { OrderCreateErrorDialog } from "~/components/OrderCreateErrorDialog";
import { useCardReader } from "~/hooks";
import {
  useAuthEmployeeCard,
  useCurrentTill,
  useOrderCreate,
  usePrintOrderReceipt,
} from "~/hooks/queries";
import {
  useAppDispatch,
  useBasketProducts,
  useCountDownState,
} from "~/providers/app";
import { employeeActions, getEmployee } from "~/providers/store/employee";
import { isApiError } from "~/utils/api";

import ScanCardSvg from "./ScanCardSvg";
import { ScanErrorSvg } from "./ScanErrorSvg";
import { ScanLoading } from "./ScanLoading";

export const EmployeeCardPage = () => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const { count } = useCountDownState();
  const dispatch = useAppDispatch();

  const {
    products,
    meta: { currency },
  } = useBasketProducts();

  const { data: till, status: tillStatus } = useCurrentTill();

  const {
    mutate: submitCode,
    isPending: isLoading,
    error,
    reset,
  } = useAuthEmployeeCard();

  const {
    mutate: createOrder,
    status: createOrderStatus,
    error: createOrderError,
    reset: createOrderReset,
  } = useOrderCreate();

  const { mutate: createReceipt } = usePrintOrderReceipt();

  useEffect(() => {
    dispatch({ type: "COUNTDOWN_STOP" });

    if (error) {
      dispatch({ type: "COUNTDOWN_RESET", payload: 5 });
      dispatch({ type: "COUNTDOWN_START", payload: 5 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on error
  }, [error]);

  useEffect(() => {
    if (count === 0) {
      dispatch({ type: "COUNTDOWN_STOP" });
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on count
  }, [count]);

  useCardReader({
    onScan: (code) => {
      dispatch({ type: "COUNTDOWN_STOP" });
      submitCode(
        { cardHex: code, currency },
        {
          onSuccess: () => {
            if (
              products.length === 1 &&
              till?.moduleConfig.paymentMethod === "billing"
            ) {
              const product = products[0];

              const { groupName, employeeUserId, employeeChildId } =
                getEmployee();

              if (!employeeUserId || !employeeChildId) {
                throw new Error("Employee not found");
              }

              createOrder(
                {
                  paymentMethod: "billing",
                  basket: [
                    {
                      amount: 1,
                      category: product.category,
                      unitPrice: product.unitPrice,
                      name: product.name,
                      id: product.id,
                      productLineId: product.productLineId,
                      type: "regular",
                      vatRate: product.vatRate,
                      taxRateId: product.taxRateId,
                      taxRateName: product.taxRateName,
                    },
                  ],
                  groupName: groupName || undefined,
                  employeeUserId,
                  employeeChildId,
                  paidWithSaldoUnit: 0,
                },
                {
                  onSuccess: (order) => {
                    createReceipt({
                      orderId: order.id,
                      isTicket: till?.moduleConfig.hidePrices ?? false,
                    });

                    navigate("/pos/all/billing");
                    setSearchParams({ orderId: order.id });
                  },
                }
              );
              return;
            }

            navigate("/pos");
          },
          onError: (error) => {
            // eslint-disable-next-line no-console -- for debugging
            console.error("Could not submit scanned code", error);
            Sentry.captureException(error, {
              extra: {
                code,
                tillId: till?.id,
                moduleConfig: till?.moduleConfig,
              },
            });
          },
        }
      );
    },
  });

  if (tillStatus === "pending" || createOrderStatus === "pending")
    return <DialogLoading />;

  // Till not found or wrong route
  if (
    tillStatus === "error" ||
    till.type !== "self-service" ||
    !till.config.hasEmployeeCardReader
  )
    return (
      <DialogError childrenPosition="top" size="md">
        <T _str="Employee card scanning is not available" />
        <Link to="/pos">
          <Button variant="danger">
            <T _str="Back" />
          </Button>
        </Link>
      </DialogError>
    );

  // Billing order creation error
  if (createOrderStatus === "error") {
    const orderError = isApiError(createOrderError)
      ? createOrderError.body.message
      : "Could not create order";

    return (
      <OrderCreateErrorDialog
        error={orderError}
        open
        reset={() => {
          employeeActions.reset();
          createOrderReset();
          reset();
        }}
      />
    );
  }

  // Employee card scanning error
  if (error) {
    const isChildNotFoundError =
      isApiError(error) &&
      error.status === 404 &&
      error.body.message === "Child not found";
    return (
      <EmployeeCardScanWrapper guestSelector={till.config.guestSelector}>
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-center text-4xl text-text-primary">
            {isChildNotFoundError ? (
              <T _str="Sorry, we can't find your employee card" />
            ) : (
              <T _str="Oops, something went wrong. Please try again" />
            )}
          </p>

          {isChildNotFoundError ? (
            <div className="w-fit rounded-md bg-primary-backdrop px-4 py-2 text-primary-dark">
              <T _str="Please contact your administrator" />
            </div>
          ) : null}
        </div>

        <div className="absolute bottom-0">
          <ScanErrorSvg />
        </div>
      </EmployeeCardScanWrapper>
    );
  }

  if (isLoading) {
    return (
      <EmployeeCardScanWrapper
        className="opacity-30"
        guestSelector={till.config.guestSelector}
      >
        <ScanLoading />
      </EmployeeCardScanWrapper>
    );
  }

  return (
    <EmployeeCardScanWrapper guestSelector={till.config.guestSelector}>
      <div className="absolute bottom-0">
        <ScanCardSvg />
      </div>
    </EmployeeCardScanWrapper>
  );
};

type EmployeeCardScanWrapperProps = {
  children: React.ReactNode;
  className?: string;
} & Pick<SelfServiceConfig, "guestSelector">;

const EmployeeCardScanWrapper = ({
  guestSelector,
  children,
  className,
}: EmployeeCardScanWrapperProps) => {
  const navigate = useNavigate();

  return (
    <div className="size-full bg-slate-100 p-5">
      <div className="flex justify-end">
        <UnattendedLogoutButton />
      </div>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full max-w-screen-sm flex-col items-center">
          <div className={clsx(className, "flex flex-col items-center")}>
            <h2 className="text-lg uppercase text-text-primary">
              <T _str="Lunch registration" />
            </h2>
            <h1 className="mb-4 text-5xl font-medium text-text-primary">
              <T _str="Scan your card" />
            </h1>
          </div>

          <div className="relative flex h-[354px] w-full items-center justify-center rounded-md bg-background-primary">
            {children}
          </div>

          {guestSelector ? (
            <Button
              className={clsx("mt-4 w-full", className)}
              onClick={() => {
                employeeActions.setGroupName(guestSelector);
                navigate("/pos");
              }}
              size="xl"
              variant="info-inverted"
            >
              <T _str="Continue as" />
              <span className="ml-1">
                <b>
                  <T _str="Guest" />
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                </b>
              </span>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
