import { Link } from "react-router-dom";
import type { ReactNode } from "react";
import { T } from "@repo/transifex";

import type { Printer, Terminal } from "~/types/hardware";
import { Button } from "~/components";

type ConnectedDeviceProps = {
  device: Printer | Terminal;
  identifier: string;
  connectRoute?: never;
  onConnect?: never;
  onDisconnect: () => void;
};

type DisconnectedDeviceProps = {
  device?: never;
  identifier?: never;
  connectRoute: string;
  onConnect?: () => void;
  onDisconnect?: never;
};

type DeviceProps = ConnectedDeviceProps | DisconnectedDeviceProps;

export const Device = ({
  device,
  identifier,
  connectRoute,
  onConnect,
  onDisconnect,
}: DeviceProps) => {
  return (
    <DeviceWrapper>
      {device ? (
        <>
          <p>{identifier}</p>
          <Button onClick={onDisconnect} variant="danger">
            <T _str="Disconnect" />
          </Button>
        </>
      ) : (
        <>
          <p className="text-gray-600">
            <T _str="No device connected" />
          </p>
          <Link to={connectRoute}>
            <Button onClick={onConnect}>
              <T _str="Select device" />
            </Button>
          </Link>
        </>
      )}
    </DeviceWrapper>
  );
};

export const DeviceWrapper = ({ children }: { children: ReactNode }) => (
  <div className="flex h-[72px] items-center justify-between rounded-md bg-background-secondary px-5">
    {children}
  </div>
);
