import { useParams } from "react-router-dom";

import { ReportList } from "./ReportList";
import { ReportCombinedList } from "./ReportCombinedList";

const SessionReportsPage = () => {
  const { tillId } = useParams();

  if (tillId === "combined") return <ReportCombinedList />;

  return <ReportList />;
};

export { SessionReportsPage };
