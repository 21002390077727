import { employeeStore } from "./store";
import type { EmployeeState, EmployeeStore } from "./store.types";

/** Selector to get the whole state - the whole employee */
const storeSelector = (state: EmployeeStore): EmployeeState => state;

function getEmployee(): EmployeeStore {
  return storeSelector(employeeStore.getState());
}

export { getEmployee };
