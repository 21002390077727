import { T, useT } from "@repo/transifex";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { BaseInput, Button, Dialog } from "~/components";
import { basketActions, useShopperName } from "~/providers/store/basket";

export const ShopperNamePage = () => {
  const t = useT();
  const navigate = useNavigate();

  const shopperName = useShopperName();
  const [name, setName] = useState(shopperName || "");

  return (
    <Dialog size="lg">
      <div className="flex w-full flex-col items-center gap-8 px-10 py-5">
        <div>
          <Link to="..">
            <Button
              className="absolute left-10 top-3 font-medium text-danger-dark"
              variant="transparent"
            >
              <T _str="Cancel" />
            </Button>
          </Link>

          <h1 className="text-2xl font-bold">
            <T _str="Input name" />
          </h1>
        </div>

        <BaseInput
          className="w-full"
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder={t("Input name")}
          value={name}
        />

        <Button
          className="mt-2 w-full"
          disabled={!name}
          onClick={() => {
            basketActions.setShopperName(name);
            navigate("..");
          }}
        >
          <T _str="Confirm" />
        </Button>
      </div>
    </Dialog>
  );
};
