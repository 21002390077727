/* eslint-disable @typescript-eslint/prefer-promise-reject-errors -- isApiError fails otherwise */
import { useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";
import ShortUniqueId from "short-unique-id";

import { TimeoutError, timeout } from "~/utils/timeout";

import type { apiContract } from "./api.client";
import { client, getAuthHeader } from "./api.client";
import { ordersKeys } from "./orders";

type CreateRequest = ClientInferRequest<
  typeof apiContract.v1.pos.payments.create
>;

function usePaymentCreate() {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  return useMutation({
    mutationFn: async (body: Omit<CreateRequest["body"], "serviceId">) => {
      const uid = new ShortUniqueId({ length: 10 });
      const serviceId = uid.rnd();

      setSearchParams({ serviceId, orderId: body.orderId });

      try {
        return await timeout(
          new Promise((resolve, reject) => {
            void client.v1.pos.payments
              .create({
                body: { ...body, serviceId },
                headers: getAuthHeader(),
              })
              .then((res) => {
                if (res.status === 201) {
                  resolve(res.body);
                } else {
                  reject(res);
                }
              });
          }),
          { ms: 300000 } // 5 minutes
        );
      } catch (error) {
        if (error instanceof TimeoutError) {
          // automatically poll for the transaction request after 5 minutes
          setSearchParams({ serviceId, orderId: body.orderId, poll: "true" });
        }
        throw error;
      }
    },
    onSuccess: (_, { orderId }) => {
      setTimeout(async () => {
        await queryClient.invalidateQueries({
          queryKey: ordersKeys.detail(orderId).queryKey,
        });
      }, 150);
    },
    onError:async  (_, {orderId}) => {
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(orderId).queryKey,
      })},
  });
}

export { usePaymentCreate };
