import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";
import { Navigate } from "react-router-dom";

import { Dialog } from "~/components";
import { useAuth } from "~/providers/store/auth";

const OfflinePage = () => {
  const { isOnline } = useAuth();

  if (isOnline) {
    return <Navigate replace to="/pos" />;
  }

  return (
    <Dialog backdropVariant="error" size="md">
      <div className="m-7 flex flex-col items-center text-center">
        <FontAwesomeIcon
          className="text-danger-main"
          icon={faWarning}
          size="2xl"
        />
        <h2 className="mt-3 text-3xl font-bold">
          <T _str="Terminal is offline" />
        </h2>
        <p className="my-6">
          <T _str="The terminal has lost its connection to the Wi-fi." />
        </p>
        <p className="font-semibold">
          <T _str="Please contact the kitchen staff" />
        </p>
        <div className="mt-7 flex flex-col items-center rounded-md bg-background-secondary p-7">
          <p className="pb-4 text-2xl font-bold">
            <T _str="But don't worry!" />
          </p>
          <p>
            <T _str="You can always order your lunch via the app" />
          </p>
        </div>
      </div>
    </Dialog>
  );
};

export { OfflinePage };
