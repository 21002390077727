export const getSupportInfoFromCountryCode = (countryCode: string | null) => {
  switch (countryCode) {
    case "GB":
      return {
        email: "support@kanpla.io",
        phone: "+443308081620",
      };
    default:
      return {
        email: "support@kanpla.dk",
        phone: "+4539393009",
      };
  }
};
