import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { getRefundInfo } from "./getters";
import { refundStore } from "./store";
import type { RefundState } from "./store.types";

function useRefundInfo(): RefundState {
  return useStore(refundStore, useShallow(getRefundInfo));
}

export { useRefundInfo };
