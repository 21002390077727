import { useState } from "react";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";

import { Button, Dialog, DialogSuccess, type DialogProps } from "~/components";
import { useSendEmailReportCombined } from "~/hooks/queries/reportsCombined";

type DialogEmailReceiptCombinedProps = Partial<DialogProps> & {
  dateSeconds?: string;
};

export const DialogSendReportCombinedEmail = ({
  dateSeconds,
  ...dialogProps
}: DialogEmailReceiptCombinedProps) => {
  const {
    mutate: sendReport,
    status,
    reset,
  } = useSendEmailReportCombined(Number(dateSeconds || 0));

  const [email, setEmail] = useState("");

  if (status === "success")
    return (
      <DialogSuccess
        {...dialogProps}
        onOutsideClick={() => {
          dialogProps.onOutsideClick?.();
          setEmail("");
          reset();
        }}
        title="Email sent!"
      />
    );

  return (
    <Dialog size="md" {...dialogProps}>
      <div className="flex flex-col items-center justify-center px-8 py-5 text-center">
        <div className="mb-8 flex flex-col gap-3 text-text-secondary">
          <h2 className="text-xl">
            <T _str="Send report" />
          </h2>
        </div>
        <div className="relative flex w-full flex-col rounded-md border border-gray-300 px-3 py-2 text-left">
          <label className="text-xs text-text-disabled">
            <T _str="Email" />
          </label>
          <input
            className="border-none text-sm text-text-secondary outline-none"
            name="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            type="text"
            value={email}
          />
          <FontAwesomeIcon
            className="absolute right-3 top-1/3"
            icon={faEnvelope}
          />
        </div>

        {status === "error" && (
          <div>
            <T _str="Error sending the email, try again" />
          </div>
        )}

        <div className="mt-10 flex w-full gap-3">
          <Button
            onClick={() => dialogProps.onOutsideClick?.()}
            size="large"
            variant="danger"
          >
            <T _str="Cancel" />
          </Button>
          <Button
            className="w-full"
            disabled={!email || status === "pending"}
            loading={status === "pending"}
            onClick={() => {
              sendReport({
                email,
              });
            }}
            size="large"
            variant="primary"
          >
            <T _str="Send" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
