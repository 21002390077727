import { faBan, faReceipt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { priceFormatter } from "@repo/system";
import { T, useT } from "@repo/transifex";
import type { Order, OrderPersonal } from "@repo/types";
import type { QueryStatus } from "@tanstack/react-query";
import {
  type CellContext,
  type Row,
  createColumnHelper,
} from "@tanstack/react-table";
import { atom, useAtom, useSetAtom } from "jotai";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import { useCurrentTill, useSession } from "~/hooks/queries";
import { useAppContext, useAppDispatch } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

import { Button } from "../Button";
import { Popover } from "../Popover";
import { Table, TableSkeleton } from "../Table/Table";

import { DialogEmailReceipt } from "./DialogEmailReceipt";
import { DialogPrintReceipt } from "./DialogPrintReceipt";
import { DialogRefund } from "./DialogRefund";

type TableTransaction = Pick<
  OrderPersonal,
  | "createdAtSeconds"
  | "profileName"
  | "paymentProvider"
  | "currency"
  | "orderLines"
  | "id"
  | "relatedOrders"
  | "paidWithSaldoUnit"
  | "discounts"
  | "payments"
  | "payouts"
  | "isRefunded"
  | "isRefund"
> & { totalPriceUnit: number };

const columnHelper = createColumnHelper<TableTransaction>();

type MoreButtonsProps = {
  row: Row<TableTransaction>;
};
const MoreButtons = ({ row }: MoreButtonsProps) => {
  const {
    hardware: { printer },
  } = useAppContext();

  const { data: till } = useCurrentTill();
  const { data: session } = useSession(till?.sessionId);

  const setOpenDialog = useSetAtom(openDialogAtom);

  /** Set default values for undefined properties */
  const {
    isRefunded = false,
    isRefund = false,
    paidWithSaldoUnit = 0,
  } = row.original;

  const isPaidWithSaldo = paidWithSaldoUnit > 0;
  const isRefundDisabled = isPaidWithSaldo || !session?.isValid || isRefunded;

  return (
    <div className="flex justify-end gap-2">
      <Popover
        className="w-full"
        content={
          <>
            <Popover.Item
              onClick={() => {
                setOpenDialog({
                  type: "receipt-email",
                  orderId: row.original.id,
                });
              }}
            >
              <span>
                <T _str="Email receipt" />
              </span>
            </Popover.Item>

            <Popover.Item
              disabled={!printer}
              onClick={() => {
                setOpenDialog({
                  type: "receipt-print",
                  orderId: row.original.id,
                });
              }}
            >
              <T _str="Print receipt" />
            </Popover.Item>
          </>
        }
        // fix a layering issue with the sticky table header
        position={row.index === 0 || row.index === 1 ? "bottom" : "top"}
      >
        <Button className="w-full" variant="secondary">
          <FontAwesomeIcon className="mr-2" icon={faReceipt} />
          <T _str="Receipt" />
        </Button>
      </Popover>

      <Button
        disabled={isRefundDisabled}
        hidden={isRefund}
        onClick={() => {
          setOpenDialog({
            type: "refund",
            orderId: row.original.id,
          });
        }}
        variant="danger-light"
      >
        <FontAwesomeIcon className="mr-2" icon={faBan} />
        <T _str="Refund" />
      </Button>
    </div>
  );
};

type TableTransactionsProps = {
  status: QueryStatus;
  data: TableTransaction[] | undefined;
};

type TableDialog = {
  type: "receipt-email" | "refund" | "receipt-print";
  orderId: Order["id"];
} | null;
export const openDialogAtom = atom<TableDialog>(null);

export const TableTransactions = ({ data, status }: TableTransactionsProps) => {
  const t = useT();
  const { locale } = useAuth();
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useAtom(openDialogAtom);

  const { data: till, status: tillStatus } = useCurrentTill();

  if (status === "pending" || tillStatus === "pending")
    return <TableSkeleton />;

  if (status === "error" || !data) {
    return (
      <div className="flex size-full flex-col items-center justify-center gap-2">
        <h2 className="text-2xl text-danger-dark">
          <T _str="Could not load transactions" />
        </h2>

        <Link to="..">
          <Button>
            <T _str="Back" />
          </Button>
        </Link>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="flex size-full flex-col items-center justify-center gap-2">
        <h2 className="text-2xl text-text-primary">
          <T _str="No transactions found" />
        </h2>
      </div>
    );
  }

  const columns = [
    columnHelper.accessor("createdAtSeconds", {
      id: "date",
      cell: (info) => {
        const value = info.getValue();
        if (!value) return null;

        return (
          <div className="whitespace-nowrap">
            {moment.unix(value).format("DD-MM-YYYY")}
          </div>
        );
      },
      header: () => <T _str="Date" />,
    }),
    columnHelper.accessor("createdAtSeconds", {
      id: "time",
      cell: (info) => {
        const value = info.getValue();
        if (!value) return null;

        const baseMoment = moment.unix(value);

        return till?.timezone
          ? baseMoment.tz(till.timezone).format("HH:mm")
          : baseMoment.format("HH:mm");
      },
      header: () => <T _str="Time" />,
    }),
    columnHelper.accessor("orderLines", {
      cell: (info) => {
        const value = info.getValue();
        if (!value) return null;

        return value.flatMap((orderLine, index) => {
          const line = `${orderLine.amount} x ${orderLine.name}`;
          if (index === 0) {
            return [line];
          }

          return [" / ", line];
        });
      },
      header: () => <T _str="Products" />,
    }),
    columnHelper.accessor("profileName", {
      header: () => <T _str="User" />,
    }),
    {
      id: "type",
      header: () => <T _str="Type" />,
      cell: ({ row }: CellContext<TableTransaction, number>) => {
        const payments = row.original.payments ?? [];
        const payouts = row.original.payouts ?? [];
        const hasCreditPayment = payments.some(
          (payment) => payment.paymentProvider === "credit"
        );

        const isHybridPayment =
          typeof row.original.paidWithSaldoUnit === "number" &&
          row.original.paidWithSaldoUnit > 0 &&
          !hasCreditPayment;

        const paymentProvider = (() =>
          (payments.length > 0 ? payments : payouts)
            .map((payment) => {
              switch (payment.paymentProvider) {
                case "mobilepay_own":
                  return "MobilePay";
                case "card":
                  return t("Card") + (isHybridPayment ? ` ${t("Credit")}` : "");
                case "billing":
                  return t("Billing");
                case "cash":
                  return t("Cash");
                case "credit":
                  return t("Credit");
                case "swish":
                  return "Swish";
                default:
                  return "";
              }
            })
            .join(", "))();

        return <p>{paymentProvider}</p>;
      },
    },
    columnHelper.accessor("totalPriceUnit", {
      header: () => <T _str="Price" />,
      cell: (info) => {
        const value = info.getValue();
        if (typeof value !== "number") return null;

        return priceFormatter({
          value,
          currency: info.row.original.currency,
          locale,
        });
      },
    }),
    {
      id: "actions",
      header: () => <T _str="Actions" />,
      cell: ({ row }: CellContext<TableTransaction, number>) => {
        return <MoreButtons row={row} />;
      },
    },
  ];

  return (
    <>
      <Table columns={columns} data={data} />

      {openDialog?.type === "receipt-email" && (
        <DialogEmailReceipt
          onOutsideClick={() => {
            setOpenDialog(null);
          }}
          orderId={openDialog.orderId}
        />
      )}

      {openDialog?.type === "refund" && (
        <DialogRefund
          onOutsideClick={() => {
            setOpenDialog(null);
          }}
          orderId={openDialog.orderId}
        />
      )}

      {openDialog?.type === "receipt-print" && (
        <DialogPrintReceipt
          onOutsideClick={() => {
            dispatch({ type: "PRINTER_PRINT_RECEIPT_RESET" });
            setOpenDialog(null);
          }}
          orderId={openDialog.orderId}
        />
      )}
    </>
  );
};
