import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";

import { getAuth } from "~/providers/store/auth";

import type { apiContract } from "./api.client";
import { client, getAuthHeader } from "./api.client";
import { ordersKeys } from "./orders";
import { useCurrentTill } from "./tills";

type AbortRequest = ClientInferRequest<
  typeof apiContract.v1.pos.terminals.abort
>;

export const terminalsKeys = createQueryKeys("terminals", {
  transactionStatus: ({
    serviceId,
    terminalId,
  }: {
    serviceId: string | null;
    terminalId?: string;
  }) => ({
    queryKey: ["transactions", { serviceId, terminalId }],
  }),
  all: null,
});

// POST
export function useAbortTransaction() {
  const queryClient = useQueryClient();

  const { data: till } = useCurrentTill();

  return useMutation({
    mutationFn: async ({
      orderId,
      serviceId,
    }: Pick<AbortRequest["body"], "orderId" | "serviceId">) => {
      const { tillId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const terminalId = till?.terminalId;
      if (!terminalId) throw new Error("Terminal not found");

      const res = await client.v1.pos.terminals.abort({
        body: {
          tillId,
          orderId,
          serviceId,
        },
        params: { terminalId },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
    onSuccess: async (_, { orderId }) => {
      if (!orderId) return;

      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(orderId).queryKey,
      });
    },
  });
}

// GET
export function useTransactionStatus(
  {
    serviceId,
    orderId,
    terminalId,
  }: {
    serviceId: string | null;
    orderId: string | null;
    terminalId?: string;
  },
  shouldPoll = false
) {
  const queryClient = useQueryClient();

  return useQuery({
    ...terminalsKeys.transactionStatus({ serviceId, terminalId }),
    queryFn: async () => {
      const { tillId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!terminalId) throw new Error("Terminal not found");

      const res = await client.v1.pos.terminals.status({
        query: { tillId, serviceId },
        params: { terminalId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(serviceId) && shouldPoll,
    refetchInterval: ({ state: { data } }) => {
      if (!shouldPoll || !data) {
        return false;
      }

      // If the transaction is not found, stop polling
      if (data.status === "failed") {
        return false;
      }

      if (data.transactionStatus === "approved") {
        void queryClient.invalidateQueries({
          queryKey: ordersKeys.detail(orderId || "empty").queryKey,
        });
        return false;
      }

      if (data.transactionStatus === "cancelled") {
        void queryClient.invalidateQueries({
          queryKey: ordersKeys.detail(orderId || "empty").queryKey,
        });
        return false;
      }

      // Poll every second
      return 1000;
    },
  });
}

// GET
export function useTerminals() {
  return useQuery({
    ...terminalsKeys.all,
    queryFn: async () => {
      const { schoolId, partnerId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.terminals.get({
        query: { partnerId, schoolId },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
  });
}
