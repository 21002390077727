import type { Currency } from "@repo/types";

type PriceFormatterArgs = {
  value: number | string;
  currency: Currency;
  locale?: string | null;
  options?: {
    showDecimals?: boolean;
    hideCurrency?: boolean;
  };
};

export const priceFormatter = ({
  value,
  currency,
  locale,
  options,
}: PriceFormatterArgs) => {
  /** Sometimes happens that the value is directly a string */
  if (typeof value === "string") return value;

  /** Convert from unit value to display value (e.g. "cents" to "euro") */
  const priceCurrency = value / 100;

  let decimals;
  if (options?.showDecimals) {
    decimals = 2;
  } else if (priceCurrency % 1 === 0) {
    decimals = 0;
  } else {
    decimals = 2;
  }

  /** Format the price */
  const price = new Intl.NumberFormat(locale || "da-DK", {
    style: options?.hideCurrency ? "decimal" : "currency",
    currency,
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(priceCurrency);

  return price;
};
