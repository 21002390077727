import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { getAuth } from "~/providers/store/auth";

import type { apiContract } from "./api.client";
import { client, getAuthHeader } from "./api.client";
import { tillsKeys } from "./tills";

const sessionsKeys = createQueryKeys("sessions", {
  list: ({
    tillId,
    end,
    start,
  }: {
    tillId: string;
    end?: number;
    start?: number;
  }) => ({
    queryKey: !end || !start ? [tillId] : [tillId, { start, end }],
  }),
  detail: (
    sessionId: string,
    options?: { expand?: "profiles" | undefined }
  ) => ({
    queryKey: options ? [sessionId, options] : [sessionId],
  }),
});

type GetSessionsRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.getSessions
>;

function useSessions({
  end,
  start,
  tillId,
}: Pick<GetSessionsRequest["query"], "end" | "start"> & {
  tillId: string | null;
}) {
  return useQuery({
    ...sessionsKeys.list({ end, start, tillId: tillId ?? "empty" }),
    queryFn: async () => {
      if (!tillId) {
        throw new Error("No Till ID provided");
      }

      const res = await client.v1.pos.tills.getSessions({
        params: { tillId },
        query: {
          end,
          start,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(tillId),
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });
}

type CreateSessionRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.createSession
>;

function useOpenTillSession() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      printerId = "",
      tillId,
      ...body
    }: Omit<CreateSessionRequest["body"], "printerId" | "profileId"> &
      Pick<CreateSessionRequest["params"], "tillId"> & {
        printerId?: string;
      }) => {
      const { profileId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile not selected");
      }

      const res = await client.v1.pos.tills.createSession({
        params: { tillId },
        body: { printerId, profileId, ...body },
        headers: getAuthHeader(),
      });

      if (res.status === 201) {
        return res.body;
      }

      throw res;
    },
    onSuccess: async (session) => {
      await queryClient.invalidateQueries({ queryKey: tillsKeys._def });
      await queryClient.invalidateQueries({
        queryKey: sessionsKeys.list({ tillId: session.tillId }).queryKey,
      });

      navigate("/settings/tills");
    },
  });
}

type GetSessionByIdRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.getSessionById
>;

function useSession(
  sessionId?: GetSessionByIdRequest["params"]["sessionId"] | null,
  options?: Pick<GetSessionByIdRequest["query"], "expand">
) {
  return useQuery({
    ...sessionsKeys.detail(sessionId ?? "empty", options),
    queryFn: async () => {
      if (!sessionId) {
        throw new Error("No session ID provided");
      }

      const res = await client.v1.pos.tills.getSessionById({
        // it's because of the /:tillId/sessions/:sessionId route (but we don't check for tillId in the query)
        params: { tillId: "none", sessionId },
        query: {
          expand: options?.expand,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        const isValid = moment(res.body.startedAt * 1000).isSame(
          moment(),
          "day"
        );
        return { ...res.body, isValid };
      }

      throw res;
    },
    enabled: Boolean(sessionId),
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });
}

type CloseSessionRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.closeSession
>;

function useCloseTillSession() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      printerId = "",
      tillId,
      sessionId,
      ...body
    }: Omit<CloseSessionRequest["body"], "profileId" | "printerId"> &
      Pick<CloseSessionRequest["params"], "tillId"> & {
        sessionId: string;
        printerId?: string;
      }) => {
      const { profileId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile not selected");
      }

      const res = await client.v1.pos.tills.closeSession({
        params: { tillId, sessionId },
        body: { printerId, profileId, ...body },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
    onSuccess: async (_, { sessionId, tillId }) => {
      await queryClient.invalidateQueries({ queryKey: tillsKeys._def });
      await queryClient.invalidateQueries({
        queryKey: sessionsKeys.list({ tillId }).queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: sessionsKeys.detail(sessionId).queryKey,
      });

      navigate("/settings/tills");
    },
  });
}

export { useCloseTillSession, useOpenTillSession, useSession, useSessions };
