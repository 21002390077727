import { initClient } from "@ts-rest/core";
import { apiContract } from "@kanpla/api-contract";
import * as Sentry from "@sentry/react";

import { KANPLA_API_URL } from "~/utils/constants";
import { getAuthToken } from "~/providers/store/auth";

const client = initClient(apiContract, {
  baseUrl: KANPLA_API_URL,
  baseHeaders: {},
});

function getAuthHeader() {
  const activeSpan = Sentry.getActiveSpan();

  const rootSpan: Sentry.Span | undefined = activeSpan
    ? Sentry.getRootSpan(activeSpan)
    : undefined;

  const sentryTraceHeader: string | undefined = rootSpan
    ? Sentry.spanToTraceHeader(rootSpan)
    : undefined;

  const sentryBaggageHeader: string | undefined = rootSpan
    ? Sentry.spanToBaggageHeader(rootSpan)
    : undefined;

  const token = getAuthToken();

  if (!token) {
    throw new Error("No auth token");
  }

  const baseHeaders: {
    authorization: string;
    "ngrok-skip-browser-warning": string;
    "sentry-trace"?: string;
    baggage?: string;
  } = {
    authorization: `Bearer ${token}`,
    "ngrok-skip-browser-warning": "true",
  };

  if (sentryTraceHeader) {
    baseHeaders["sentry-trace"] = sentryTraceHeader;
  }

  if (sentryBaggageHeader) {
    baseHeaders.baggage = sentryBaggageHeader;
  }

  return baseHeaders;
}

export { apiContract, client, getAuthHeader };
