import { clsx } from "clsx";

type InitialsCircleProps = {
  name: string;
  className?: string;
};

export const InitialsCircle = ({ name, className }: InitialsCircleProps) => {
  const initials = getInitials(name);

  return (
    <div
      className={clsx(
        "flex size-12 items-center justify-center rounded-full bg-text-secondary p-3",
        className
      )}
    >
      {initials}
    </div>
  );
};

function getInitials(name: string) {
  return name
    .split(" ")
    .map((word) => word[0])
    .filter((ch) => /(?:[A-Za-z])/.test(ch))
    .join("")
    .slice(0, 2);
}
