import { faBurgerGlass, faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";
import type { Till } from "@repo/types";
import { Link } from "react-router-dom";

import { Button } from "~/components";
import { useDiningOption } from "~/providers/store/basket";

export const DiningOptionSwitch = ({
  tillType,
}: {
  tillType: Till["type"];
}) => {
  const diningOption = useDiningOption();

  if (!diningOption) return null;

  if (tillType === "self-service")
    return <SelfServiceDiningOptionSwitch diningOption={diningOption.value} />;

  return <AttendedDiningOptionSwitch diningOption={diningOption.value} />;
};

const SelfServiceDiningOptionSwitch = ({
  diningOption,
}: {
  diningOption: string;
}) => {
  return (
    <div className="m-4 flex items-center justify-between rounded-md bg-background-secondary p-3 pl-4">
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faBurgerGlass} />
        <p className="font-medium text-text-secondary">{diningOption}</p>
      </div>
      <Link to="/dining-options">
        <Button variant="light">
          <T _str="Switch" />
        </Button>
      </Link>
    </div>
  );
};

const AttendedDiningOptionSwitch = ({
  diningOption,
}: {
  diningOption: string;
}) => {
  return (
    <div className="flex size-full items-center justify-between pr-4">
      <div className="flex flex-col">
        <p className="font-medium uppercase text-text-disabled">
          <T _str="Delivery" />
        </p>
        <p className="text-text-secondary">{diningOption}</p>
      </div>
      <Link to="/dining-options">
        <Button>
          <FontAwesomeIcon icon={faRepeat} />
        </Button>
      </Link>
    </div>
  );
};
