type ErrorWithMessage = {
  message: string;
};

type ValidateErrorEntity = {
  values: Record<string, string>;
  errorFields: {
    name: (string | number)[];
    errors: string[];
  }[];
  outOfDate: boolean;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function isAntdError (error: unknown): error is ValidateErrorEntity {
  return (
    typeof error === "object" &&
    error !== null &&
    "errorFields" in error &&
    Array.isArray((error as ValidateErrorEntity).errorFields)
  );
};

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  // ANTD error
  if (isAntdError(maybeError)) {
    return new Error(
      maybeError.errorFields
        .map((field) => field.errors.join(", "))
        .join(", ")
    );
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}
