import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { clsx } from "clsx";

import { Button } from "../Button";

export type TimeProps = {
  label: string;
  onNext: () => void;
  onPrev: () => void;
  className?: string;
};

export const Time = ({ label, onPrev, onNext, className }: TimeProps) => {
  return (
    <div
      className={clsx(
        "flex min-w-[300px] items-center justify-between rounded-lg border border-gray-200",
        className
      )}
    >
      <Button icon={faChevronLeft} onClick={onPrev} variant="transparent" />

      <h3 className="mx-auto text-lg">{label}</h3>

      <Button icon={faChevronRight} onClick={onNext} variant="transparent" />
    </div>
  );
};

export const TimeSkeleton = () => (
  <div className="flex h-[50px] min-w-[300px] animate-pulse items-center justify-between rounded-lg bg-gray-200" />
);
