import type { AllowanceUsage, CombinedOfferItem } from "@repo/types";

type AllowanceForProductArgs = {
  productId: CombinedOfferItem["id"];
  allowanceUsages: AllowanceUsage[] | undefined | null;
}

export const getAllowancesForProduct = ({
  productId,
  allowanceUsages,
}: AllowanceForProductArgs): AllowanceUsage[] => {
  const targetAllowanceProducts = (allowanceUsages || []).filter((usage) => {
    const { numberOfProducts, unitPrice } = usage;

    if (numberOfProducts?.productIds.includes(productId)) return true;
    if (unitPrice?.productIds.includes(productId)) return true;

    return (
      (numberOfProducts?.productIds || []).length === 0 && (unitPrice?.productIds || []).length === 0
    );
  });

  return targetAllowanceProducts;
};
