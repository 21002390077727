import type { Product } from "@repo/types";

type Args = {
  unitPrice: number;
  weight: number;
  unitSystem?: Omit<Product["unitSystem"], "piece">;
};

export const calculateUnitPriceByUnitSystem = ({
  unitPrice,
  weight,
  unitSystem,
}: Args) => {
  switch (unitSystem) {
    case "1-gram":
      return unitPrice * weight;
    case "100-grams":
      return (unitPrice * weight) / 100;
    case "kilogram":
      return (unitPrice * weight) / 1000;
    default:
      return unitPrice;
  }
};
