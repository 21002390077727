import {
  faArrowRightArrowLeft,
  faCashRegister,
  faCircleInfo,
  faCircleQuestion,
  faFileLines,
  faPrint,
  faStore,
  faWifiSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, useT } from "@repo/transifex";
import { Link, useNavigate } from "react-router-dom";

import {
  DialogError,
  DialogLoading,
  InitialsCircle,
  KanplaLogo,
} from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useProfile, useSession, useTill } from "~/hooks/queries";
import { useAppContext } from "~/providers/app";
import { authActions, useAuth, useIsOnline } from "~/providers/store/auth";
import { basketActions } from "~/providers/store/basket";

import { MenuButton, MenuLink } from "./MenuLink";
import { UpdatePWA } from "./UpdatePWA";
import { UpdatesInfo } from "./UpdatesInfo";

const MenuPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const t = useT();

  const {
    hardware: { printer },
  } = useAppContext();

  const isOnline = useIsOnline();

  const { data: profile, status: profileStatus } = useProfile(auth.profileId);

  const { data: till } = useTill(auth.tillId);
  const { data: session } = useSession(till?.sessionId);

  const isTillOpen = Boolean(till?.sessionId);

  const sendWebEvent = useSendWebEvent();
  const links = useMenuLinks({
    showWarning: !(session?.isValid ?? false),
    disabled: !isOnline,
  });

  if (auth.status === "none" || profileStatus === "pending") {
    return <DialogLoading />;
  }

  if (profileStatus === "error") {
    return (
      <DialogError
        error={t("Profile not found")}
        onOutsideClick={() => {
          navigate("/profiles");
        }}
      />
    );
  }

  /** Link that redirects either to closing a session on the current till or to the sales screen  */
  const linkToSalesOrCloseSession =
    session?.isValid ?? false
      ? "/pos"
      : `/settings/tills/${till?.id}/${session?.id}/close`;
  /** Final sales menu item link */
  const salesMenuLink = !isTillOpen
    ? `/settings/tills/${till?.id}/open`
    : linkToSalesOrCloseSession;

  return (
    <div className="flex size-full flex-col bg-text-primary">
      <div className="grid grid-cols-3 px-5 pt-10">
        <div />

        <div className="flex items-center justify-center">
          {!isOnline && (
            <div className="flex flex-col items-center gap-1.5">
              <div className="flex w-max items-center gap-2.5 rounded-lg bg-warning-backdrop/20 px-3 py-2 text-warning-main">
                <FontAwesomeIcon icon={faWifiSlash} />
                <p>
                  <T _str="Device is offline. Please check the Wi-fi connection" />
                </p>
              </div>
              <UpdatesInfo />
            </div>
          )}

          <UpdatePWA />
        </div>

        <div className="flex items-center justify-end">
          {isOnline ? (
            <button
              className="flex h-16 items-center gap-3 rounded-md bg-secondary-backdrop/20 pl-2.5 pr-4 text-secondary-main"
              onClick={() => {
                basketActions.reset();
                basketActions.resetDiningOption();
                authActions.logoutProfile();
              }}
              type="button"
            >
              <InitialsCircle name={profile.name} />
              <T _str="Log out" />
            </button>
          ) : (
            <div />
          )}
        </div>
      </div>

      <div className="flex w-full flex-1 items-center justify-center">
        <div className="flex h-fit w-3/4 gap-16">
          <div className="flex min-h-full flex-1 flex-col gap-4">
            <MenuLink
              icon={faStore}
              isBig
              showOpacity={!isTillOpen || !(session?.isValid ?? false)}
              title="Sales"
              to={salesMenuLink}
            >
              {(!isTillOpen || !(session?.isValid ?? false)) && (
                <div
                  className="pointer-events-none absolute inset-x-0 mx-auto flex w-fit items-center gap-2 rounded-md bg-warning-main/20 px-4 py-2 text-warning-main"
                  style={{ bottom: "25%" }}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                  {!isTillOpen && <T _str="Open the till to continue" />}
                  {!(session?.isValid ?? false) && isTillOpen ? (
                    <T _str="Close till before continuing" />
                  ) : null}
                </div>
              )}
            </MenuLink>
            {printer && printer.hasCashDrawer ? (
              <div className="relative">
                <MenuButton
                  className="!flex-row bg-gray-800"
                  disabled={!isTillOpen || !(session?.isValid ?? false)}
                  icon={faCashRegister}
                  onClick={() => {
                    sendWebEvent({ type: "CASH_DRAWER_OPEN_REQUEST" });
                  }}
                  title={t("Open cash drawer")}
                />
              </div>
            ) : null}
          </div>

          <div className="flex flex-1 flex-col gap-4">
            {links.map(({ to, title, icon, showWarning, disabled }) => (
              <MenuLink
                disabled={disabled}
                icon={icon}
                key={to + title}
                showWarning={showWarning}
                title={title}
                to={to}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="flex h-20 w-full items-center justify-between border-t border-divider-main px-5 text-white">
        <div className="w-24">
          <KanplaLogo className="fill-white" />
        </div>

        <Link to="/support">
          <button className="flex items-center gap-2.5" type="button">
            <FontAwesomeIcon icon={faCircleQuestion} size="xl" />
            <T _str="Need help?" />
          </button>
        </Link>

        <p className="text-xs">
          <T _str="All rights reserved ®" />
        </p>
      </div>
    </div>
  );
};

const useMenuLinks = ({
  showWarning,
  disabled,
}: {
  showWarning: boolean;
  disabled: boolean;
}) => {
  const t = useT();

  return [
    {
      to: "/settings/tills",
      title: t("Register Management"),
      icon: faCashRegister,
      showWarning,
      disabled,
    },
    {
      to: "/settings/transactions",
      title: t("Transactions"),
      icon: faArrowRightArrowLeft,
    },
    {
      to: "/settings/reports",
      title: t("Reports"),
      icon: faFileLines,
      disabled,
    },
    {
      to: "/settings/devices",
      title: t("Devices setup"),
      icon: faPrint,
    },
  ];
};

export { MenuPage };
