import classNames from "classnames";
import React from "react";

type KanplaLogoProps = {
  onlyIcon?: boolean;
  className?: string;
};
export const KanplaLogo = ({
  onlyIcon = false,
  className,
}: KanplaLogoProps) => {
  const width = onlyIcon ? 405 : 2252;

  return (
    <>
      <style>
        {`
  @keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes popReveal {
    0% {
      opacity: 0;
      transform: scale(.85)
    }
    70% {
      opacity: .8;
      transform: scale(1.05)
    }
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }
  
  #logo_kanpla_letter_1 {
    opacity: 0;
    animation: reveal .5s 0s ease forwards;
  }
  
  #logo_kanpla_letter_2 {
    opacity: 0;
    animation: reveal .55s .05s ease forwards;
  }
  
  #logo_kanpla_letter_3 {
    opacity: 0;
    animation: reveal .6s .1s ease forwards;
  }
  
  #logo_kanpla_letter_4 {
    opacity: 0;
    animation: reveal .65s .15s ease forwards;
  }
  
  #logo_kanpla_letter_5 {
    opacity: 0;
    animation: reveal .7s .2s ease forwards;
  }
  
  #logo_kanpla_letter_6 {
    opacity: 0;
    animation: reveal .75s .25s ease forwards;
  }
  
  
  #logo_kanpla_fork {
    opacity: 0;
    animation: popReveal .4s 0s ease forwards;
    transform-origin: center left;
  }
  
  #logo_kanpla_knife {
    opacity: 0;
    animation: popReveal .5s 0s ease forwards;
    transform-origin: center left;
  }
  
  @media print {
    #logo_kanpla_letter_1,
    #logo_kanpla_letter_2,
    #logo_kanpla_letter_3,
    #logo_kanpla_letter_4,
    #logo_kanpla_letter_5,
    #logo_kanpla_letter_6,
    #logo_kanpla_fork,
    #logo_kanpla_knife {
      opacity: 1;
      animation: none;
    }
  }
  
  
  `}
      </style>
      <svg
        className={classNames(
          "h-auto w-full max-h-full max-w-full object-contain overflow-visible",
          className
        )}
        viewBox={`0 0 ${width} 542`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {!onlyIcon && (
          <>
            <path
              className="primary"
              d="M2085.88 457.978C2056.67 457.978 2032.45 450.345 2013.2 435.079C1993.95 419.813 1984.33 397.047 1984.33 366.781C1984.33 343.948 1989.7 326.027 2000.45 313.018C2011.21 300.008 2025.28 290.517 2042.67 284.543C2076.04 272.915 2111.49 272.939 2145.52 264.233C2156.94 261.312 2162.65 254.94 2162.65 245.117V243.922C2162.65 231.311 2158.66 221.554 2150.7 214.651C2142.87 207.749 2131.71 204.297 2117.25 204.297C2097.72 204.297 2079.62 221.104 2074.35 239.542L1995.9 233.17C2001.74 205.293 2016.21 183.19 2039.31 166.862C2062.54 150.534 2092.47 142.37 2117.64 142.37C2140.34 142.37 2161.58 145.135 2181.36 152.304C2201.14 159.472 2217.07 171.508 2229.15 186.774C2241.36 201.908 2247.47 221.621 2247.47 245.914C2247.47 245.914 2247.47 371.913 2247.47 404.718C2247.47 437.524 2251.85 452.204 2251.85 452.204H2172.5C2172.5 452.204 2167.03 448.967 2167.03 409.791H2164.64C2157.2 423.995 2146.05 435.61 2131.18 444.637C2116.45 453.531 2108.44 457.978 2085.88 457.978ZM2145.32 383.308C2157.27 372.422 2163.24 358.948 2163.24 342.886V310.429C2150.55 319.059 2129.68 321.974 2114.95 324.168C2100.08 326.292 2088.07 330.474 2078.91 336.713C2069.88 342.952 2065.37 352.046 2065.37 363.993C2065.37 375.675 2069.55 384.502 2077.91 390.476C2100.28 406.2 2126.47 400.279 2145.32 383.308Z"
              id="logo_kanpla_letter_6"
            />
            <path
              className="primary"
              d="M1962.22 43.7646V451.566H1877.39L1877.39 50.6938L1962.22 43.7646Z"
              id="logo_kanpla_letter_5"
            />
            <path
              className="primary"
              d="M1557 541.688V145.712H1640.63V197.085H1644.41C1648.26 188.722 1653.71 180.293 1660.74 171.797C1667.78 163.301 1677.07 156.199 1688.62 150.491C1700.17 144.65 1714.44 141.729 1731.43 141.729C1753.86 141.729 1774.44 147.57 1793.16 159.252C1811.88 170.801 1826.88 188.258 1838.16 211.621C1849.58 234.852 1855.28 263.99 1855.28 299.036C1855.28 333.152 1849.78 361.958 1838.76 385.455C1827.74 408.818 1812.87 426.54 1794.15 438.62C1775.44 450.568 1754.46 456.541 1731.23 456.541C1714.77 456.541 1700.77 453.82 1689.22 448.377C1677.8 442.935 1668.44 436.098 1661.14 427.868C1653.97 419.505 1648.4 411.075 1644.41 402.579H1641.83V534.911L1557 541.688ZM1640.03 298.638C1640.03 325.851 1645.61 347.754 1656.76 364.348C1668.04 380.809 1683.91 389.039 1704.35 389.039C1725.19 389.039 1741.12 380.676 1752.14 363.95C1763.16 347.091 1768.67 325.32 1768.67 298.638C1768.67 272.088 1763.16 250.583 1752.14 234.122C1741.25 217.529 1725.32 209.232 1704.35 209.232C1683.77 209.232 1667.91 217.329 1656.76 233.525C1645.61 249.587 1640.03 271.292 1640.03 298.638Z"
              id="logo_kanpla_letter_4"
            />
            <path
              className="primary"
              d="M1328.02 274.743V451.563H1243.2V145.712H1324.04V199.674H1327.62C1334.53 181.886 1345.88 167.814 1361.67 157.46C1377.6 146.973 1396.92 141.729 1419.62 141.729C1451.48 141.729 1477.03 152.017 1496.28 172.593C1515.53 193.037 1525.15 221.113 1525.15 256.822V451.563H1440.33V271.955C1440.46 253.238 1435.68 238.636 1425.99 228.148C1416.3 217.529 1402.96 212.219 1385.97 212.219C1368.84 212.219 1354.9 217.728 1344.15 228.746C1333.53 239.631 1328.15 254.964 1328.02 274.743Z"
              id="logo_kanpla_letter_3"
            />
            <path
              className="primary"
              clipRule="evenodd"
              d="M997.102 450.635C1009.99 455.104 1024.33 457.338 1040.13 457.338C1062.7 457.338 1081.35 452.891 1096.09 443.997C1110.95 434.97 1122.1 423.354 1129.54 409.15H1131.93C1131.93 430.05 1136.28 451.563 1136.28 451.563H1212.37V245.273C1212.37 220.98 1206.27 201.267 1194.05 186.134C1181.97 170.868 1166.04 159.65 1146.26 152.482C1126.49 145.314 1105.25 141.729 1082.55 141.729C1045.91 141.729 1015.97 149.893 992.741 166.221C969.643 182.549 955.174 204.652 949.333 232.529L1027.79 238.901C1030.71 228.679 1036.68 220.25 1045.71 213.613C1054.73 206.975 1066.88 203.656 1082.15 203.656C1096.62 203.656 1107.77 207.108 1115.6 214.011C1123.56 220.914 1127.55 230.671 1127.55 243.282V244.476C1127.55 254.3 1121.84 260.672 1110.42 263.592C1099.01 266.513 1080.02 269.3 1053.47 271.955C1033.3 273.814 1014.45 277.796 996.923 283.903C979.533 289.876 965.462 299.368 954.709 312.377C944.052 325.271 938.676 342.99 938.582 365.535L997.102 450.635ZM1110.22 382.667C1098.28 393.42 1083.01 398.796 1064.43 398.796C1051.42 398.796 1040.66 395.809 1032.17 389.835C1023.8 383.862 1019.62 375.034 1019.62 363.352C1019.62 351.405 1024.14 342.312 1033.16 336.073C1042.32 329.833 1054.34 325.652 1069.2 323.528C1075.44 322.599 1082.48 321.537 1090.31 320.342C1098.28 319.014 1105.71 317.488 1112.61 315.762C1119.65 314.036 1124.83 312.045 1128.14 309.788V342.245C1128.14 358.308 1122.17 371.782 1110.22 382.667Z"
              fillRule="evenodd"
              id="logo_kanpla_letter_2"
            />
            <path
              className="primary"
              d="M623.849 451.566V43.7646H710.069V223.572H715.445L862.198 43.7646H965.542L814.21 226.36L967.335 451.566H864.189L752.482 283.906L710.069 335.677V451.566H623.849Z"
              id="logo_kanpla_letter_1"
            />
          </>
        )}

        <path
          className="primary"
          d="M78.8806 222.174C78.2181 250.343 50.1077 251.918 50.1077 222.174C50.1077 111.656 51.6624 0 32.9069 0C14.1513 0 0 213.703 0 264.607C0 328.465 63.679 334.486 63.679 373.913C63.679 385.787 67.1631 472.26 67.0403 478.588C66.8233 489.781 72.5357 492.123 79.8239 492.123H114.461C121.749 492.123 127.462 489.781 127.245 478.588C127.122 472.26 130.606 385.787 130.606 373.913C130.606 334.486 194.285 328.465 194.285 264.607C194.285 213.703 180.134 0 161.378 0C142.623 0 144.177 111.656 144.177 222.174C144.177 251.918 116.067 250.343 115.404 222.174C114.461 182.066 120.146 0 97.1402 0C74.1346 0 79.8239 182.066 78.8806 222.174Z"
          id="logo_kanpla_fork"
        />
        <path
          className="primary"
          d="M399.11 0C254.377 0 236.236 210.763 236.239 326.135C273.219 349.801 331.467 359.627 364.583 332.636C353.167 364.904 312.139 366.978 312.139 366.978C335.323 420.469 331.609 470.973 331.467 477.679C331.22 489.387 337.639 491.955 345.893 492.02H384.359C392.769 492.02 399.36 489.539 399.11 477.678V0Z"
          id="logo_kanpla_knife"
        />
      </svg>
    </>
  );
};
