import { useT } from "@repo/transifex";
import type { Plugins } from "@repo/types";
import { Controller, useFormContext } from "react-hook-form";

import { Field, SelectCreatable } from "~/components";

import type { InvoiceStepTextInputsType } from "../route";

type StepTextInputsProps = {
  textInputFields: Plugins.TextInputField[];
};

export const StepTextInputs = ({ textInputFields }: StepTextInputsProps) => {
  const t = useT();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<InvoiceStepTextInputsType>();

  return (
    <div className="flex w-full flex-col gap-5 px-1 pb-2">
      {textInputFields.map((field) => {
        const {
          title,
          subtitle,
          required,
          key,
          isTextArea,
          type,
          isMultiSelect,
        } = field;

        const options =
          field.options?.map((option) => ({
            value: option,
            label: option,
          })) || [];

        return (
          <div className="flex w-full flex-col gap-2" key={key}>
            <span className="flex gap-1 text-lg">
              {required ? <h5 className="text-danger-main">*</h5> : null}
              <h4 className="font-semibold">{title}</h4>
            </span>

            {type === "text" ? (
              <Field
                error={errors.textInputs?.[key]?.message}
                label={subtitle}
                placeholder={t("Type here...")}
                type={isTextArea ? "textarea" : "text"}
                {...register(`textInputs.${key}`, {
                  required: required
                    ? t('"{field}" field is required', { field: title })
                    : false,
                })}
              />
            ) : (
              <Controller
                control={control}
                name={`textInputs.${key}`}
                render={({ field: { ref: _, ...field } }) => (
                  <SelectCreatable
                    {...field}
                    className="w-full"
                    isMulti={isMultiSelect}
                    menuPortalTarget={document.body}
                    options={options}
                    placeholder={t(
                      isMultiSelect ? t("Select choices") : t("Select a choice")
                    )}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                )}
                rules={{
                  required: required
                    ? t('"{field}" field is required', { field: title })
                    : false,
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
