import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { round } from "@repo/system";
import { useT } from "@repo/transifex";
import type { PriceExcludingVat, VatRate } from "@repo/types";
import { clsx } from "clsx";
import type { SetStateAction } from "jotai";
import type { ChangeEvent, Dispatch } from "react";
import { useEffect, useState } from "react";

import { BaseInput } from "./BaseInput";

type InputProps = {
  className?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  value: number | string;
  setShowingVat: Dispatch<SetStateAction<boolean>>;
  label: string;
};

const Input = ({
  className,
  onChange,
  placeholder,
  value,
  setShowingVat,
  label,
}: InputProps) => {
  return (
    <div className="flex h-12 items-center">
      <BaseInput
        className={clsx(
          className,
          "h-full w-16 rounded-r-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none",
        )}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />

      <div
        className="flex h-full items-center gap-1 whitespace-nowrap rounded-r-md border border-l-0 border-divider-main bg-background-secondary p-2 text-center"
        onClick={() => {
          setShowingVat((prevValue) => !prevValue);
        }}
      >
        {label}
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
    </div>
  );
};

type PriceInputProps = {
  value?: PriceExcludingVat;
  vatRate: VatRate;
  onChange: (value: number) => void;
  className?: string;
  /** Should show prices inc. VAT by default? */
  defaultShowingVat?: boolean;
};

export const PriceInput = ({
  value,
  vatRate,
  onChange,
  className,
  defaultShowingVat = false,
}: PriceInputProps) => {
  /** Convert from øre to kroner (or from cents fo EUR) */
  const t = useT();
  const [displayValue, setDisplayValue] = useState<string>(
    value ? (value / 100).toString() : "",
  );
  const [price, setPrice] = useState(value ?? 0);
  const [showingVat, setShowingVat] = useState<boolean>(defaultShowingVat);
  useEffect(() => {
    if (!onChange) return;
    onChange((showingVat ? price / (1 + vatRate) : price) * 100);
  }, [displayValue, vatRate, showingVat, price, onChange]);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    // Replace comma with dot
    const convertedString = event.target.value.replace(",", ".");

    // Check if there are more than two decimal places
    const decimalParts = convertedString.split(".");
    const decimalPlaces = decimalParts[1] ? decimalParts[1].length : 0;
    const truncatedString =
      decimalPlaces > 2
        ? `${decimalParts[0]}.${decimalParts[1].substring(0, 2)}`
        : convertedString;

    // Convert back to number
    const convertedNumber = Number(
      truncatedString.replace(/[.,]\d{3}/g, "").replace(/,/, "."),
    );

    setDisplayValue(truncatedString);
    setPrice(round(convertedNumber, 6));
  };

  const label = showingVat
    ? t("{vatRate}% VAT", { vatRate: vatRate * 100 })
    : t("Excl. VAT");

  const placeholder = showingVat
    ? t("Enter price incl. VAT")
    : t("Enter price excl. VAT");

  return (
    <Input
      className={className}
      label={label}
      onChange={(e) => {
        handleInput(e);
      }}
      placeholder={placeholder}
      setShowingVat={(newShowingVat) => {
        setShowingVat(newShowingVat);
        setDisplayValue(price.toString());
      }}
      value={displayValue}
    />
  );
};
