import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";

import { Dialog, type DialogProps } from "./Dialog";

type DialogLoadingProps = Partial<DialogProps> & {
  title?: React.ReactNode;
};

export const DialogLoading = ({
  children,
  title,
  ...props
}: DialogLoadingProps) => {
  return (
    <Dialog size="sm" {...props}>
      <div className="flex flex-col items-center gap-y-4 p-8">
        <FontAwesomeIcon
          className="text-5xl text-text-secondary"
          icon={faSpinner}
          spinPulse
        />

        {title ? (
          <p className="text-center text-xl">{title}</p>
        ) : (
          <div className="flex flex-col items-center gap-y-1">
            <p className="text-xl">
              <T _str="Loading..." />
            </p>
            <p className="text-center text-sm">
              <T _str="This may take a short moment" />
            </p>
          </div>
        )}

        {children}
      </div>
    </Dialog>
  );
};
