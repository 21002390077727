import type { QueryStatus } from "@tanstack/react-query";
import { clsx } from "clsx";
import { v4 as uuidv4 } from "uuid";

type PinInputProps = {
  pin: string;
  status: QueryStatus | "idle";
};

export const PinInput = ({ pin, status }: PinInputProps) => (
  <div className="my-5 grid grid-cols-4 place-items-center gap-x-3">
    {Array(4)
      .fill("*")
      .map((value, index) => (
        <div
          className={clsx(
            "flex h-20 w-16 items-center justify-center rounded-lg bg-secondary-dark p-3 text-5xl font-medium",
            status === "error" && "border border-danger-main",
            status === "pending" && "opacity-20"
          )}
          key={uuidv4()}
        >
          {pin.length > index ? value : " "}
        </div>
      ))}
  </div>
);
