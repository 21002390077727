import { clsx } from "clsx";

import { HeaderSettings, type HeaderSettingsProps } from "./HeaderSettings";

type SettingsLayoutProps = {
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  className?: string;
} & HeaderSettingsProps;

export const SettingsLayout = ({
  headerChildren,
  children,
  className,
  ...rest
}: SettingsLayoutProps) => {
  return (
    <div className="flex size-full flex-col">
      <HeaderSettings {...rest}>{headerChildren}</HeaderSettings>

      <div
        className={clsx(
          "flex h-[calc(100%-theme(spacing.24))] flex-col overflow-y-auto py-5 scrollbar-hide",
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};
