import { refundStore } from "./store";
import type { RefundState, RefundStore } from "./store.types";

// Selectors
const storeSelector = (state: RefundStore): RefundState => state;

function getRefundInfo(): RefundState {
  return storeSelector(refundStore.getState());
}

export { getRefundInfo };
