import { useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";
import { useAppContext } from "~/providers/app";

import { client, getAuthHeader } from "./api.client";

function useCreateCashDrawerOpenPosLog() {
  const {
    hardware: { printer },
  } = useAppContext();

  return useMutation({
    mutationFn: async () => {
      const { profileId, userId, tillId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.logs.create({
        body: {
          type: "cash-drawer-opened",
          payload: {
            printerId: printer?.identifier ?? "unknown",
            profileId,
            userId,
            tillId,
          },
        },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
  });
}

export { useCreateCashDrawerOpenPosLog };
