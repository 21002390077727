import { useParams } from "react-router-dom";
import { getErrorMessage } from "@repo/system";

import { useProfile } from "~/hooks/queries";
import { DialogError, DialogLoading, DialogPin } from "~/components";
import { isApiError } from "~/utils/api";


const ProfilePinPage = () => {
  const params = useParams();

  const { data: profile, status, error } = useProfile(params.profileId);

  if (status === "pending") return <DialogLoading />;

  if (status === "error") {
    const errorMessage = isApiError(error) ? error.body.message : getErrorMessage(error);
    return <DialogError backUrl=".." error={errorMessage} />;
  }

  return (
    <DialogPin backUrl=".." profile={{ id: profile.id, name: profile.name }} />
  );
};

export { ProfilePinPage };
