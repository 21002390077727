import { Link, useLocation, useParams } from "react-router-dom";
import { T } from "@repo/transifex";

import { useTill } from "~/hooks/queries";
import { Button } from "~/components";

export const HeaderButton = () => {
  const { tillId } = useParams();
  const { pathname } = useLocation();

  const { data: till, status: tillStatus } = useTill(tillId);

  if (tillStatus !== "success") return null;

  const isTillOpen = Boolean(till.sessionId);

  return (
    <Link to={`${pathname}/${isTillOpen ? `${till.sessionId}/close` : "open"}`}>
      <Button className="ml-auto" variant={isTillOpen ? "danger" : "primary"}>
        {isTillOpen ? <T _str="Close register" /> : <T _str="Open register" />}
      </Button>
    </Link>
  );
};
