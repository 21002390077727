import { T } from "@repo/transifex";

import { basketActions } from "~/providers/store/basket";
import { useOrderCancel } from "~/hooks/queries";
import { useAppDispatch, useSplitPaymentFlow } from "~/providers/app";

import { Button } from "./Button";
import { Dialog } from "./Dialog";

type DialogCancelSplitPaymentProps = {
  onClose: () => void;
};

export const DialogCancelSplitPayment = ({
  onClose,
}: DialogCancelSplitPaymentProps) => {
  const dispatch = useAppDispatch();

  const { mutate: cancelOrder, status: cancelStatus } = useOrderCancel();
  const { orderId, isActive, reset } = useSplitPaymentFlow();

  return (
    <Dialog onOutsideClick={onClose} size="md">
      <div className="flex flex-col items-center gap-5 p-5 text-center">
        <p className="text-5xl font-semibold text-text-primary">
          <T _str="Cancel payment" />
        </p>
        <p className="text-2xl text-text-primary">
          <T _str="You are about to cancel the payment. Do you wish to continue?" />
        </p>
        <div className="flex w-full gap-2.5">
          <Button
            disabled={cancelStatus === "pending"}
            onClick={onClose}
            variant="danger"
          >
            <T _str="Cancel" />
          </Button>
          <Button
            className="flex-1"
            loading={cancelStatus === "pending"}
            onClick={() => {
              dispatch({ type: "COUNTDOWN_RESET" });
              if (isActive && orderId) {
                cancelOrder(orderId);
                reset();
                dispatch({
                  type: "CASH_DRAWER_OPEN_REQUEST",
                });
              }
              basketActions.reset();
              onClose();
            }}
            variant="primary"
          >
            <T _str="Confirm and open cashdrawer" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
