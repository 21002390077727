import * as Sentry from "@sentry/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";
import { useSearchParams } from "react-router-dom";
import ShortUniqueId from "short-unique-id";

import {
  type apiContract,
  client,
  getAuthHeader,
} from "~/hooks/queries/api.client.ts";
import { ordersKeys } from "~/hooks/queries/orders.ts";
import { useCurrentTill } from "~/hooks/queries/tills.ts";
import { getAuth } from "~/providers/store/auth";
import { basketActions } from "~/providers/store/basket";

type CreateCardRequest = ClientInferRequest<
  typeof apiContract.v3.pos.orders.create
>;

type UseOrderCardCreateProps = {
  /**
   * If the timeout is reached, the transaction will be aborted.
   * @param args args needed to the abort mutation and cancel a transaction
   */
  abortTransactionCallback: (args: {
    orderId: string;
    serviceId: string;
    terminalId: string;
  }) => void;
};

export default function useOrderCardCreateV3({
  abortTransactionCallback,
}: UseOrderCardCreateProps) {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { data: till } = useCurrentTill();

  return useMutation({
    mutationFn: async ({
      employeeUserId,
      employeeChildId,
      paidWithSaldoUnit,
      ...body
    }: Omit<
      CreateCardRequest["body"],
      | "tillId"
      | "moduleId"
      | "schoolId"
      | "userId"
      | "childId"
      | "profileId"
      | "serviceId"
    > & {
      employeeUserId: string | null;
      employeeChildId: string | null;
      paidWithSaldoUnit: number | null;
    }) => {
      basketActions.setCardPaymentInProgress(true);

      const { tillId, moduleId, schoolId, childId, profileId, userId, status } =
        getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!till?.terminalId) {
        throw new Error("Terminal is not connected");
      }

      const uid = new ShortUniqueId({ length: 10 });
      const serviceId = uid.rnd();

      setSearchParams((searchParams) => {
        searchParams.set("serviceId", serviceId);
        return searchParams;
      });

      const res = await client.v3.pos.orders.create({
        body: {
          ...body,
          tillId,
          moduleId,
          schoolId,
          profileId,
          childId: employeeChildId ?? childId,
          userId: employeeUserId ?? userId,
          paidWithSaldoUnit,
          serviceId,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 201) {
        return res.body;
      }

      // Check Adyen transaction status to handle cases where something went wrong on the order endpoint
      const transactionStatus = await client.v1.pos.terminals.status({
        params: {
          terminalId: till.terminalId,
        },
        query: {
          serviceId,
          tillId,
        },
        headers: getAuthHeader(),
      });

      if (transactionStatus.status === 200) {
        if (transactionStatus.body.status === "success") {
          if (transactionStatus.body.transactionStatus === "in_progress") {
            abortTransactionCallback({
              orderId: body.orderId,
              serviceId,
              terminalId: till.terminalId,
            });

            throw new Error("Transaction timed out");
          }

          if (transactionStatus.body.transactionStatus === "approved") {
            const order = await client.v1.pos.orders.getById({
              params: {
                orderId: body.orderId,
              },
              headers: getAuthHeader(),
            });

            if (order.status === 200) {
              if (order.body.status === "paid") {
                return {
                  orderId: body.orderId,
                  id: order.body.payments?.[0].id ?? "unknown",
                };
              }
              // TO-DO: refund or mark order as paid (if we see this happening)
              Sentry.captureMessage(
                `Order ${body.orderId} was paid, but the order is not marked paid`,
                "fatal"
              );
            } else {
              // TO-DO: refund (if we see this happening)
              Sentry.captureMessage(
                `Order ${body.orderId} was paid, but the order could not be fetched`,
                "fatal"
              );
            }
          }
        }
      }

      throw res;
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(res.orderId).queryKey,
      });

      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.append("orderId", res.orderId);
        return newParams;
      });
    },
    onError: async (_, { orderId }) => {
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(orderId).queryKey,
      });
    },
    onSettled: () => {
      basketActions.setCardPaymentInProgress(false);
    },
  });
}
