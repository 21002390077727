import { T } from "@repo/transifex";
import { Link } from "react-router-dom";

export const LicenseGuide = () => {
  return (
    <div className="flex flex-col items-center gap-5">
      <div className="flex flex-col gap-2">
        <h1 className="text-center text-2xl font-bold">
          <T _str="Contact Kanpla Support" />
        </h1>
        <p className="text-center">
          <T _str="How do I create a till?" />
        </p>
      </div>

      <div className="flex flex-col items-start rounded-lg bg-background-secondary p-7">
        <div className="flex items-start">
          <div className="mr-4 flex flex-col items-center">
            <div className="flex size-6 items-center justify-center rounded-full border border-primary-dark bg-white">
              <div className="size-3 rounded-full bg-primary-main" />
            </div>
            <div className="h-12 w-px bg-primary-main" />
          </div>
          <p>
            <T
              _str="Go to {link} and log in to your account"
              link={
                <Link
                  className="font-bold underline"
                  to="https://admin.kanpla.dk"
                >
                  admin.kanpla.dk
                </Link>
              }
            />
          </p>
        </div>

        <div className="flex items-start">
          <div className="mr-4 flex flex-col items-center">
            <div className="flex size-6 items-center justify-center rounded-full border border-primary-dark bg-white">
              <div className="size-3 rounded-full bg-primary-main" />
            </div>
            <div className="h-12 w-px bg-primary-main" />
          </div>
          <p>
            <T
              _str="Go to {settings} choose {modules} and select the {pos}"
              modules={<span className="font-bold">Modules</span>}
              pos={<span className="font-bold">POS</span>}
              settings={<span className="font-bold">Settings</span>}
            />
          </p>
        </div>

        <div className="flex items-start">
          <div className="mr-4 flex flex-col items-center">
            <div className="flex size-6 items-center justify-center rounded-full border border-primary-dark bg-white">
              <div className="size-3 rounded-full bg-primary-main" />
            </div>
            <div className="h-8 w-px bg-primary-main" />
          </div>
          <p>
            <T _str="Select the tab Tills and create a new till" />
          </p>
        </div>

        <div className="flex items-start">
          <div className="mr-4 flex flex-col items-center">
            <div className="flex size-6 items-center justify-center rounded-full border border-primary-dark bg-white">
              <div className="size-3 rounded-full bg-primary-main" />
            </div>
          </div>
          <p>
            <T
              _str="Once you have created a new till, you will be able to select it
              from this screen"
            />
          </p>
        </div>
      </div>
    </div>
  );
};
