import { T } from "@repo/transifex";
import { Link } from "react-router-dom";

import { Button } from "~/components";
import { DeviceWrapper } from "~/routes/__app.settings.devices/Device.tsx";

type ScaleProps = {
  isConnected: boolean;
  onConnect?: () => void;
  onDisconnect?: () => void;
};

export const Scale = ({ isConnected, onConnect, onDisconnect }: ScaleProps) => {
  return (
    <DeviceWrapper>
      {isConnected ? (
        <>
          <T _str="Scale is connected" />
          <Button onClick={onDisconnect} variant="danger">
            <T _str="Disconnect" />
          </Button>
        </>
      ) : (
        <>
          <p className="text-gray-600">
            <T _str="Scale is disconnected" />
          </p>
          <Link to="/settings/devices/scales">
            <Button onClick={onConnect}>
              <T _str="Select scale" />
            </Button>
          </Link>
        </>
      )}
    </DeviceWrapper>
  );
};
