import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";
import { clsx } from "clsx";

import { BackButton } from "./BackButton";

export type HeaderSettingsProps = {
  title: string;
  backUrl?: string;
  subtitle?: string;
  children?: React.ReactNode;
  action?: React.ReactNode;
};

export const HeaderSettings = ({
  backUrl,
  title,
  subtitle,
  children,
  action,
}: HeaderSettingsProps) => {
  return (
    <div className="flex h-24 w-full shrink-0 items-center justify-between border-b border-divider-main">
      <div className="flex items-center gap-5">
        {backUrl ? <BackButton to={backUrl} variant="secondary" /> : null}

        <span
          className={clsx(
            "text-2xl font-medium",
            subtitle ? "text-text-disabled" : "text-text-secondary"
          )}
        >
          <T _str={title} />
        </span>

        {subtitle ? (
          <>
            <HeaderSeparator />

            <span className="text-2xl font-medium text-text-primary">
              {subtitle}
            </span>
          </>
        ) : null}

        {children}
      </div>

      {action}
    </div>
  );
};

export const HeaderSeparator = () => (
  <FontAwesomeIcon
    className="font-medium text-text-primary"
    icon={faChevronRight}
  />
);
