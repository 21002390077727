import { useCallback } from "react";

import type { WebEvent } from "~/types/events";

/**
 * Handle communication from web -> native
 * @returns utilities to send post message to the native wrapper
 */
export const useSendWebEvent = () => {
  return useCallback((event: WebEvent) => {
    const postMessage = JSON.stringify(event);
    window.ReactNativeWebView?.postMessage(postMessage);
  }, []);
};
