import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import { employeeStore } from "./store";
import { getEmployee } from "./getters";

import type { EmployeeStore } from ".";

function useEmployee(): EmployeeStore {
  return useStore(employeeStore, useShallow(getEmployee));
}

export { useEmployee };
