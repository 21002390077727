import { clsx } from "clsx";

import { Badge } from "./Badge";

export const ItemLayout = ({
  badge,
  className,
  children,
  ...rest
}: JSX.IntrinsicElements["button"] & { badge?: string }) => (
  <div className="relative">
    {!rest.disabled && badge ? (
      <Badge className="absolute -right-2 -top-2 " text={badge} />
    ) : null}
    <button
      className={clsx(
        "min-h-[130px] w-full rounded-md outline-none",
        rest.disabled && "cursor-not-allowed opacity-50",
        className
      )}
      style={{ minHeight: "130px" }}
      type="button"
      {...rest}
    >
      {rest.disabled && badge ? (
        <Badge
          className="absolute right-1/2 top-1/2 w-max max-w-full -translate-y-1/2 translate-x-1/2"
          text={badge}
        />
      ) : null}
      {children}
    </button>
  </div>
);
