import { clsx } from "clsx";
import { useEffect, useRef, useState } from "react";

type PopoverProps = {
  children: React.ReactNode;
  content: React.ReactNode;
  trigger?: "click" | "hover";
  position?: "top" | "bottom";
  className?: string;
};

export const Popover = ({
  children,
  content,
  position = "top",
  trigger = "click",
  className,
}: PopoverProps) => {
  const [show, setShow] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleMouseOver = () => {
    if (trigger === "hover") {
      setShow(true);
    }
  };

  const handleMouseLeft = () => {
    if (trigger === "hover") {
      setShow(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShow(false);
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [show, wrapperRef]);

  return (
    <div
      className={clsx("relative flex size-fit justify-center", className)}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseLeft}
      ref={wrapperRef}
    >
      <div
        className="w-full"
        onClick={() => {
          setShow(!show);
        }}
      >
        {children}
      </div>
      <div
        className={clsx(
          "absolute right-0 z-10 h-fit w-max transition-all",
          position === "top" ? "bottom-full" : "top-full"
        )}
        hidden={!show}
      >
        <div
          className={clsx(
            "rounded-lg shadow-2xl",
            position === "top" ? "mb-2" : "mt-2"
          )}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

type PopoverItemProps = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

Popover.Item = function PopoverItem({
  children,
  onClick,
  disabled = false,
}: PopoverItemProps) {
  return (
    <button
      className={clsx(
        "flex w-full border-b border-gray-300 bg-white px-5 py-4 transition first:rounded-t-lg last:rounded-b-lg last:border-b-0 hover:bg-secondary-main",
        { "pointer-events-none text-secondary-dark": disabled }
      )}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  );
};
