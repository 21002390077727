import { clsx } from "clsx";

type BaseInputProps = {
  onClick: () => void;
  label: string;
  checked: boolean;
};

export const RadioButton = ({ label, onClick, checked }: BaseInputProps) => {
  return (
    <button className="w-full" onClick={onClick} type="button">
      <div className="flex items-center gap-3">
        <div className={clsx("relative size-6 rounded-full border-2 p-2")}>
          <div
            className={clsx(
              "absolute inset-0 m-auto size-4 rounded-full",
              checked && "bg-primary-main"
            )}
          />
        </div>

        {label}
      </div>
    </button>
  );
};
