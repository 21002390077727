import type { SizeProp } from "@fortawesome/fontawesome-svg-core";
import type { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T } from "@repo/transifex";
import { clsx } from "clsx";

type VariantType =
  | "primary"
  | "secondary"
  | "secondary-inverted"
  | "danger"
  | "primary-light"
  | "danger-light"
  | "light"
  | "transparent"
  | "info"
  | "info-inverted"
  | "skeleton"
  | "dark-blue";

type SizeType = "small" | "medium" | "large" | "xl";

type IconProps =
  | {
      icon?: IconDefinition;
      iconClassName?: string;
      iconSize?: SizeProp;
    }
  | {
      icon?: React.ReactNode;
      iconClassName?: never;
      iconSize?: never;
    };

type ButtonProps = {
  loading?: boolean;
  loadingText?: string;
  variant?: VariantType;
  size?: SizeType;
  textClassName?: string;
} & IconProps &
  JSX.IntrinsicElements["button"];

export const Button = ({
  loading = false,
  loadingText = "Loading...",
  variant = "primary",
  size = "medium",
  className,
  icon,
  iconClassName,
  iconSize,
  children,
  textClassName,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={clsx(
        "rounded-md transition-all ease-in-out",
        {
          "pointer-events-none cursor-not-allowed opacity-40": props.disabled,
          "pointer-events-none cursor-wait opacity-80": loading,
          "bg-primary-main text-primary-contrast": variant === "primary",
          "bg-secondary-main text-secondary-contrast": variant === "secondary",
          "bg-danger-main text-danger-contrast": variant === "danger",
          "bg-success-backdrop text-success-dark": variant === "primary-light",
          "bg-danger-backdrop text-danger-dark": variant === "danger-light",
          "text-text-secondary bg-white": variant === "light",
          "bg-info-dark text-info-backdrop": variant === "info",
          "text-info-dark bg-info-backdrop": variant === "info-inverted",
          "text-background-primary bg-text-primary":
            variant === "secondary-inverted",
          "text-info-backdrop bg-info-backdrop": variant === "skeleton",
          "bg-transparent": variant === "transparent",
          "bg-info-backdrop/20 text-background-primary":
            variant === "dark-blue",
          "px-3 py-1 text-sm": size === "small",
          "px-5 py-3 text-base font-medium": size === "medium",
          "px-5 py-4 text-lg font-bold": size === "large",
          "px-5 py-6 text-lg font-bold": size === "xl",
        },
        className
      )}
      type="button"
      {...props}
    >
      <span
        className={clsx(
          {
            "flex w-full flex-nowrap items-center justify-center whitespace-nowrap text-center":
              Boolean(children),
            "animate-pulse": loading,
            "gap-x-2": icon && children,
          },
          textClassName
        )}
      >
        {icon ? (
          <div className="flex min-h-[24px] min-w-[14px] max-w-[24px] items-center justify-center">
            {isIconDefinition(icon) ? (
              <FontAwesomeIcon
                className={clsx(iconClassName)}
                icon={icon}
                size={iconSize}
              />
            ) : (
              icon
            )}
          </div>
        ) : null}
        {loading ? <T _str={loadingText} /> : children}
      </span>
    </button>
  );
};

/**
 * user-defined type guard to check if the icon prop is an IconDefinition
 * @param {IconDefinition | ReactNode} icon prop to check the type
 * @returns {boolean} true if the icon prop is an IconDefinition
 */
function isIconDefinition(icon: unknown): icon is IconDefinition {
  return "iconName" in (icon as object);
}
