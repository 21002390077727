import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import type { Currency } from "@repo/types";
import { clsx } from "clsx";
import {
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from "react";

import { useAuth } from "~/providers/store/auth";

import { Button } from "./Button";
import { NumPadDialog } from "./NumPadDialog";

type ReportBodyProps = {
  title: string;
  currency: Currency;
  cashBalance: number;
  setCashBalance: Dispatch<SetStateAction<number>>;
  expected?: number;
  hideExpected?: boolean;
  hideDifference?: boolean;
};

export const Report = ({
  title,
  expected = 0,
  cashBalance,
  setCashBalance,
  currency,
  hideExpected = false,
  hideDifference = false,
}: ReportBodyProps) => {
  const { locale } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);

  const cash = Number(cashBalance);
  const difference = Number(expected) - Number(cashBalance);

  return (
    <>
      <ReportLayout className="p-8" title={title}>
        <div className="flex flex-wrap items-center gap-x-20 gap-y-3">
          {!hideExpected && (
            <div className="flex gap-3">
              <p className="text-text-secondary">
                <T _str="Expected" />
              </p>

              <p>
                {priceFormatter({ value: expected * 100, currency, locale })}
              </p>
            </div>
          )}

          <div className="flex items-center gap-3">
            <p className="text-gray-600">
              <T _str="Registered" />
            </p>

            <div
              className="w-48 rounded-md bg-white p-2"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              <p
                className={clsx({
                  "text-gray-600": cash === 0,
                })}
              >
                {priceFormatter({ value: cash * 100, currency, locale })}
              </p>
            </div>
          </div>
        </div>

        {!hideDifference && (
          <div className="flex gap-3">
            <p className="text-text-secondary">
              <T _str="Difference" />
            </p>

            <p
              className={clsx(
                difference === 0 ? "text-primary-main" : "text-danger-dark"
              )}
            >
              {priceFormatter({ value: difference * 100, currency, locale })}
            </p>
          </div>
        )}
      </ReportLayout>

      <NumPadDialog
        onChange={setCashBalance}
        open={dialogOpen}
        setOpen={setDialogOpen}
        value={cashBalance}
      >
        <>
          <Button
            onClick={() => {
              setCashBalance(0);
              setDialogOpen(false);
            }}
            size="xl"
            variant="danger"
          >
            <T _str="Cancel" />
          </Button>
          <Button
            className="col-span-2"
            onClick={() => {
              setDialogOpen(false);
            }}
            size="xl"
          >
            <T _str="Confirm" />
          </Button>
        </>
      </NumPadDialog>
    </>
  );
};

export const ReportLayout = ({
  title,
  children,
  className = "",
}: {
  title: string | ReactNode;
  children?: ReactNode;
  className?: string;
}) => (
  <div className={clsx("size-full", className)}>
    <div className="mb-5 flex flex-col gap-y-2">
      <p className="text-3xl">
        {title instanceof Object ? title : <T _str={title?.toString()} />}
      </p>

      <div className="flex flex-col items-center justify-between gap-3 rounded-lg bg-secondary-main px-6 py-5 text-base md:flex-row">
        {children}
      </div>
    </div>
  </div>
);
