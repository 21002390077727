import { basketStore } from "./store";
import type { BasketState, BasketStore } from "./store.types";

// Selectors
const storeSelector = (state: BasketStore): BasketState => state;
const diningOptionSelector = (
  state: BasketStore
): BasketState["diningOption"] => state.diningOption;

// Getters
function getBasket(): BasketStore {
  return storeSelector(basketStore.getState());
}

function getDiningOption(): BasketState["diningOption"] {
  return diningOptionSelector(basketStore.getState());
}

function getIsCardPaymentInProgress(): BasketState["isCardPaymentInProgress"] {
  return storeSelector(basketStore.getState()).isCardPaymentInProgress;
}

export { getBasket, getDiningOption, getIsCardPaymentInProgress };
