import type { NumPadProps } from "~/components";
import { Dialog, NumPad } from "~/components";

type NumPadDialogProps = {
  value: number;
  onChange: (value: number) => void;
  setOpen: (value: boolean) => void;
  open: boolean;
} & Pick<NumPadProps, "variant" | "placeholder" | "children">;

export const NumPadDialog = ({
  value,
  onChange,
  setOpen,
  open,
  children,
  ...rest
}: NumPadDialogProps) => {
  return (
    <Dialog
      onOutsideClick={() => {
        setOpen(false);
      }}
      open={open}
      size="md"
    >
      <div className="flex w-full">
        <NumPad setValue={onChange} value={value} {...rest}>
          {children}
        </NumPad>
      </div>
    </Dialog>
  );
};
