import { clsx } from "clsx";

type SquareButtonProps = {
  variant?: "default" | "danger" | "success";
} & JSX.IntrinsicElements["button"];

export const SquareButton = ({
  variant = "default",
  className,
  ...rest
}: SquareButtonProps) => {
  return (
    <button
      className={clsx(
        "flex h-20 w-auto items-center justify-center rounded-md text-5xl text-white",
        {
          "bg-text-primary hover:bg-text-primary-light": variant === "default",
          "bg-danger-dark hover:bg-danger-main": variant === "danger",
          "bg-success-dark hover:bg-success-main text-sm":
            variant === "success",
        },
        className
      )}
      type="button"
      {...rest}
    />
  );
};
