import type { Language } from "@repo/types";

import { clearSentryTagProfile, setSentryTagProfile } from "~/utils/sentry";

import { authStore, INITIAL_STATE } from "./store";
import type { AuthStore } from "./store.types";

/**
 * Set user data after user login
 */
function login(
  params: Required<
    Pick<
      AuthStore,
      | "token"
      | "partnerId"
      | "language"
      | "country"
      | "isKanplaAdmin"
      | "locale"
    >
  >
): void {
  authStore.setState({ ...params, status: "user" });
}

/**
 * Logout user
 */
function logout(): void {
  authStore.setState(INITIAL_STATE);
}

/**
 * Set profile data after profile selection
 */
function loginProfile(
  params: Required<Pick<AuthStore, "profileId" | "childId">>
): void {
  setSentryTagProfile(params.profileId);
  authStore.setState({ ...params, status: "profile" });
}

/**
 * Logout profile
 */
function logoutProfile(): void {
  clearSentryTagProfile();
  authStore.setState({ childId: null, profileId: null, status: "till" });
}

type AssignTillParams = Required<
  Pick<
    AuthStore,
    "moduleId" | "userId" | "schoolId" | "tillId" | "language" | "partnerId"
  >
>;
/**
 * Set till data after till assignment
 */
function assignTill(params: Omit<AssignTillParams, "partnerId">): void {
  authStore.setState({
    ...params,
    deviceTillId: params.tillId,
    status: "till",
  });
}

/**
 * Set till data to impersonate till (except updating deviceTillId)
 */
function impersonateTill(params: AssignTillParams): void {
  authStore.setState({
    ...params,
    status: "till",
  });
}

function logoutTill(): void {
  authStore.setState({
    tillId: null,
    moduleId: null,
    status: "user",
  });
}

/**
 * Update language
 */
function updateLanguage(language: Language): void {
  authStore.setState({ language });
}

/**
 * Update online status
 */
function updateOnlineStatus(isOnline: boolean) {
  authStore.setState({
    isOnline,
  });
}

const authActions = {
  login,
  logout,
  loginProfile,
  logoutProfile,
  assignTill,
  impersonateTill,
  logoutTill,
  updateLanguage,
  updateOnlineStatus,
};

export { authActions };
