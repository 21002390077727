import {
  faMinus,
  faPlus,
  faTrash,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { round } from "@repo/system";
import { T, useT } from "@repo/transifex";
import type { Currency } from "@repo/types";
import { useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { Button, Dialog } from "~/components";
import type { DiscountTypes } from "~/components/Inputs/DiscountInput";
import { DiscountInput } from "~/components/Inputs/DiscountInput";
import { InputWithNumPad } from "~/components/Inputs/InputWithNumPad";
import { useCurrentTill } from "~/hooks/queries";
import type { PosProductInBasket } from "~/providers/app";
import { useAppDispatch, useBasketProducts } from "~/providers/app";
import { basketActions, useOrderDiscount } from "~/providers/store/basket";
import { modalVariantsActions } from "~/providers/store/modal-variants";

export const DialogBasketProduct = () => {
  const { basketProductId } = useParams();

  const {
    productsInBasket,
    meta: { currency },
  } = useBasketProducts();
  const product = productsInBasket.find((p) => p.basketId === basketProductId);

  if (!product) return <Navigate to=".." />;

  return (
    <Dialog backUrl=".." size="md">
      <BasketProductForm currency={currency} product={product} />
    </Dialog>
  );
};

const BasketProductForm = ({
  product,
  currency,
}: {
  product: PosProductInBasket;
  currency: Currency;
}) => {
  const orderDiscount = useOrderDiscount();

  const [discountType, setDiscountType] = useState<DiscountTypes>("percentage");

  const [amount, setAmount] = useState(product.quantity);
  const [discount, setDiscount] = useState(
    product.discountUnitPrice
      ? (product.discountUnitPrice * product.quantity) / 100
      : undefined
  );
  const [percentage, setPercentage] = useState(
    product.discountPercentage ? product.discountPercentage : undefined
  );
  const [price, setPrice] = useState(
    (product.totalUnitPrice / 100) * product.quantity -
      ((product.discountUnitPrice || 0) * product.quantity) / 100
  );

  const t = useT();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: till } = useCurrentTill();

  const handleResetCountdown = () => {
    dispatch({ type: "COUNTDOWN_RESET" });
  };

  const handleChangeAmount = (
    action: "increment" | "decrement" | "input",
    inputAmount?: number
  ) => {
    handleResetCountdown();

    const actionHandlers: Record<
      "increment" | "decrement" | "input",
      () => number
    > = {
      decrement: () => Math.max(1, amount - 1),
      increment: () => Math.min(product.limit || Infinity, amount + 1),
      input: () => inputAmount ?? 1,
    };

    const newAmount = actionHandlers[action]();

    setAmount(newAmount);
    const newPrice = getPrice({
      productTotalUnitPrice: product.totalUnitPrice,
      amount: newAmount,
      ...(discountType === "percentage"
        ? { percentage: percentage ?? 0 }
        : { discount: discount ?? 0 }),
    });
    setPrice(newPrice);

    // at onChange we update the final price and discount type in the background
    if (discountType === "percentage") {
      setDiscount(
        round((product.totalUnitPrice * newAmount) / 100 - newPrice, 2)
      );
    } else {
      setPercentage(
        round(
          ((discount ?? 0) / ((product.totalUnitPrice * newAmount) / 100)) *
            100,
          0
        )
      );
    }
  };

  const handleChangeDiscount = (newDiscount: number) => {
    if (newDiscount === 0) {
      setDiscount(undefined);
      setPercentage(undefined);
      setPrice(round((product.totalUnitPrice / 100) * amount, 2));
      return;
    }

    const percentage = round(
      (newDiscount / ((product.totalUnitPrice * amount) / 100)) * 100,
      0
    );

    setDiscount(newDiscount);
    setPercentage(percentage);
    setPrice(round((product.totalUnitPrice / 100) * amount - newDiscount, 2));
  };

  const handleChangePercentage = (newPercentage: number) => {
    if (newPercentage === 0) {
      setDiscount(undefined);
      setPercentage(undefined);
      setPrice(round((product.totalUnitPrice / 100) * amount, 2));
      return;
    }

    const discount = round(
      (newPercentage * ((product.totalUnitPrice * amount) / 100)) / 100,
      2
    );

    setDiscount(discount);
    setPercentage(newPercentage);
    setPrice(round((product.totalUnitPrice / 100) * amount - discount, 2));
  };

  const handleChangePrice = (newPrice: number) => {
    setPrice(newPrice);

    if (newPrice === 0) {
      setDiscount((product.totalUnitPrice / 100) * amount);
      setPercentage(100);
      return;
    }

    const discount = round(
      (product.totalUnitPrice * amount) / 100 - newPrice,
      2
    );

    const percentage = round(
      (discount / ((product.totalUnitPrice * amount) / 100)) * 100,
      0
    );

    setDiscount(discount);
    setPercentage(percentage);
  };

  const discountError = useMemo(() => {
    if (typeof discount === "undefined") {
      return null;
    }

    if (price < 0) {
      return t("Maximum discount is 100%");
    }

    if (price > (product.totalUnitPrice * amount) / 100) {
      return t("Discount cannot be negative");
    }

    return null;
  }, [amount, discount, price, product.totalUnitPrice, t]);

  return (
    <div className="flex flex-col gap-8 p-5">
      <div className="flex items-center justify-between border-b border-divider-main pb-2.5">
        <Button
          className="text-base font-medium text-danger-dark"
          onClick={() => {
            navigate("..");
          }}
          size="small"
          variant="transparent"
        >
          <T _str="Cancel" />
        </Button>

        <span className="text-center text-lg font-bold text-text-primary">
          {product.name}
        </span>

        <Button
          disabled={Boolean(discountError)}
          onClick={() => {
            basketActions.updateAmount(product.basketId, amount);
            if (typeof discount === "number") {
              basketActions.setDiscount(product.basketId, discount * 100);
            } else {
              basketActions.removeDiscount(product.basketId);
            }

            navigate("..");
          }}
        >
          <T _str="Done" />
        </Button>
      </div>

      {product.unitSystem === "piece" ? (
        <div className="flex flex-col pb-6">
          <span className="font-medium text-text-primary">
            <T _str="Amount" />
          </span>

          <div className="mt-4 flex">
            <Button
              className="rounded-r-none !px-9"
              disabled={amount === 1}
              icon={faMinus}
              onClick={() => {
                handleChangeAmount("decrement");
              }}
              variant="secondary"
            />

            <InputWithNumPad
              amount={amount}
              className="rounded-none"
              onChange={(newValue) => {
                handleChangeAmount("input", newValue);
              }}
              variant="amount"
            />

            <Button
              className="rounded-l-none !px-9"
              disabled={product.limit === 0}
              icon={faPlus}
              onClick={() => {
                handleChangeAmount("increment");
              }}
              variant="secondary"
            />
          </div>
        </div>
      ) : null}

      {till?.type === "attended" &&
      product.type !== "custom" &&
      product.unitPrice > 0 &&
      !orderDiscount.amount ? (
        <div className="flex flex-col">
          <span className="pb-4 font-medium text-text-primary">
            <T _str="Discount" />
          </span>

          <DiscountInput
            currency={currency}
            handleChangeDiscount={
              discountType === "currency"
                ? handleChangeDiscount
                : handleChangePercentage
            }
            handleChangeType={setDiscountType}
            type={discountType}
            value={discountType === "currency" ? discount : percentage}
          />
          {discountError ? (
            <div className="mt-3 flex items-center gap-1 text-warning-dark">
              <FontAwesomeIcon icon={faWarning} />
              <span className="text-base font-medium">{discountError}</span>
            </div>
          ) : null}

          <span className="pb-4 pt-6 font-medium text-text-primary">
            <T _str="Change price" />
          </span>

          <InputWithNumPad
            amount={price}
            className="items-start !justify-start"
            onChange={(value) => {
              handleChangePrice(value);
            }}
            suffix={currency}
            variant="currency"
          />
        </div>
      ) : null}

      <div className="flex gap-2">
        {(product.options || []).length > 0 &&
        product.unitSystem === "piece" ? (
          <Button
            className="w-full"
            onClick={() => {
              modalVariantsActions.initEdit({
                productId: product.id,
                options: product.options ?? [],
                amount: product.quantity,
              });

              navigate(
                `/product/${product.id}/variants?edit=${product.basketId}`
              );
            }}
            variant="secondary"
          >
            <T _str="Edit" />
          </Button>
        ) : null}
        <Button
          className="w-full"
          icon={faTrash}
          onClick={() => {
            basketActions.remove(product.basketId);
          }}
          variant="danger-light"
        >
          <T _str="Remove from basket" />
        </Button>
      </div>
    </div>
  );
};

const getPrice = ({
  productTotalUnitPrice,
  amount,
  percentage,
  discount,
}: {
  productTotalUnitPrice: number;
  amount: number;
  percentage?: number;
  discount?: number;
}) => {
  let newPrice = 0;
  if (typeof percentage === "number") {
    newPrice =
      (((productTotalUnitPrice * amount) / 100) * (100 - percentage)) / 100;
  } else {
    newPrice = (productTotalUnitPrice * amount) / 100 - (discount || 0);
  }
  return round(newPrice, 2);
};
