import type { AllowanceUsage, Product } from "@repo/types";

type AllowanceMaxAmountArgs = {
  /** Product price */
  productPrice: Product["unitPrice"];
  /** The allowance usages for the current product (already filtered) @see getAllowancesForProducts */
  allowanceUsages: AllowanceUsage[];
}

/**
 * Returns the maximum amount of products that can be added to the basket
 * type `AllowanceMaxAmountArgs`
 * @returns `number` | `null`
 */
export const getAllowanceMaxAmount = ({
  productPrice,
  allowanceUsages,
}: AllowanceMaxAmountArgs) => {
  if (allowanceUsages.length === 0) return null;

  const amounts: number[] = [];

  const allowanceUsagesWithAmount = allowanceUsages.filter(
    (usage) => typeof usage.numberOfProducts?.amount !== "undefined"
  );

  const allowanceUsagesWithPrice = allowanceUsages.filter(
    (usage) => typeof usage.unitPrice?.amount !== "undefined"
  );

  if (allowanceUsagesWithAmount.length > 0) {
    const maxAmountForNumberOfProducts = Math.min(
      ...allowanceUsagesWithAmount.map(
        (usage) => usage.numberOfProducts?.amount ?? Infinity
      )
    );

    amounts.push(maxAmountForNumberOfProducts);
  }

  if ((allowanceUsagesWithPrice.length > 0)) {
    const maxAmountForPrice = Math.min(
      ...allowanceUsagesWithPrice.map(
        (usage) => usage.unitPrice?.amount ?? Infinity
      )
    );

    amounts.push(Math.floor(maxAmountForPrice / productPrice));
  }

  return Math.min(...amounts);
};
