import { T } from "@repo/transifex";

import { Button } from "./Button";

export const TillStatusButton = ({ status }: { status: "open" | "closed" }) => {
  return (
    <Button
      className="pointer-events-none"
      size="small"
      variant={status === "closed" ? "danger-light" : "primary-light"}
    >
      <T _str={status === "closed" ? "Closed" : "Open"} />
    </Button>
  );
};
