import type { Operator, OrderLine, RequiredOrder } from "@repo/types";

export const validateRequiredOrder = (
  orderLines: Pick<OrderLine, "amount" | "productId">[],
  validationDocument?: RequiredOrder
) => {
  if (!validationDocument?.layers.length) return true;

  const operator: Operator = validationDocument.operator;

  // Check that every condition is met
  if (operator === "AND") {
    // Map through layers
    const andResults = validationDocument.layers.map((layer) => {
      if (!layer.productIds.length) return true;

      return orderLines.some((orderLine) => {
        const { productId, amount } = orderLine;
        // Is correct amount as well as is any of product IDs
        return amount >= layer.amount && layer.productIds.includes(productId);
      });
    });

    return andResults.every((res) => res);
  }

  // OR operator is not supported yet
  throw new Error("Not able to handle OR operator yet");
};
