import type { Product } from "@repo/types";

export const getUnitOfMeasure = (
  unitSystem?: Omit<Product["unitSystem"], "piece">
): "grams" => {
  switch (unitSystem) {
    case "1-gram":
    case "100-grams":
    case "kilogram":
      return "grams";
    default:
      return "grams";
  }
};
