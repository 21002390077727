import { useLocation } from "react-router-dom";

import { useCurrentTill } from "~/hooks/queries";
import { useEmployee } from "~/providers/store/employee";

import { Basket } from "./Basket";
import { WelcomeGuest } from "./WelcomeGuest";

export const BasketWrapper = () => {
  const { pathname } = useLocation();

  const { data: till } = useCurrentTill();

  const { groupName } = useEmployee();

  // show welcome UI only if the user is on the choosing selectors screen
  if (
    till?.type === "self-service" &&
    groupName === null &&
    pathname.includes("selectors")
  )
    return <WelcomeGuest />;

  return (
    <div className="flex w-full flex-col gap-y-6">
      <Basket />
    </div>
  );
};
