import { useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";

import { client, getAuthHeader } from "./api.client";

function useIntercomCreateTicket() {
  return useMutation({
    mutationFn: async ({
      deviceId,
      sessionId,
    }: {
      deviceId: string | null;
      sessionId: string | null | undefined;
    }) => {
      const { tillId } = getAuth();

      if (!tillId) {
        throw new Error("Till not assigned");
      }

      const res = await client.v1.intercom.ticket.create({
        body: {
          tillId,
          deviceId: deviceId ?? "unknown",
          email: "pos@kanpla.dk",
          sessionId: sessionId ?? undefined,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 204) {
        return res.body;
      }
      throw res;
    },
  });
}

export { useIntercomCreateTicket };
