import { round } from "@repo/system";
import type { StepVariant } from "@repo/types";

export const calculateVariantTotal = (
  variants: StepVariant[],
  vatRate: number
) => {
  return variants.reduce((sum, variant) => {
    const choicesSum = variant.choices.reduce((sum, choice) => {
      const choicePrice = round((choice.unitPrice ?? 0) * (1 + vatRate), 2);
      const choiceTotal = choice.amount * choicePrice;
      return sum + choiceTotal;
    }, 0);

    return sum + choicesSum;
  }, 0);
};
