import { authStore } from "./store";
import type { AuthState, AuthStore } from "./store.types";

// Selectors
const storeSelector = (state: AuthStore): AuthState => state as AuthState;
const tokenSelector = (state: AuthStore): AuthState["token"] => state.token;
const statusSelector = (state: AuthStore): AuthState["status"] => state.status;

// Getters
function getAuthToken(): AuthState["token"] {
  return tokenSelector(authStore.getState());
}
function getAuth(): AuthState {
  return storeSelector(authStore.getState());
}
function getAuthStatus(): AuthState["status"] {
  return statusSelector(authStore.getState());
}

export { getAuth, getAuthToken, getAuthStatus };
