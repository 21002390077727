import { createStore } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import type { RefundStore } from "./store.types";

const INITIAL_STATE: RefundStore = {
  refundMethod: "card",
  selectedOrderLines: [],
};

const refundStore = createStore<RefundStore>()(
  immer(devtools(() => INITIAL_STATE, { name: "refund-store" }))
);

export { INITIAL_STATE, refundStore };
