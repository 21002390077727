export const ScanErrorSvg = () => (
  <svg
    fill="none"
    height="144"
    viewBox="0 0 225 144"
    width="225"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_8073_9719)">
      <path
        d="M169.581 30.1864C173.999 30.1864 177.581 33.7681 177.581 38.1864L177.581 219.872C177.581 224.29 173.999 227.872 169.581 227.872L56.0366 227.872C51.6183 227.872 48.0366 224.29 48.0366 219.872L48.0366 38.1864C48.0366 33.7681 51.6183 30.1864 56.0366 30.1864L169.581 30.1864Z"
        fill="url(#paint0_linear_8073_9719)"
      />
      <path
        d="M107.648 140.29C107.648 139.599 107.807 138.992 108.124 138.47C108.442 137.956 108.876 137.555 109.426 137.266C109.977 136.986 110.602 136.846 111.302 136.846C112.002 136.846 112.623 136.99 113.164 137.28C113.715 137.569 114.144 137.97 114.452 138.484C114.77 138.997 114.928 139.604 114.928 140.304C114.928 140.873 114.812 141.377 114.578 141.816C114.345 142.254 114.018 142.595 113.598 142.838L117.896 142.838L117.896 144.014L107.816 144.014L107.816 142.95L108.964 142.838C108.628 142.614 108.325 142.292 108.054 141.872C107.784 141.452 107.648 140.924 107.648 140.29ZM108.67 140.416C108.67 140.882 108.778 141.293 108.992 141.648C109.216 142.012 109.524 142.292 109.916 142.488C110.308 142.693 110.766 142.796 111.288 142.796C111.811 142.796 112.268 142.693 112.66 142.488C113.052 142.292 113.356 142.012 113.57 141.648C113.794 141.293 113.906 140.882 113.906 140.416C113.906 139.958 113.794 139.548 113.57 139.184C113.356 138.829 113.052 138.549 112.66 138.344C112.268 138.148 111.811 138.05 111.288 138.05C110.766 138.05 110.308 138.148 109.916 138.344C109.524 138.549 109.216 138.829 108.992 139.184C108.778 139.548 108.67 139.958 108.67 140.416Z"
        fill="white"
      />
      <path
        d="M116.3 134.521C116.3 134.297 116.375 134.105 116.524 133.947C116.683 133.788 116.879 133.709 117.112 133.709C117.336 133.709 117.523 133.788 117.672 133.947C117.822 134.105 117.896 134.297 117.896 134.521C117.896 134.745 117.822 134.931 117.672 135.081C117.523 135.239 117.336 135.319 117.112 135.319C116.879 135.319 116.683 135.239 116.524 135.081C116.375 134.931 116.3 134.745 116.3 134.521ZM104.736 132.393L105.744 132.393L105.744 132.939C105.744 133.284 105.819 133.531 105.968 133.681C106.108 133.839 106.351 133.919 106.696 133.919L114.76 133.919L114.76 135.095L106.668 135.095C105.996 135.095 105.506 134.927 105.198 134.591C104.89 134.255 104.736 133.765 104.736 133.121L104.736 132.393Z"
        fill="white"
      />
      <path
        d="M107.649 128.9C107.649 128.238 107.798 127.65 108.097 127.136C108.405 126.623 108.829 126.217 109.371 125.918C109.912 125.629 110.551 125.484 111.289 125.484C112.017 125.484 112.651 125.629 113.193 125.918C113.743 126.208 114.168 126.609 114.467 127.122C114.775 127.645 114.929 128.247 114.929 128.928C114.929 129.6 114.775 130.179 114.467 130.664C114.168 131.159 113.771 131.537 113.277 131.798C112.782 132.06 112.25 132.19 111.681 132.19C111.578 132.19 111.475 132.186 111.373 132.176C111.27 132.176 111.153 132.176 111.023 132.176L111.023 126.646C110.491 126.674 110.047 126.796 109.693 127.01C109.347 127.234 109.086 127.51 108.909 127.836C108.731 128.172 108.643 128.527 108.643 128.9C108.643 129.386 108.755 129.792 108.979 130.118C109.203 130.445 109.506 130.683 109.889 130.832L109.889 131.994C109.245 131.808 108.708 131.448 108.279 130.916C107.859 130.394 107.649 129.722 107.649 128.9ZM113.935 128.9C113.935 128.34 113.762 127.841 113.417 127.402C113.081 126.973 112.586 126.726 111.933 126.66L111.933 131.028C112.558 131 113.048 130.786 113.403 130.384C113.757 129.983 113.935 129.488 113.935 128.9Z"
        fill="white"
      />
      <path
        d="M107.648 120.698C107.648 120.129 107.765 119.62 107.998 119.172C108.232 118.734 108.558 118.398 108.978 118.164L107.816 118.052L107.816 116.988L117.896 116.988L117.896 118.164L113.612 118.164C113.948 118.388 114.252 118.706 114.522 119.116C114.793 119.536 114.928 120.068 114.928 120.712C114.928 121.403 114.77 122.005 114.452 122.518C114.135 123.032 113.701 123.428 113.15 123.708C112.6 123.998 111.974 124.142 111.274 124.142C110.574 124.142 109.949 123.998 109.398 123.708C108.857 123.428 108.428 123.027 108.11 122.504C107.802 121.991 107.648 121.389 107.648 120.698ZM108.67 120.572C108.67 121.039 108.778 121.45 108.992 121.804C109.216 122.159 109.524 122.439 109.916 122.644C110.308 122.85 110.766 122.952 111.288 122.952C111.811 122.952 112.268 122.85 112.66 122.644C113.052 122.439 113.356 122.159 113.57 121.804C113.794 121.45 113.906 121.039 113.906 120.572C113.906 120.106 113.794 119.695 113.57 119.34C113.356 118.986 113.052 118.706 112.66 118.5C112.268 118.295 111.811 118.192 111.288 118.192C110.766 118.192 110.308 118.295 109.916 118.5C109.524 118.706 109.216 118.986 108.992 119.34C108.778 119.695 108.67 120.106 108.67 120.572Z"
        fill="white"
      />
      <path
        d="M107.817 111.902L114.761 111.902L114.761 112.966L113.431 113.064C113.888 113.279 114.252 113.606 114.523 114.044C114.793 114.483 114.929 115.024 114.929 115.668L113.697 115.668L113.697 115.346C113.697 114.936 113.622 114.558 113.473 114.212C113.333 113.867 113.09 113.592 112.745 113.386C112.399 113.181 111.923 113.078 111.317 113.078L107.817 113.078L107.817 111.902Z"
        fill="white"
      />
      <path
        d="M107.649 106.638C107.649 106.06 107.747 105.579 107.943 105.196C108.139 104.814 108.4 104.529 108.727 104.342C109.053 104.156 109.408 104.062 109.791 104.062C110.5 104.062 111.046 104.333 111.429 104.874C111.811 105.416 112.003 106.153 112.003 107.086L112.003 108.962L112.087 108.962C112.693 108.962 113.151 108.804 113.459 108.486C113.776 108.169 113.935 107.744 113.935 107.212C113.935 106.755 113.818 106.358 113.585 106.022C113.361 105.696 113.029 105.49 112.591 105.406L112.591 104.202C113.095 104.249 113.519 104.417 113.865 104.707C114.21 105.005 114.471 105.374 114.649 105.812C114.835 106.251 114.929 106.718 114.929 107.212C114.929 108.183 114.667 108.911 114.145 109.396C113.631 109.891 112.945 110.139 112.087 110.138L107.817 110.138L107.817 109.089L109.063 109.019C108.671 108.823 108.335 108.533 108.055 108.151C107.784 107.777 107.649 107.273 107.649 106.638ZM108.643 106.82C108.643 107.268 108.759 107.651 108.993 107.968C109.226 108.295 109.529 108.542 109.903 108.71C110.276 108.878 110.668 108.962 111.079 108.962L111.093 108.962L111.093 107.184C111.093 106.494 110.971 106.004 110.729 105.715C110.495 105.435 110.201 105.294 109.847 105.294C109.483 105.294 109.189 105.425 108.965 105.686C108.75 105.957 108.643 106.335 108.643 106.82Z"
        fill="white"
      />
      <path
        d="M107.648 98.6862C107.648 97.9955 107.807 97.3889 108.124 96.8662C108.442 96.3529 108.876 95.9515 109.426 95.6622C109.977 95.3822 110.602 95.2422 111.302 95.2422C112.002 95.2422 112.623 95.3869 113.164 95.6762C113.715 95.9655 114.144 96.3669 114.452 96.8802C114.77 97.3935 114.928 98.0002 114.928 98.7002C114.928 99.2695 114.812 99.7735 114.578 100.212C114.345 100.651 114.018 100.992 113.598 101.234L117.896 101.234L117.896 102.41L107.816 102.41L107.816 101.346L108.964 101.234C108.628 101.01 108.325 100.688 108.054 100.268C107.784 99.8482 107.648 99.3209 107.648 98.6862ZM108.67 98.8122C108.67 99.2789 108.778 99.6895 108.992 100.044C109.216 100.408 109.524 100.688 109.916 100.884C110.308 101.09 110.766 101.192 111.288 101.192C111.811 101.192 112.268 101.09 112.66 100.884C113.052 100.688 113.356 100.408 113.57 100.044C113.794 99.6895 113.906 99.2789 113.906 98.8122C113.906 98.3549 113.794 97.9442 113.57 97.5802C113.356 97.2255 113.052 96.9455 112.66 96.7402C112.268 96.5442 111.811 96.4462 111.288 96.4462C110.766 96.4462 110.308 96.5442 109.916 96.7402C109.524 96.9455 109.216 97.2255 108.992 97.5802C108.778 97.9442 108.67 98.3549 108.67 98.8122Z"
        fill="white"
      />
      <path
        d="M107.649 90.6875C107.649 90.0248 107.798 89.4368 108.097 88.9235C108.405 88.4101 108.829 88.0042 109.371 87.7055C109.912 87.4162 110.551 87.2715 111.289 87.2715C112.017 87.2715 112.651 87.4162 113.193 87.7055C113.743 87.9948 114.168 88.3961 114.467 88.9095C114.775 89.4321 114.929 90.0341 114.929 90.7155C114.929 91.3875 114.775 91.9661 114.467 92.4515C114.168 92.9461 113.771 93.3241 113.277 93.5855C112.782 93.8468 112.25 93.9775 111.681 93.9775C111.578 93.9775 111.475 93.9728 111.373 93.9635C111.27 93.9635 111.153 93.9635 111.023 93.9635L111.023 88.4335C110.491 88.4615 110.047 88.5828 109.693 88.7975C109.347 89.0215 109.086 89.2968 108.909 89.6235C108.731 89.9595 108.643 90.3142 108.643 90.6875C108.643 91.1728 108.755 91.5788 108.979 91.9055C109.203 92.2321 109.506 92.4702 109.889 92.6195L109.889 93.7815C109.245 93.5948 108.708 93.2355 108.279 92.7035C107.859 92.1808 107.649 91.5088 107.649 90.6875ZM113.935 90.6875C113.935 90.1275 113.762 89.6282 113.417 89.1895C113.081 88.7602 112.586 88.5128 111.933 88.4475L111.933 92.8155C112.558 92.7875 113.048 92.5728 113.403 92.1715C113.757 91.7702 113.935 91.2755 113.935 90.6875Z"
        fill="white"
      />
      <path
        d="M107.816 75.7832L117.616 75.7832L117.616 77.1552L110.784 80.6832L117.616 84.1832L117.616 85.5692L107.816 85.5692L107.816 84.3932L115.516 84.3932L109.216 81.1172L109.216 80.2352L115.502 76.9592L107.816 76.9592L107.816 75.7832Z"
        fill="white"
      />
      <path
        d="M64.9197 123.065L61.6385 123.065L61.6385 122.227L67.093 122.227L67.093 123.036L66.2408 123.036L66.2408 123.107C66.5178 123.235 66.7403 123.429 66.9084 123.69C67.0788 123.95 67.1641 124.286 67.1641 124.698C67.1641 125.067 67.0883 125.391 66.9368 125.668C66.7876 125.945 66.5604 126.16 66.255 126.314C65.9519 126.468 65.5684 126.545 65.1044 126.545L61.6385 126.545L61.6385 125.707L65.0476 125.707C65.4761 125.707 65.8099 125.595 66.049 125.373C66.2905 125.15 66.4112 124.845 66.4112 124.457C66.4112 124.189 66.3532 123.95 66.2372 123.739C66.1212 123.531 65.9519 123.366 65.7294 123.246C65.5069 123.125 65.237 123.065 64.9197 123.065Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M67.0928 121.121L61.6382 119.104L61.6382 118.251L67.0928 116.234L67.0928 117.143L62.7462 118.649L62.7462 118.706L67.0928 120.212L67.0928 121.121Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.5107 112.826C61.5107 112.48 61.5758 112.166 61.706 111.885C61.8385 111.603 62.0291 111.379 62.2777 111.213C62.5286 111.048 62.8317 110.965 63.1868 110.965C63.4993 110.965 63.7526 111.026 63.9467 111.15C64.1432 111.273 64.2971 111.437 64.4084 111.643C64.5197 111.849 64.6025 112.076 64.657 112.325C64.7138 112.576 64.7588 112.828 64.7919 113.081C64.8345 113.413 64.8665 113.681 64.8878 113.887C64.9115 114.096 64.9505 114.247 65.005 114.342C65.0594 114.439 65.1541 114.488 65.2891 114.488L65.3175 114.488C65.6679 114.488 65.9401 114.392 66.1342 114.2C66.3284 114.011 66.4254 113.723 66.4254 113.337C66.4254 112.937 66.3378 112.623 66.1626 112.396C65.9875 112.169 65.8004 112.009 65.6016 111.917L65.8857 111.121C66.2171 111.263 66.4751 111.453 66.6598 111.689C66.8468 111.928 66.977 112.189 67.0504 112.471C67.1262 112.755 67.1641 113.034 67.1641 113.309C67.1641 113.484 67.1428 113.685 67.1001 113.912C67.0599 114.142 66.9759 114.363 66.848 114.576C66.7202 114.792 66.5272 114.971 66.2692 115.113C66.0111 115.255 65.6655 115.326 65.2322 115.326L61.6385 115.326L61.6385 114.488L62.3771 114.488L62.3771 114.445C62.2588 114.388 62.1321 114.293 61.9972 114.161C61.8622 114.028 61.7474 113.852 61.6527 113.632C61.558 113.412 61.5107 113.143 61.5107 112.826ZM62.2635 112.953C62.2635 113.285 62.3286 113.564 62.4588 113.792C62.589 114.021 62.7571 114.194 62.9631 114.31C63.169 114.428 63.3857 114.488 63.6129 114.488L64.38 114.488C64.3374 114.452 64.2983 114.374 64.2628 114.253C64.2296 114.135 64.2 113.998 64.174 113.841C64.1503 113.687 64.129 113.537 64.1101 113.39C64.0935 113.246 64.0793 113.129 64.0675 113.039C64.0391 112.821 63.9929 112.617 63.929 112.428C63.8674 112.241 63.7739 112.089 63.6484 111.973C63.5253 111.86 63.3572 111.803 63.1442 111.803C62.853 111.803 62.6328 111.911 62.4837 112.126C62.3369 112.344 62.2635 112.62 62.2635 112.953Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M64.9197 106.209L61.6385 106.209L61.6385 105.371L67.093 105.371L67.093 106.181L66.2408 106.181L66.2408 106.252C66.5178 106.38 66.7403 106.574 66.9084 106.834C67.0788 107.095 67.1641 107.431 67.1641 107.843C67.1641 108.212 67.0883 108.535 66.9368 108.812C66.7876 109.089 66.5604 109.305 66.255 109.458C65.9519 109.612 65.5684 109.689 65.1044 109.689L61.6385 109.689L61.6385 108.851L65.0476 108.851C65.4761 108.851 65.8099 108.74 66.049 108.517C66.2905 108.295 66.4112 107.989 66.4112 107.601C66.4112 107.334 66.3532 107.095 66.2372 106.884C66.1212 106.676 65.9519 106.511 65.7294 106.39C65.5069 106.27 65.237 106.209 64.9197 106.209Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.6389 101.65L67.0935 101.65L67.0935 102.46L66.2696 102.46L66.2696 102.517C66.5395 102.616 66.7585 102.796 66.9266 103.057C67.0947 103.317 67.1787 103.611 67.1787 103.937C67.1787 103.999 67.1775 104.076 67.1752 104.168C67.1728 104.26 67.1692 104.33 67.1645 104.378L66.3122 104.378C66.3193 104.349 66.33 104.284 66.3442 104.182C66.3608 104.083 66.3691 103.978 66.3691 103.866C66.3691 103.601 66.3134 103.364 66.2021 103.156C66.0932 102.95 65.9417 102.787 65.7476 102.666C65.5558 102.548 65.3369 102.488 65.0906 102.488L61.6389 102.488L61.6389 101.65Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.5249 98.1168C61.5249 97.5913 61.6409 97.1379 61.8729 96.7567C62.1072 96.378 62.4339 96.0856 62.853 95.8796C63.2744 95.676 63.7644 95.5742 64.3232 95.5742C64.8819 95.5742 65.3743 95.676 65.8004 95.8796C66.2289 96.0856 66.5627 96.372 66.8018 96.739C67.0433 97.1083 67.1641 97.5392 67.1641 98.0316C67.1641 98.3157 67.1167 98.5962 67.022 98.8732C66.9273 99.1502 66.7734 99.4023 66.5604 99.6296C66.3497 99.8569 66.0703 100.038 65.7223 100.173C65.3743 100.308 64.9458 100.375 64.4368 100.375L64.0817 100.375L64.0817 96.1708L64.8061 96.1708L64.8061 99.5231C65.1139 99.5231 65.3885 99.4615 65.63 99.3384C65.8714 99.2177 66.062 99.0449 66.2017 98.82C66.3414 98.5974 66.4112 98.3346 66.4112 98.0316C66.4112 97.6978 66.3284 97.409 66.1626 97.1651C65.9993 96.9236 65.7862 96.7378 65.5234 96.6076C65.2607 96.4774 64.9789 96.4123 64.6783 96.4123L64.1953 96.4123C63.7834 96.4123 63.4342 96.4833 63.1477 96.6254C62.8636 96.7698 62.647 96.9698 62.4979 97.2255C62.3511 97.4812 62.2777 97.7783 62.2777 98.1168C62.2777 98.337 62.3085 98.5359 62.37 98.7134C62.4339 98.8933 62.5286 99.0484 62.6541 99.1786C62.782 99.3088 62.9406 99.4094 63.13 99.4805L62.9027 100.29C62.6281 100.205 62.3866 100.062 62.1783 99.8604C61.9723 99.6592 61.8113 99.4106 61.6953 99.1147C61.5817 98.8188 61.5249 98.4862 61.5249 98.1168Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M67.0936 94.6275L66.3833 94.6275L66.3833 91.8008L67.0936 91.8008L67.0936 94.6275ZM68.4004 92.6246L68.4004 93.4627L63.2015 93.4627C62.9648 93.4627 62.7872 93.497 62.6689 93.5657C62.5529 93.6367 62.4747 93.7267 62.4345 93.8356C62.3966 93.9469 62.3777 94.064 62.3777 94.1871C62.3777 94.2795 62.3824 94.3552 62.3919 94.4144C62.4037 94.4736 62.4132 94.5209 62.4203 94.5565L61.6674 94.7269C61.6461 94.6701 61.6248 94.5908 61.6035 94.489C61.5798 94.3872 61.568 94.2582 61.568 94.1019C61.568 93.8652 61.6189 93.6332 61.7207 93.4059C61.8225 93.181 61.9776 92.994 62.1859 92.8448C62.3942 92.698 62.657 92.6246 62.9743 92.6246L68.4004 92.6246Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M67.0929 91.081L66.3826 91.081L66.3826 88.1406L67.0929 88.1406L67.0929 91.081ZM61.6383 89.0213L67.8457 89.0213C68.1582 89.0213 68.4186 89.0947 68.627 89.2415C68.8353 89.3883 68.9915 89.5788 69.0957 89.8132C69.1999 90.0476 69.252 90.295 69.252 90.5554C69.252 90.7614 69.2354 90.9295 69.2022 91.0597C69.1691 91.1899 69.1383 91.2869 69.1099 91.3509L68.3855 91.1094C68.3997 91.0668 68.4174 91.0076 68.4387 90.9318C68.46 90.8584 68.4707 90.7614 68.4707 90.6406C68.4707 90.3636 68.4009 90.1636 68.2612 90.0405C68.1215 89.9197 67.9167 89.8594 67.6468 89.8594L61.6383 89.8594L61.6383 89.0213Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.6384 82.7891L68.9111 82.7891L68.9111 87.1783L68.1299 87.1783L68.1299 83.6697L65.6725 83.6697L65.6725 86.951L64.8912 86.951L64.8912 83.6697L62.4197 83.6697L62.4197 87.2351L61.6384 87.2351L61.6384 82.7891Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M64.9197 74.8517L61.6385 74.8517L61.6385 74.0137L67.093 74.0137L67.093 74.8233L66.2408 74.8233L66.2408 74.8944C66.5178 75.0222 66.7403 75.2163 66.9084 75.4767C67.0788 75.7372 67.1641 76.0733 67.1641 76.4853C67.1641 76.8546 67.0883 77.1777 66.9368 77.4547C66.7876 77.7317 66.5604 77.9471 66.255 78.101C65.9519 78.2549 65.5684 78.3319 65.1044 78.3319L61.6385 78.3319L61.6385 77.4938L65.0476 77.4938C65.4761 77.4938 65.8099 77.3825 66.049 77.16C66.2905 76.9374 66.4112 76.632 66.4112 76.2438C66.4112 75.9763 66.3532 75.7372 66.2372 75.5265C66.1212 75.3181 65.9519 75.1536 65.7294 75.0328C65.5069 74.9121 65.237 74.8517 64.9197 74.8517Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M67.0928 72.9078L61.6382 70.8908L61.6382 70.0385L67.0928 68.0215L67.0928 68.9306L62.7462 70.4363L62.7462 70.4931L67.0928 71.9988L67.0928 72.9078Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.5107 64.6127C61.5107 64.2671 61.5758 63.9534 61.706 63.6717C61.8385 63.39 62.0291 63.1663 62.2777 63.0005C62.5286 62.8348 62.8317 62.752 63.1868 62.752C63.4993 62.752 63.7526 62.8135 63.9467 62.9366C64.1432 63.0597 64.2971 63.2243 64.4084 63.4302C64.5197 63.6362 64.6025 63.8635 64.657 64.112C64.7138 64.363 64.7588 64.6151 64.7919 64.8684C64.8345 65.1999 64.8665 65.4686 64.8878 65.6745C64.9115 65.8829 64.9505 66.0344 65.005 66.1291C65.0594 66.2261 65.1541 66.2747 65.2891 66.2747L65.3175 66.2747C65.6679 66.2747 65.9401 66.1788 66.1342 65.987C66.3284 65.7976 66.4254 65.51 66.4254 65.1241C66.4254 64.724 66.3378 64.4103 66.1626 64.1831C65.9875 63.9558 65.8004 63.796 65.6016 63.7037L65.8857 62.9082C66.2171 63.0502 66.4751 63.2396 66.6598 63.4764C66.8468 63.7155 66.977 63.9759 67.0504 64.2576C67.1262 64.5417 67.1641 64.8211 67.1641 65.0957C67.1641 65.2709 67.1428 65.4721 67.1001 65.6994C67.0599 65.929 66.9759 66.1504 66.848 66.3635C66.7202 66.5789 66.5272 66.7576 66.2692 66.8997C66.0111 67.0417 65.6655 67.1127 65.2322 67.1127L61.6385 67.1127L61.6385 66.2747L62.3771 66.2747L62.3771 66.2321C62.2588 66.1752 62.1321 66.0806 61.9972 65.948C61.8622 65.8154 61.7474 65.639 61.6527 65.4189C61.558 65.1987 61.5107 64.93 61.5107 64.6127ZM62.2635 64.7406C62.2635 65.072 62.3286 65.3514 62.4588 65.5787C62.589 65.8083 62.7571 65.9811 62.9631 66.0971C63.169 66.2155 63.3857 66.2747 63.6129 66.2747L64.38 66.2747C64.3374 66.2392 64.2983 66.161 64.2628 66.0403C64.2296 65.9219 64.2 65.7846 64.174 65.6284C64.1503 65.4745 64.129 65.3242 64.1101 65.1774C64.0935 65.033 64.0793 64.9158 64.0675 64.8258C64.0391 64.608 63.9929 64.4044 63.929 64.215C63.8674 64.028 63.7739 63.8765 63.6484 63.7605C63.5253 63.6468 63.3572 63.59 63.1442 63.59C62.853 63.59 62.6328 63.6977 62.4837 63.9132C62.3369 64.131 62.2635 64.4068 62.2635 64.7406Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M64.9197 57.9963L61.6385 57.9963L61.6385 57.1582L67.093 57.1582L67.093 57.9679L66.2408 57.9679L66.2408 58.0389C66.5178 58.1667 66.7403 58.3609 66.9084 58.6213C67.0788 58.8817 67.1641 59.2179 67.1641 59.6298C67.1641 59.9991 67.0883 60.3223 66.9368 60.5993C66.7876 60.8762 66.5604 61.0917 66.255 61.2456C65.9519 61.3994 65.5684 61.4764 65.1044 61.4764L61.6385 61.4764L61.6385 60.6383L65.0476 60.6383C65.4761 60.6383 65.8099 60.527 66.049 60.3045C66.2905 60.082 66.4112 59.7766 66.4112 59.3883C66.4112 59.1208 66.3532 58.8817 66.2372 58.671C66.1212 58.4627 65.9519 58.2981 65.7294 58.1774C65.5069 58.0566 65.237 57.9963 64.9197 57.9963Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.6389 53.4375L67.0935 53.4375L67.0935 54.2472L66.2696 54.2472L66.2696 54.304C66.5395 54.4034 66.7585 54.5833 66.9266 54.8437C67.0947 55.1042 67.1787 55.3977 67.1787 55.7244C67.1787 55.786 67.1775 55.8629 67.1752 55.9553C67.1728 56.0476 67.1692 56.1174 67.1645 56.1648L66.3122 56.1648C66.3193 56.1364 66.33 56.0713 66.3442 55.9695C66.3608 55.87 66.3691 55.7647 66.3691 55.6534C66.3691 55.3883 66.3134 55.1515 66.2021 54.9432C66.0932 54.7372 65.9417 54.5739 65.7476 54.4531C65.5558 54.3348 65.3369 54.2756 65.0906 54.2756L61.6389 54.2756L61.6389 53.4375Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.5249 49.6864C61.5249 49.194 61.642 48.762 61.8764 48.3903C62.1108 48.021 62.4387 47.7321 62.8601 47.5238C63.2815 47.3178 63.7739 47.2148 64.3374 47.2148C64.9055 47.2148 65.4015 47.3178 65.8253 47.5238C66.2491 47.7321 66.5781 48.021 66.8125 48.3903C67.0469 48.762 67.1641 49.194 67.1641 49.6864C67.1641 50.1789 67.0469 50.6097 66.8125 50.979C66.5781 51.3507 66.2491 51.6396 65.8253 51.8455C65.4015 52.0539 64.9055 52.158 64.3374 52.158C63.7739 52.158 63.2815 52.0539 62.8601 51.8455C62.4387 51.6396 62.1108 51.3507 61.8764 50.979C61.642 50.6097 61.5249 50.1789 61.5249 49.6864ZM62.2777 49.6864C62.2777 50.0605 62.3736 50.3683 62.5653 50.6097C62.7571 50.8512 63.0092 51.0299 63.3217 51.146C63.6342 51.262 63.9728 51.32 64.3374 51.32C64.7019 51.32 65.0417 51.262 65.3565 51.146C65.6714 51.0299 65.9259 50.8512 66.12 50.6097C66.3142 50.3683 66.4112 50.0605 66.4112 49.6864C66.4112 49.3124 66.3142 49.0046 66.12 48.7631C65.9259 48.5217 65.6714 48.3429 65.3565 48.2269C65.0417 48.1109 64.7019 48.0529 64.3374 48.0529C63.9728 48.0529 63.6342 48.1109 63.3217 48.2269C63.0092 48.3429 62.7571 48.5217 62.5653 48.7631C62.3736 49.0046 62.2777 49.3124 62.2777 49.6864Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M61.6384 42.1152L68.9111 42.1152L68.9111 46.476L68.1299 46.476L68.1299 42.9959L65.6725 42.9959L65.6725 46.1493L64.8912 46.1493L64.8912 42.9959L61.6384 42.9959L61.6384 42.1152Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M81.0931 115.341C81.453 115.298 81.7323 115.125 81.9312 114.822C82.1301 114.519 82.2295 114.147 82.2295 113.707C82.2295 113.385 82.1774 113.103 82.0732 112.862C81.9691 112.623 81.8258 112.436 81.6436 112.301C81.4613 112.168 81.2541 112.102 81.0221 112.102C80.828 112.102 80.6611 112.148 80.5214 112.24C80.3841 112.335 80.2693 112.456 80.1769 112.603C80.087 112.749 80.0124 112.903 79.9532 113.064C79.8964 113.225 79.8502 113.373 79.8147 113.508L79.6159 114.247C79.5661 114.436 79.4975 114.647 79.4099 114.879C79.3223 115.113 79.2027 115.337 79.0512 115.55C78.9021 115.766 78.7103 115.943 78.4759 116.083C78.2416 116.222 77.9539 116.292 77.613 116.292C77.22 116.292 76.8649 116.189 76.5477 115.983C76.2304 115.78 75.9783 115.481 75.7913 115.088C75.6043 114.698 75.5107 114.223 75.5107 113.664C75.5107 113.144 75.5948 112.693 75.7629 112.311C75.931 111.933 76.1653 111.634 76.466 111.417C76.7667 111.201 77.1159 111.079 77.5136 111.051L77.5136 111.96C77.239 111.984 77.0117 112.076 76.8318 112.237C76.6542 112.4 76.5216 112.606 76.434 112.855C76.3488 113.106 76.3062 113.376 76.3062 113.664C76.3062 114.001 76.3606 114.302 76.4695 114.57C76.5808 114.837 76.7347 115.049 76.9312 115.206C77.1301 115.362 77.3621 115.44 77.6272 115.44C77.8687 115.44 78.0652 115.373 78.2167 115.238C78.3682 115.103 78.4913 114.925 78.586 114.705C78.6807 114.485 78.7636 114.247 78.8346 113.991L79.0903 113.096C79.2536 112.528 79.4868 112.078 79.7899 111.747C80.0929 111.415 80.4894 111.25 80.9795 111.25C81.3867 111.25 81.7418 111.36 82.0448 111.58C82.3502 111.802 82.587 112.101 82.7551 112.475C82.9255 112.851 83.0107 113.271 83.0107 113.735C83.0107 114.204 82.9267 114.621 82.7586 114.985C82.5929 115.35 82.3656 115.639 82.0768 115.852C81.788 116.067 81.4601 116.181 81.0931 116.193L81.0931 115.341Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M83.252 110.257L74.5446 107.914L74.5446 107.146L83.252 109.49L83.252 110.257Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.6384 101.339L75.6384 100.416L82.9111 103.086L82.9111 103.996L75.6384 106.666L75.6384 105.743L81.7606 103.569L81.7606 103.513L75.6384 101.339ZM78.4793 101.68L78.4793 105.402L77.6981 105.402L77.6981 101.68L78.4793 101.68Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.5248 93.9638C75.5248 93.5092 75.6396 93.108 75.8692 92.7599C76.1012 92.4119 76.4279 92.1397 76.8493 91.9432C77.2731 91.7467 77.7738 91.6484 78.3515 91.6484C78.9244 91.6484 79.4215 91.7467 79.843 91.9432C80.2644 92.1397 80.5899 92.4131 80.8195 92.7635C81.0492 93.1139 81.164 93.5187 81.164 93.978C81.164 94.3331 81.1048 94.6136 80.9864 94.8196C80.8704 95.0279 80.7378 95.1866 80.5887 95.2955C80.4419 95.4067 80.3212 95.4931 80.2265 95.5547L80.2265 95.6257L82.9111 95.6257L82.9111 96.4638L75.6384 96.4638L75.6384 95.6541L76.4765 95.6541L76.4765 95.5547C76.377 95.4931 76.2516 95.4055 76.1001 95.2919C75.9509 95.1783 75.8171 95.0161 75.6988 94.8054C75.5828 94.5947 75.5248 94.3142 75.5248 93.9638ZM76.2776 94.0774C76.2776 94.4136 76.3652 94.6977 76.5404 94.9297C76.718 95.1617 76.963 95.3381 77.2755 95.4588C77.5903 95.5795 77.9537 95.6399 78.3657 95.6399C78.7729 95.6399 79.1292 95.5807 79.4346 95.4624C79.7423 95.344 79.9814 95.1688 80.1519 94.9368C80.3247 94.7048 80.4111 94.4183 80.4111 94.0774C80.4111 93.7223 80.32 93.4264 80.1377 93.1896C79.9578 92.9553 79.7127 92.7789 79.4026 92.6605C79.0948 92.5445 78.7492 92.4865 78.3657 92.4865C77.9774 92.4865 77.6247 92.5457 77.3074 92.6641C76.9926 92.7848 76.7416 92.9624 76.5546 93.1967C76.3699 93.4335 76.2776 93.727 76.2776 94.0774Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.5249 88.3707C75.5249 87.8452 75.6409 87.3918 75.8729 87.0107C76.1072 86.6319 76.4339 86.3395 76.853 86.1335C77.2744 85.9299 77.7644 85.8281 78.3232 85.8281C78.8819 85.8281 79.3743 85.9299 79.8004 86.1335C80.2289 86.3395 80.5627 86.6259 80.8018 86.9929C81.0433 87.3622 81.1641 87.7931 81.1641 88.2855C81.1641 88.5696 81.1167 88.8501 81.022 89.1271C80.9273 89.4041 80.7734 89.6562 80.5604 89.8835C80.3497 90.1108 80.0703 90.2919 79.7223 90.4268C79.3743 90.5618 78.9458 90.6293 78.4368 90.6293L78.0817 90.6293L78.0817 86.4247L78.8061 86.4247L78.8061 89.777C79.1139 89.777 79.3885 89.7154 79.63 89.5923C79.8714 89.4716 80.062 89.2988 80.2017 89.0739C80.3414 88.8513 80.4112 88.5885 80.4112 88.2855C80.4112 87.9517 80.3284 87.6629 80.1626 87.419C79.9993 87.1776 79.7862 86.9917 79.5234 86.8615C79.2607 86.7313 78.9789 86.6662 78.6783 86.6662L78.1953 86.6662C77.7834 86.6662 77.4342 86.7372 77.1477 86.8793C76.8636 87.0237 76.647 87.2237 76.4979 87.4794C76.3511 87.7351 76.2777 88.0322 76.2777 88.3707C76.2777 88.5909 76.3085 88.7898 76.37 88.9673C76.4339 89.1473 76.5286 89.3023 76.6541 89.4325C76.782 89.5627 76.9406 89.6634 77.13 89.7344L76.9027 90.544C76.6281 90.4588 76.3866 90.3156 76.1783 90.1143C75.9723 89.9131 75.8113 89.6645 75.6953 89.3686C75.5817 89.0727 75.5249 88.7401 75.5249 88.3707Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M78.9197 81.0139L75.6384 81.0139L75.6384 80.1758L82.9111 80.1758L82.9111 81.0139L80.2407 81.0139L80.2407 81.0849C80.5224 81.2127 80.7461 81.4045 80.9118 81.6602C81.0799 81.9182 81.164 82.2615 81.164 82.69C81.164 83.0617 81.0894 83.3872 80.9403 83.6665C80.7935 83.9459 80.5674 84.1625 80.262 84.3164C79.959 84.4727 79.5731 84.5508 79.1043 84.5508L75.6384 84.5508L75.6384 83.7127L79.0475 83.7127C79.4807 83.7127 79.8157 83.6003 80.0525 83.3754C80.2916 83.1528 80.4111 82.8439 80.4111 82.4485C80.4111 82.1739 80.3531 81.9277 80.2371 81.7099C80.1211 81.4944 79.9519 81.324 79.7293 81.1985C79.5068 81.0754 79.2369 81.0139 78.9197 81.0139Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.6385 71.4844L81.093 71.4844L81.093 72.294L80.2408 72.294L80.2408 72.3651C80.532 72.4787 80.758 72.6622 80.919 72.9155C81.0824 73.1688 81.1641 73.473 81.1641 73.8281C81.1641 74.188 81.0824 74.4875 80.919 74.7266C80.758 74.968 80.532 75.1562 80.2408 75.2912L80.2408 75.348C80.5225 75.4877 80.7462 75.6972 80.9119 75.9766C81.08 76.2559 81.1641 76.5909 81.1641 76.9815C81.1641 77.4692 81.0114 77.8681 80.706 78.1783C80.4029 78.4884 79.9306 78.6435 79.2891 78.6435L75.6385 78.6435L75.6385 77.8054L79.2891 77.8054C79.6915 77.8054 79.9792 77.6953 80.152 77.4751C80.3248 77.255 80.4112 76.9957 80.4112 76.6974C80.4112 76.3139 80.2952 76.0168 80.0632 75.8061C79.8336 75.5954 79.5424 75.4901 79.1896 75.4901L75.6385 75.4901L75.6385 74.6378L79.3743 74.6378C79.6844 74.6378 79.9342 74.5372 80.1236 74.3359C80.3153 74.1347 80.4112 73.8755 80.4112 73.5582C80.4112 73.3404 80.3532 73.1368 80.2372 72.9474C80.1212 72.7604 79.9602 72.6089 79.7543 72.4929C79.5507 72.3793 79.3151 72.3224 79.0476 72.3224L75.6385 72.3224L75.6385 71.4844Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.5249 67.7333C75.5249 67.2409 75.642 66.8088 75.8764 66.4371C76.1108 66.0678 76.4387 65.779 76.8601 65.5707C77.2815 65.3647 77.7739 65.2617 78.3374 65.2617C78.9055 65.2617 79.4015 65.3647 79.8253 65.5707C80.2491 65.779 80.5781 66.0678 80.8125 66.4371C81.0469 66.8088 81.1641 67.2409 81.1641 67.7333C81.1641 68.2257 81.0469 68.6566 80.8125 69.0259C80.5781 69.3976 80.2491 69.6864 79.8253 69.8924C79.4015 70.1007 78.9055 70.2049 78.3374 70.2049C77.7739 70.2049 77.2815 70.1007 76.8601 69.8924C76.4387 69.6864 76.1108 69.3976 75.8764 69.0259C75.642 68.6566 75.5249 68.2257 75.5249 67.7333ZM76.2777 67.7333C76.2777 68.1074 76.3736 68.4151 76.5653 68.6566C76.7571 68.8981 77.0092 69.0768 77.3217 69.1928C77.6342 69.3088 77.9728 69.3668 78.3374 69.3668C78.7019 69.3668 79.0417 69.3088 79.3565 69.1928C79.6714 69.0768 79.9259 68.8981 80.12 68.6566C80.3142 68.4151 80.4112 68.1074 80.4112 67.7333C80.4112 67.3593 80.3142 67.0515 80.12 66.81C79.9259 66.5685 79.6714 66.3898 79.3565 66.2738C79.0417 66.1578 78.7019 66.0998 78.3374 66.0998C77.9728 66.0998 77.6342 66.1578 77.3217 66.2738C77.0092 66.3898 76.7571 66.5685 76.5653 66.81C76.3736 67.0515 76.2777 67.3593 76.2777 67.7333Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M79.8714 64.1578L79.6584 63.405C79.7839 63.3577 79.9058 63.2878 80.0241 63.1955C80.1449 63.1055 80.2443 62.9824 80.3224 62.8262C80.4006 62.6699 80.4396 62.4699 80.4396 62.226C80.4396 61.8922 80.3627 61.6141 80.2088 61.3915C80.0573 61.1713 79.8643 61.0613 79.63 61.0613C79.4216 61.0613 79.2571 61.137 79.1364 61.2885C79.0156 61.44 78.915 61.6768 78.8345 61.9988L78.6357 62.8084C78.5173 63.2961 78.3362 63.6595 78.0923 63.8986C77.8509 64.1377 77.5395 64.2573 77.1584 64.2573C76.8459 64.2573 76.5665 64.1673 76.3203 63.9874C76.0741 63.8098 75.88 63.5613 75.7379 63.2417C75.5959 62.9221 75.5249 62.5504 75.5249 62.1266C75.5249 61.5703 75.6456 61.1098 75.8871 60.7452C76.1286 60.3806 76.4813 60.1498 76.9453 60.0527L77.1442 60.8482C76.8506 60.9239 76.6304 61.0672 76.4837 61.2779C76.3369 61.4909 76.2635 61.7691 76.2635 62.1124C76.2635 62.503 76.3464 62.8132 76.5121 63.0428C76.6802 63.2748 76.8814 63.3908 77.1158 63.3908C77.3052 63.3908 77.4638 63.3245 77.5916 63.1919C77.7218 63.0594 77.8189 62.8558 77.8828 62.5811L78.0959 61.6721C78.2143 61.1725 78.3977 60.8056 78.6463 60.5712C78.8973 60.3392 79.2109 60.2232 79.5874 60.2232C79.8951 60.2232 80.1674 60.3096 80.4041 60.4824C80.6409 60.6576 80.8267 60.8955 80.9616 61.1962C81.0966 61.4992 81.1641 61.8425 81.1641 62.226C81.1641 62.7658 81.0457 63.1896 80.8089 63.4973C80.5722 63.8075 80.2597 64.0276 79.8714 64.1578Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M77.627 55.6348L78.664 55.6206L78.664 55.791L81.093 58.1774L81.093 59.2143L78.5219 56.6717L78.5219 56.6007L77.627 55.6348ZM75.6384 54.8535L82.9111 54.8535L82.9111 55.6916L75.6384 55.6916L75.6384 54.8535ZM75.6384 58.3194L78.3373 56.1887L78.9197 56.7853L75.6384 59.3848L75.6384 58.3194Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.6389 51.1328L81.0935 51.1328L81.0935 51.9425L80.2696 51.9425L80.2696 51.9993C80.5395 52.0987 80.7585 52.2786 80.9266 52.5391C81.0947 52.7995 81.1787 53.093 81.1787 53.4197C81.1787 53.4813 81.1775 53.5582 81.1752 53.6506C81.1728 53.7429 81.1692 53.8127 81.1645 53.8601L80.3122 53.8601C80.3193 53.8317 80.33 53.7666 80.3442 53.6648C80.3608 53.5653 80.3691 53.46 80.3691 53.3487C80.3691 53.0836 80.3134 52.8468 80.2021 52.6385C80.0932 52.4325 79.9417 52.2692 79.7476 52.1484C79.5558 52.0301 79.3369 51.9709 79.0906 51.9709L75.6389 51.9709L75.6389 51.1328Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M75.6387 48.7603L81.0932 48.7603L81.0932 49.5984L75.6387 49.5984L75.6387 48.7603ZM82.0023 49.1864C82.0023 49.0231 82.0579 48.8822 82.1692 48.7638C82.2805 48.6478 82.4142 48.5898 82.5705 48.5898C82.7267 48.5898 82.8605 48.6478 82.9718 48.7638C83.083 48.8822 83.1387 49.0231 83.1387 49.1864C83.1387 49.3498 83.083 49.4895 82.9718 49.6055C82.8605 49.7238 82.7267 49.783 82.5705 49.783C82.4142 49.783 82.2805 49.7238 82.1692 49.6055C82.0579 49.4895 82.0023 49.3498 82.0023 49.1864Z"
        fill="white"
        opacity="0.5"
      />
      <path
        d="M82.9111 42.4135L76.789 44.5726L76.789 44.6578L82.9111 46.8169L82.9111 47.7402L75.6384 45.0698L75.6384 44.1607L82.9111 41.4902L82.9111 42.4135Z"
        fill="white"
        opacity="0.5"
      />
    </g>
    <defs>
      <filter
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
        height="237.686"
        id="filter0_d_8073_9719"
        width="169.545"
        x="28.0361"
        y="14.1855"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          in2="BackgroundImageFix"
          mode="normal"
          result="effect1_dropShadow_8073_9719"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_8073_9719"
          mode="normal"
          result="shape"
        />
      </filter>
      <linearGradient
        gradientUnits="userSpaceOnUse"
        id="paint0_linear_8073_9719"
        x1="264.233"
        x2="46.7307"
        y1="242.763"
        y2="42.5163"
      >
        <stop stopColor="#1A202C" />
        <stop offset="1" stopColor="#1D78D3" />
      </linearGradient>
    </defs>
  </svg>
);
