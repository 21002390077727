import { useNavigate } from "react-router-dom";

import { ItemLayout } from "~/components/ItemLayout";
import { employeeActions } from "~/providers/store/employee";

export const SelectorItem = ({ groupName }: { groupName: string }) => {
  const navigate = useNavigate();

  return (
    <ItemLayout
      className="flex size-full flex-1 flex-col items-center justify-center bg-background-secondary p-4 text-left text-lg text-text-primary"
      onClick={() => {
        employeeActions.setGroupName(groupName);
        navigate("/pos");
      }}
    >
      <span className="line-clamp-2">{groupName}</span>
    </ItemLayout>
  );
};
