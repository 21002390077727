import { t } from "@repo/transifex";
import { clsx } from "clsx";
import moment from "moment-timezone";
import type { TillSession } from "@repo/types";

type ReportSessionItemProps = Pick<TillSession, "startedAt" | "endedAt"> & {
  isActive: boolean;
  timezone: string;
};

export const ReportSessionItem = ({
  isActive,
  startedAt,
  endedAt,
  timezone,
}: ReportSessionItemProps) => (
  <button
    className={clsx(
      "flex w-full justify-center rounded-md border-2 border-divider-main px-4 py-3 text-text-primary",
      isActive && "bg-text-primary text-white"
    )}
    type="button"
  >
    <p>
      {formatDate(startedAt, timezone)} - {formatEndedDate(endedAt, timezone)}
    </p>
  </button>
);

const formatDate = (dateSeconds: number, timezone: string) =>
  moment.unix(dateSeconds).tz(timezone).format("D MMMM HH:mm");

const formatEndedDate = (
  dateSeconds: number | null | undefined,
  timezone: string
) =>
  dateSeconds
    ? moment.unix(dateSeconds).tz(timezone).format("HH:mm")
    : t("now");
