import { T } from "@repo/transifex";
import { clsx } from "clsx";

type PriceProps = {
  title: string;
  value: string;
  column?: boolean;
  type: "primary" | "secondary";
  hidden?: boolean;
};

export const Price = ({ title, value, column, type, hidden }: PriceProps) => {
  if (hidden) return null;

  return (
    <div
      className={clsx("flex items-baseline justify-between", {
        "flex-col !items-end": column,
      })}
    >
      <span className="text-lg font-medium text-text-secondary">
        <T _str={title} />
      </span>

      <span
        className={clsx({
          "text-5xl text-primary-dark": type === "primary",
          "text-lg font-medium text-text-secondary": type === "secondary",
        })}
      >
        {value}
      </span>
    </div>
  );
};
