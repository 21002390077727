/**
 * Function that calculates the amount to pay with saldo and other payment methods
 * @param amountToPay: The amount to pay
 * @param saldo: The saldo
 * @returns: The amount to pay with saldo and other payment methods
 */
export function calculateAmountForSaldo(
  amountToPay: number,
  saldo: number | null
): {
  payWithSaldo: number;
  payWithOther: number;
} {
  //If the amount is not valid, return 0
  if (!amountToPay)
    return {
      payWithSaldo: 0,
      payWithOther: 0,
    };

  //If there is no saldo, fill "other" with the amount
  if (!saldo || saldo < 0)
    return {
      payWithSaldo: 0,
      payWithOther: amountToPay,
    };

  const difference = saldo - amountToPay;

  //If the amount to pay is higher than the saldo
  //Return the saldo as saldo and the difference as other
  if (difference < 0)
    return {
      payWithSaldo: saldo,
      payWithOther: amountToPay - saldo,
    };

  //If the amount to pay is lower than pay with saldo
  const payWithSaldo = difference < saldo ? amountToPay : difference;
  return {
    payWithSaldo,
    payWithOther: 0,
  };
}
