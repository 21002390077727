export type ApiError = {
  body: {
    message: string;
  };
  status: number;
};

export function isApiError(error: unknown): error is ApiError {
  return (
    typeof error === "object" &&
    error !== null &&
    "body" in error &&
    "status" in error &&
    typeof error.body === "object" &&
    error.body !== null &&
    "message" in error.body
  );
}
