import { round } from "@repo/system";
import { T } from "@repo/transifex";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Dialog } from "~/components";
import type { DiscountTypes } from "~/components/Inputs/DiscountInput";
import { DiscountInput } from "~/components/Inputs/DiscountInput";
import { useBasketProducts } from "~/providers/app";
import { basketActions, useOrderDiscount } from "~/providers/store/basket";

export const DiscountOrderPage = () => {
  const {
    totalUnit,
    meta: { currency },
  } = useBasketProducts();

  const navigate = useNavigate();

  const orderDiscount = useOrderDiscount();

  const [discountType, setDiscountType] = useState<DiscountTypes>(
    orderDiscount.type
  );

  const [discount, setDiscount] = useState<number | undefined>(
    orderDiscount.type === "currency"
      ? orderDiscount.amount / 100
      : (totalUnit / 100) * (orderDiscount.amount / 100)
  );

  const [percentage, setPercentage] = useState<number | undefined>(
    orderDiscount.type === "percentage"
      ? orderDiscount.amount
      : (orderDiscount.amount / totalUnit) * 100
  );

  const handleChangeDiscount = (newDiscount: number) => {
    if (newDiscount === 0) {
      setDiscount(undefined);
      setPercentage(undefined);
      return;
    }
    const percentage = round((newDiscount / (totalUnit / 100)) * 100, 2);

    setDiscount(newDiscount);
    setPercentage(percentage);
  };

  const handleChangePercentage = (newPercentage: number) => {
    if (newPercentage === 0) {
      setDiscount(undefined);
      setPercentage(undefined);
      return;
    }

    const discount = round((newPercentage * (totalUnit / 100)) / 100, 2);

    setDiscount(discount);
    setPercentage(newPercentage);
  };

  return (
    <Dialog className="w-fit" size="lg">
      <div className="relative flex w-[640px] flex-col items-center gap-4">
        <div className="flex w-full flex-col items-center gap-8 px-10 py-5">
          <div>
            <Link to="..">
              <Button
                className="absolute left-10 top-3 font-medium text-danger-dark"
                variant="transparent"
              >
                <T _str="Cancel" />
              </Button>
            </Link>

            <h1 className="text-2xl font-bold">
              <T _str="Order discount" />
            </h1>
          </div>
          <div className="flex w-full flex-col">
            <p className="mb-4 font-semibold">
              <T _str="Discount" />
            </p>
            <DiscountInput
              currency={currency}
              handleChangeDiscount={
                discountType === "currency"
                  ? handleChangeDiscount
                  : handleChangePercentage
              }
              handleChangeType={setDiscountType}
              type={discountType}
              value={discountType === "currency" ? discount : percentage}
            />

            <Button
              className="mt-8"
              onClick={() => {
                if (discount === undefined || percentage === undefined) {
                  basketActions.setOrderDiscount({
                    amount: 0,
                    type: discountType,
                  });
                } else {
                  basketActions.setOrderDiscount({
                    amount:
                      discountType === "currency" ? discount * 100 : percentage,
                    type: discountType,
                  });
                }
                navigate("..");
              }}
              size="large"
              variant="primary"
            >
              <T _str="Confirm" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
