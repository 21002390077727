import { forwardRef } from "react";
import { clsx } from "clsx";

type FieldProps = {
  name: string;
  label: string;
  error?: string;
  wrapperClassName?: string;
  labelClassName?: string;
} & JSX.IntrinsicElements["input"];

export const Field = forwardRef<HTMLInputElement, FieldProps>(
  ({ name, label, error, wrapperClassName, labelClassName, ...props }, ref) => {
    return (
      <div className={clsx("mb-4 flex flex-col", wrapperClassName)}>
        <label
          className={clsx(
            "block pb-2 text-base font-normal text-text-primary",
            labelClassName
          )}
          htmlFor={name}
        >
          {label}
        </label>

        <input
          autoComplete="on"
          autoSave="on"
          className={clsx(
            "block w-full rounded-lg border px-2.5 py-2 text-sm text-gray-900",
            {
              "border-gray-300": !error,
              "border-danger-dark focus:border-danger-dark focus:ring-danger-dark focus-visible:ring-danger-dark focus-visible:border-danger-dark focus-visible:outline-danger-light":
                error,
            }
          )}
          id={name}
          name={name}
          ref={ref}
          {...props}
        />

        {error ? (
          <div className="my-1 text-xs text-red-600" role="alert">
            {error}
          </div>
        ) : null}
      </div>
    );
  }
);

Field.displayName = "Field";
