import { tx } from "@transifex/native";

const TRANSIFEX_TOKEN = "1/ea4ee311c73bda3f503526312d898bda9079baa0";

tx.init({
  token: TRANSIFEX_TOKEN,
});

export { T, useT } from "@transifex/react";
export { t } from "@transifex/native";
export { tx };
